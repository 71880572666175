import React, { useState, useEffect, useReducer, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'

import TicketListItem from '../TicketListItemCustom'
import TicketsListSkeleton from '../TicketsListSkeleton'

import useTickets from '../../hooks/useTickets'
import { AuthContext } from '../../context/Auth/AuthContext'
import { SocketContext } from '../../context/Socket/SocketContext'
import useCan from 'hooks/useCan'
import collect from 'collect.js'

const useStyles = makeStyles(theme => ({
    ticketsListWrapper: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    ticketsList: {
        flex: 1,
        maxHeight: '100%',
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
        borderTop: '2px solid rgba(0, 0, 0, 0.12)',
    },

    ticketsListHeader: {
        color: 'rgb(67, 83, 105)',
        zIndex: 2,
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    ticketsCount: {
        fontWeight: 'normal',
        color: 'rgb(104, 121, 146)',
        marginLeft: '8px',
        fontSize: '14px',
    },

    noTicketsText: {
        textAlign: 'center',
        color: 'rgb(104, 121, 146)',
        fontSize: '14px',
        lineHeight: '1.4',
    },

    noTicketsTitle: {
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: '600',
        margin: '0px',
    },

    noTicketsDiv: {
        display: 'flex',
        height: '100px',
        margin: 40,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    listItem: {
        '& .MuiListItem-container .isPinned': {
            display: 'block',
        },
    },
}))

const sortTickets = tickets => {
    const sortedTickets = collect(tickets)
        .sortBy(item => item.isPinned)
        .all()

    return sortedTickets
}
const reducer = (state, action) => {
    if (action.type === 'LOAD_TICKETS') {
        action.payload.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id)
            if (ticketIndex !== -1) {
                state[ticketIndex] = { ...state[ticketIndex], ...ticket }
            } else {
                state.push(ticket)
            }
        })

        return [...state]
    }

    if (action.type === 'RESET_UNREAD') {
        const ticketId = action.payload

        const ticketIndex = state.findIndex(t => t.id === ticketId)
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0
        }

        return [...state]
    }

    if (action.type === 'UPDATE_TICKET') {
        const ticket = action.payload

        const ticketIndex = state.findIndex(t => t.id === ticket.id)
        if (ticketIndex !== -1) {
            state[ticketIndex] = { ...state[ticketIndex], ...ticket }
        } else {
            state.unshift(ticket)
        }

        return [...state]
    }

    if (action.type === 'UPDATE_TICKET_UNREAD_MESSAGES') {
        const ticket = action.payload

        const ticketIndex = state.findIndex(t => t.id === ticket.id)
        if (ticketIndex !== -1) {
            state[ticketIndex] = { ...state[ticketIndex], ...ticket }
            if (action.sort === 'asc') {
                state.unshift(state.splice(ticketIndex, 1)[0])
            } else {
                state.push(state.splice(ticketIndex, 1)[0])
            }
        } else {
            state.unshift(ticket)
        }

        return [...state]
    }

    if (action.type === 'UPDATE_TICKET_CONTACT') {
        const contact = action.payload
        const ticketIndex = state.findIndex(t => t.contactId === contact.id)
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = {
                ...state[ticketIndex].contact,
                ...contact,
            }
        }
        return [...state]
    }

    if (action.type === 'DELETE_TICKET') {
        const ticketId = action.payload
        const ticketIndex = state.findIndex(t => t.id === ticketId)
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1)
        }

        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const determineTicketOrder = (ticketStatus, isDescending) => {
    const orderField = ticketStatus === 'pending' ? 'createdAt' : 'updatedAt'
    return `${isDescending ? '-' : ''}${orderField}`
}

const TicketsListCustom = props => {
    const {
        status,
        searchParam,
        tags,
        users,
        connections,
        isGroup,
        selectedDepartmentIds,
        updateCount,
        style,
        orderOlder,
        messagesBetween,
        unansweredMessages,
    } = props

    const { can } = useCan()
    const socketManager = useContext(SocketContext)
    const classes = useStyles()
    const [pageNumber, setPageNumber] = useState(1)
    const [ticketsList, dispatch] = useReducer(reducer, [])
    const { user } = useContext(AuthContext)
    const { departments } = user

    const ordened = sortTickets(ticketsList)

    useEffect(() => {
        // Limpando a lista de tickets caso seja alterado algum filtro
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [
        status,
        searchParam,
        orderOlder,
        tags,
        users,
        connections,
        selectedDepartmentIds,
        messagesBetween,
        unansweredMessages,
    ])

    const { tickets, loading, lastPage } = useTickets({
        pageNumber,
        searchParam,
        status,
        sort: determineTicketOrder(status, !orderOlder),
        tags: tags,
        users: users,
        connections: connections,
        departmentIds: selectedDepartmentIds,
        isGroup,
        messagesBetween: messagesBetween,
        unansweredMessages: unansweredMessages,
    })

    useEffect(() => {
        dispatch({ type: 'LOAD_TICKETS', payload: tickets })
    }, [tickets])

    const shouldUpdateTicket = ticket => {
        if (
            searchParam ||
            Object.values(messagesBetween || []).filter(r => r)?.length ||
            Object.values(unansweredMessages || []).filter(r => r)?.length
        )
            return false

        if (connections && connections !== ticket.whatsappId) return false

        if (tags?.length && !ticket.tags?.some(t => tags.includes(t.id)))
            return false

        if (
            users?.length &&
            !users.map(u => (u === 'noUser' ? null : u)).includes(ticket.userId)
        )
            return false

        if (
            status &&
            ((!Array.isArray(status) && status !== ticket.status) ||
                (Array.isArray(status) && !status.includes(ticket.status)))
        )
            return false

        if (isGroup !== ticket.isGroup) return false

        // Se o ticket não tiver departamento e não puder ver sem departamentos, retorna false
        if (!ticket.departmentId && !can('ticket:see:without:department'))
            return false

        let validDepartments = selectedDepartmentIds
        if (!selectedDepartmentIds?.length) {
            // Se não tiver nenhum setor selecionado, e puder ver de qualquer setor, retorna true
            if (can('ticket:see:all:department')) return true

            validDepartments = user.departments.map(d => d.id)
        }

        // Se o ticket não pertencer aos setores válidos, retorna false
        if (!validDepartments.includes(ticket.departmentId)) return false

        return true
    }

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        const notBelongsToUser = ticket => {
            // Se o ticket não tiver departamento e o usuário não puder ver sem departamento
            if (!ticket.departmentId && !can('ticket:see:without:department'))
                return true

            // Se o ticket tiver departamento e o usuário não tiver acesso ao departamento
            if (
                ticket.departmentId &&
                !can('ticket:see:all:department') &&
                !user.departments.some(q => q.id === ticket.departmentId)
            )
                return true

            // Se o ticket tiver atendente e o usuário não tiver acesso ao atendente
            if (
                ticket.userId &&
                !can('ticket:see:other:user') &&
                user.id !== ticket.userId
            )
                return true

            return false
        }

        socketManager.onConnect(() => {
            if (status) {
                socket.emit('joinTickets', status)
            } else {
                socket.emit('joinNotification')
            }
        })

        socket.on(`company-${companyId}-ticket`, data => {
            if (data.action === 'updateUnread') {
                dispatch({
                    type: 'RESET_UNREAD',
                    payload: data.ticketId,
                })
            }

            if (data.action === 'update') {
                if (notBelongsToUser(data.ticket))
                    dispatch({ type: 'DELETE_TICKET', payload: data.ticket.id })
                else if (shouldUpdateTicket(data.ticket))
                    dispatch({ type: 'UPDATE_TICKET', payload: data.ticket })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_TICKET', payload: data.ticketId })
            }
        })

        socket.on(`company-${companyId}-appMessage`, data => {
            if (notBelongsToUser(data.ticket))
                return dispatch({
                    type: 'DELETE_TICKET',
                    payload: data.ticket.id,
                })

            if (
                data.action === 'create' &&
                shouldUpdateTicket(data.ticket) &&
                (!status ||
                    data.ticket.status === status ||
                    status.includes(data.ticket.status))
            ) {
                dispatch({
                    type: 'UPDATE_TICKET_UNREAD_MESSAGES',
                    sort: orderOlder ? 'desc' : 'asc',
                    payload: data.ticket,
                })
            }
        })

        socket.on(`company-${companyId}-contact`, data => {
            if (data.action === 'update') {
                dispatch({
                    type: 'UPDATE_TICKET_CONTACT',
                    payload: data.contact,
                })
            }
        })

        return () => {
            socket.disconnect()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        status,
        user,
        selectedDepartmentIds,
        tags,
        users,
        isGroup,
        can,
        departments,
        socketManager,
        searchParam,
        orderOlder,
    ])

    useEffect(() => {
        if (typeof updateCount === 'function') {
            updateCount(ticketsList.length)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketsList])

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (lastPage <= pageNumber || loading) return

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    return (
        <Paper className={classes.ticketsListWrapper} style={style}>
            <Paper
                square
                name="closed"
                elevation={0}
                className={classes.ticketsList}
                onScroll={handleScroll}>
                <List style={{ paddingTop: 0 }} className={classes.listItem}>
                    {ticketsList.length === 0 && !loading ? (
                        <div className={classes.noTicketsDiv}>
                            <span className={classes.noTicketsTitle}>
                                Nada aqui!
                            </span>
                            <p className={classes.noTicketsText}>
                                Nenhum atendimento encontrado com esse status ou
                                termo pesquisado
                            </p>
                        </div>
                    ) : (
                        <>
                            {ordened.map(ticket => (
                                <TicketListItem
                                    dispatch={dispatch}
                                    ticket={ticket}
                                    key={ticket.id}
                                    search={searchParam}
                                />
                            ))}
                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
        </Paper>
    )
}

export default TicketsListCustom
