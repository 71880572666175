import React from 'react'
import useStyles from '../useStyles'
import RenderMessageMedia from './RenderMessageMedia'
import MarkdownWrapper from 'components/MarkdownWrapper'
import clsx from 'clsx'

export default function Quoted({ currentMessage }) {
    const classes = useStyles()

    const quotedMessage = currentMessage.quotedMsg
    const messageBody = [
        'contactMessage',
        'locationMessage',
        'liveLocationMessage',
        'contactsArrayMessage',
    ].includes(quotedMessage.messageType)
        ? null
        : quotedMessage.body

    if (!quotedMessage) return null

    return (
        <div className={clsx([classes.quoted, 'quoted'])}>
            <span className="side-bar" />

            <div className="container">
                {!quotedMessage.fromMe && (
                    <div className={classes.contact}>
                        {quotedMessage.participantContact?.name}
                    </div>
                )}

                {(quotedMessage.mediaUrl ||
                    [
                        'contactMessage',
                        'locationMessage',
                        'liveLocationMessage',
                        'contactsArrayMessage',
                        'groupInviteMessage',
                    ].includes(quotedMessage.messageType)) && (
                    <RenderMessageMedia message={quotedMessage} />
                )}

                <MarkdownWrapper>{messageBody}</MarkdownWrapper>
            </div>
        </div>
    )
}
