import React from 'react'

import Paper from '@material-ui/core/Paper'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import useDashboard from 'hooks/useDashboard'

import useStyles from '../components/Style'
import TableUsers from './TableUsers'
import DepartmentCards from './DepartmentCards'
import Filter from '../components/Filter'
import ChartToday from './ChartToday'
import InformationCards from './InformationCards'
import InformationCompany from './InformationCompany'
import ChartCounters from './ChartCounters'
import ChartUserTickets from './ChartUserTickets'

const General = () => {
    const classes = useStyles()
    const { findGeneralDashboard } = useDashboard()
    
    const [dashboard, setDashboard] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    
    async function onFilter(params) {
        const data = await findGeneralDashboard(params)
        setDashboard(data.dashboard)
    }

    const today = dashboard?.today || {}
    const counter = dashboard?.counter || {}
    const company = dashboard?.company || {}
    const users = dashboard?.users || []
    const departments = dashboard?.departments || []

    return (
        <div>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={2} justifyContent="flex-start">
                    
                    <Grid item xs={12} md={4} xl={3} >
                        <Grid container spacing={1}>
                            <Filter fullWidth onFilter={onFilter} setLoading={setLoading} loading={loading} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={8} xl={9} >
                        <Paper className={classes.fixedHeightPaper}>
                            <ChartToday dashboardData={today} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2} style={{ display: "flex", justifyContent: "flex-start", height: '100%' }}>
                            <InformationCards dashboardData={counter} companyData={company} />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper className={classes.fixedHeightPaper} style={{ height: 416 }}>
                            <ChartCounters dashboardData={counter} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Paper className={classes.fixedHeightPaper} style={{ height: 416 }}>
                            <ChartUserTickets dashboardData={users} />
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <InformationCompany companyData={company} />
                    </Grid>

                    <Grid item xs={12}>
                        <TableUsers users={users}  />
                    </Grid>

                    <Grid item xs={12}>
                        <DepartmentCards departments={departments} />
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default General
