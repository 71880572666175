import React from 'react'

import { Bar } from 'react-chartjs-2'
import useChartJs from 'hooks/useChartJs'

const ChartUserTickets = ({ dashboardData }) => {
    const { random, getOptions } = useChartJs()

    const chartData = Object.values(dashboardData).filter(user => user?.tickets?.total).sort((user1, user2) => (user2.tickets.total || 0) - (user1?.tickets.total || 0))

    const labels = chartData.map(user => user?.name)
    const values = chartData.map(user => user?.tickets?.total || 0)

    return (
        <Bar
            options={getOptions({
                indexAxis: 'y',
                plugins: {
                    autocolors: { enabled: true, mode: 'data', offset: random() },
                    legend: { display: false },
                    datalabels: {
                        anchor: 'start',
                        align: 'end',
                        color: '#FFF',
                        textShadowBlur: 3,
                        textShadowColor: '#000',
                        font: { weight: 'bold' },
                        formatter: (value, context) => context.chart.data.labels[context.dataIndex],
                    },
                    tooltip: {
                        callbacks: {
                            afterBody: ([tooltip]) => [
                                `Aguardando: ${chartData[tooltip.dataIndex].tickets?.pending?.total}`,
                                `Em Atendimento: ${chartData[tooltip.dataIndex].tickets?.open?.total}`,
                                `Finalizados: ${chartData[tooltip.dataIndex].tickets?.closed?.total}`,
                            ].join('\n'),
                        },
                    },
                },
                scales: {
                    y: {
                        display: false,
                        offset: true,
                    },
                    x: {
                        beginAtZero: true,
                    },
                },
            })}
            data={{
                labels: labels,
                datasets: [
                    { label: 'Atendimentos', data: values, borderRadius: 16 },
                ],
            }}
        />
    )
}

export default ChartUserTickets
