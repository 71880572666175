import { AuthContext } from 'context/Auth/AuthContext'
import { parse } from 'qs'
import { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

export function useFilters(storageName, initial = {}, urlSearch = 'search') {
    const { user } = useContext(AuthContext)
    const location = useLocation()

    let initialFilters = JSON.parse(
        localStorage.getItem(`${storageName}_${user.companyId}`) || '{}',
    )

    if (!Object.keys(initialFilters).length) initialFilters = initial

    const [filters, setFilters] = useState(initialFilters)

    const changeFilters = (filter, value) => {
        const currentFilters = { ...filters }

        if (['', null, undefined].includes(value)) delete currentFilters[filter]
        else currentFilters[filter] = value

        setFilters(currentFilters)
    }

    useEffect(() => {
        const urlParams = parse(location.search.replace('?', ''))
        if (!(urlSearch in urlParams) || !urlParams[urlSearch]) return

        const search = urlParams[urlSearch]

        if (search.clear) {
            delete search.clear
            setFilters({})
        }

        for (const filter in search) changeFilters(filter, search[filter])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    useEffect(() => {
        localStorage.setItem(
            `${storageName}_${user.companyId}`,
            JSON.stringify(filters),
        )
    }, [filters, storageName, user.companyId])

    return {
        filters,
        setFilters,
        changeFilters,
    }
}
