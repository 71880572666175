import React, { useState, useEffect } from 'react'
import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'
import { makeStyles, Paper, Tabs, Tab } from '@material-ui/core'

import TabPanel from '../../components/TabPanel'

import CompaniesManager from '../../components/CompaniesManager'
import PlansManager from '../../components/PlansManager'
import Options from '../../components/Settings/Options'

import { toast } from 'react-toastify'

import useAuth from '../../hooks/useAuth.js'
import useSettings from '../../hooks/useSettings'

import OnlyForSuperUser from '../../components/OnlyForSuperUser'
import useCan from 'hooks/useCan'
import CompanyImages from 'components/Settings/CompanyImages'
import Integrations from 'components/Settings/Integrations'
import useTabs from 'hooks/useTabs/useTabs'
import SettingsTicket from 'components/Settings/SettingsTicket'
import { SettingProvider } from './SettingsContext'

const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
        backgroundColor: theme.palette.background.paper,
    },
    mainPaper: {
        ...theme.scrollbarStyles,
        overflowY: 'scroll',
        flex: 1,
    },
    tab: {
        backgroundColor: theme.palette.options,
        borderRadius: 4,
    },
    paper: {
        ...theme.scrollbarStyles,
        overflowY: 'scroll',
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    container: {
        width: '100%',
        maxHeight: '100%',
    },
}))

const SettingsCustom = () => {
    const classes = useStyles()
    const { canOrReturn } = useCan()
    const { tab, setTab } = useTabs('tickets')
    const [currentUser, setCurrentUser] = useState({})
    const [settings, setSettings] = useState({})

    const { getCurrentUserInfo } = useAuth()
    const { getSetting } = useSettings()

    async function findData() {
        try {
            const settingList = await getSetting()
            setSettings(settingList)

            setCurrentUser(await getCurrentUserInfo())
        } catch (e) {
            toast.error(e)
        }
    }

    useEffect(() => {
        findData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const isSuper = () => {
        return currentUser.super
    }

    if (!canOrReturn(['setting:page', 'setting:update'], '/tickets'))
        return null

    return (
        <SettingProvider settings={settings} setSettings={setSettings}>
            <MainContainer className={classes.root}>
                <MainHeader>
                    <Title>Configurações</Title>
                </MainHeader>
                <Paper className={classes.mainPaper} elevation={1}>
                    <Tabs
                        value={tab}
                        indicatorColor="primary"
                        textColor="primary"
                        scrollButtons="on"
                        variant="scrollable"
                        onChange={(_, tab) => setTab(tab)}
                        className={classes.tab}>
                        <Tab label="Atendimentos" value={'tickets'} />
                        <Tab label="Integrações" value={'integrations'} />
                        <Tab label="Opções" value={'options'} />
                        <Tab label="Imagens" value={'images'} />
                        {isSuper() && (
                            <Tab label="Empresas" value={'companies'} />
                        )}
                        {isSuper() && <Tab label="Planos" value={'plans'} />}
                    </Tabs>
                    <Paper className={classes.paper} elevation={0}>
                        <TabPanel
                            className={classes.container}
                            value={tab}
                            name={'tickets'}>
                            <SettingsTicket />
                        </TabPanel>
                        <TabPanel
                            className={classes.container}
                            value={tab}
                            name={'integrations'}>
                            <Integrations />
                        </TabPanel>
                        <TabPanel
                            className={classes.container}
                            value={tab}
                            name={'options'}>
                            <Options />
                        </TabPanel>
                        <TabPanel
                            className={classes.container}
                            value={tab}
                            name={'images'}>
                            <CompanyImages />
                        </TabPanel>
                        <OnlyForSuperUser
                            user={currentUser}
                            yes={() => (
                                <TabPanel
                                    className={classes.container}
                                    value={tab}
                                    name={'companies'}>
                                    <CompaniesManager />
                                </TabPanel>
                            )}
                        />
                        <OnlyForSuperUser
                            user={currentUser}
                            yes={() => (
                                <TabPanel
                                    className={classes.container}
                                    value={tab}
                                    name={'plans'}>
                                    <PlansManager />
                                </TabPanel>
                            )}
                        />
                    </Paper>
                </Paper>
            </MainContainer>
        </SettingProvider>
    )
}

export default SettingsCustom
