import MomentUtils from '@date-io/moment'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Tooltip,
} from '@material-ui/core'
import { Info } from '@material-ui/icons'
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import QrcodeModal from 'components/QrcodeModal'
import DepartmentSelectCustom from 'components/DepartmentSelectCustom'
import UserSelect from 'components/UserSelect'
import toastError from 'errors/toastError'
import useWhatsApps from 'hooks/useWhatsApps'
import React from 'react'
import api from 'services/api'

const IMPORT = {
    PARTIAL: 'partial',
    ALL: 'all',
    NONE: 'none',
}

const SessionConnectModal = ({ open, onClose, whatsAppId }) => {
    const classes = useStyles()

    const { whatsApp } = useWhatsApps(whatsAppId)

    const [confirmationOpen, setConfirmationOpen] = React.useState(false)
    const [definitionOpen, setDefinitionOpen] = React.useState(false)
    const [qrCodeOpen, setQrCodeOpen] = React.useState(false)

    const [definition, setDefinition] = React.useState({
        import: null,
        importStatus: 'closed',
        importAfter: whatsApp?.disconnectedAt ? whatsApp.disconnectedAt : null,
        importUserId: null,
        importDepartmentId: null,
    })

    React.useEffect(() => {
        if (!open) {
            setDefinitionOpen(false)
            setQrCodeOpen(false)
        }
        setDefinition({
            import: null,
            importStatus: 'closed',
            importAfter: whatsApp?.disconnectedAt
                ? whatsApp.disconnectedAt
                : null,
            importUserId: null,
            importDepartmentId: null,
        })
        setConfirmationOpen(open)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    React.useEffect(() => {
        if (!whatsAppId || !qrCodeOpen) return

        api.put(`/whatsapp/${whatsAppId}`, definition).catch(err =>
            toastError(err),
        )
    }, [qrCodeOpen, definition, whatsAppId])

    if (!open) return null

    const handleImportResponse = async importChats => {
        setConfirmationOpen(false)

        setDefinition(prev => ({ ...prev, import: importChats }))
        setQrCodeOpen(importChats === IMPORT.NONE)
        setDefinitionOpen(importChats !== IMPORT.NONE)
    }

    const handleDefinition = () => {
        setDefinitionOpen(false)
        setQrCodeOpen(true)
    }

    const handleChange = (name, value, remove = false) => {
        setDefinition(prev => {
            if (remove) delete prev[name]
            else prev[name] = value
            return { ...prev }
        })
    }

    return (
        <>
            <Dialog open={confirmationOpen} onClose={onClose} maxWidth="md">
                <DialogTitle>
                    Deseja importar o histórico de conversas do WhatsApp?
                </DialogTitle>
                <DialogActions>
                    <Tooltip
                        title="Importar DETERMINADO PERIÓDO de conversas/mensagens de seu WhatsApp."
                        placement="top"
                        arrow>
                        <Button
                            onClick={() => handleImportResponse(IMPORT.PARTIAL)}
                            color="primary">
                            Histórico Parcial
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title="Importar TODAS as conversas/mensagens de seu WhatsApp."
                        placement="top"
                        arrow>
                        <Button
                            onClick={() => handleImportResponse(IMPORT.ALL)}
                            color="primary">
                            Histórico Completo
                        </Button>
                    </Tooltip>
                    <Button
                        onClick={() => handleImportResponse(IMPORT.NONE)}
                        color="secondary"
                        autoFocus>
                        Não importar
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={definitionOpen} onClose={onClose} maxWidth="md">
                <DialogTitle>
                    <Grid
                        container
                        className={classes.definitionTitleContainer}>
                        Definição das Conversas
                        <Tooltip
                            title={`Defina as propriedades das conversas que serão importadas.`}
                            placement="right"
                            arrow>
                            <Info fontSize="small" />
                        </Tooltip>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        {definition.import === IMPORT.PARTIAL && (
                            <KeyboardDateTimePicker
                                disableFuture
                                ampm={false}
                                label="Importar mensagens a partir de:"
                                size="small"
                                fullWidth
                                required
                                inputVariant="outlined"
                                format="DD/MM/yyyy HH:mm"
                                name="after"
                                value={definition.importAfter}
                                error={!definition.importAfter}
                                helperText={
                                    !definition.importAfter
                                        ? 'A data deve ser inferior a data atual!'
                                        : ''
                                }
                                onChange={event =>
                                    handleChange(
                                        'importAfter',
                                        event?.format('YYYY-MM-DD HH:mm:ss'),
                                    )
                                }
                            />
                        )}
                        <FormControl
                            fullWidth
                            margin="dense"
                            variant="outlined">
                            <InputLabel id="status-ticket-label">
                                Status do Ticket
                            </InputLabel>
                            <Select
                                required
                                label="Status do ticket"
                                id="status-ticket"
                                labelId="status-ticket-label"
                                value={definition.importStatus}
                                onChange={event =>
                                    handleChange(
                                        'importStatus',
                                        event.target.value,
                                    )
                                }
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}>
                                <MenuItem key="closed" value="closed">
                                    Finalizado
                                </MenuItem>
                                <MenuItem key="pending" value="pending">
                                    Aguardando
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <UserSelect
                            label="Usuário"
                            value={definition.importUserId}
                            onChange={values =>
                                handleChange('importUserId', values)
                            }
                        />
                        <DepartmentSelectCustom
                            chip={false}
                            multiple={false}
                            clearable={true}
                            label="Setor"
                            companyId={whatsApp?.companyId}
                            className="MuiFormControl-root"
                            selectedDepartmentIds={definition.importDepartmentId}
                            onChange={values =>
                                handleChange('importDepartmentId', values)
                            }
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="secondary" autoFocus>
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleDefinition}
                        color="primary"
                        autoFocus>
                        Continuar
                    </Button>
                </DialogActions>
            </Dialog>
            <QrcodeModal
                open={qrCodeOpen}
                onClose={onClose}
                whatsAppId={whatsAppId}
            />
        </>
    )
}

export default SessionConnectModal

const useStyles = makeStyles(theme => ({
    definitionTitleContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}))
