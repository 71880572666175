import React, { useEffect, useState } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    inactiveUser: {
        opacity: 0.5,
        textDecoration: 'line-through',
    }
}))

const UserSelect = ({
    value,
    onChange,
    multiple = false,
    label = 'Usuários',
    style = {},
}) => {
    const classes = useStyles()
    const [users, setUsers] = useState([])

    const fetchUsers = async () => {
        try {
            const { data } = await api.get('/users', {
                sort: 'inactive,name'
            })
            setUsers(data.data)
        } catch (err) {
            toastError(err)
        }
    }

    useEffect(() => {
        fetchUsers()
    }, [multiple, value])

    const handleChange = e => {
        onChange(e.target.value)
    }

    return (
        <FormControl
            fullWidth
            size="small"
            variant="outlined"
            style={{ minWidth: '8rem', ...style }}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                multiple={multiple}
                labelWidth={60}
                value={value}
                onChange={handleChange}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                renderValue={selected => {
                    const usersToShow = users.filter(
                        user =>
                            user.id === selected ||
                            (Array.isArray(selected) &&
                                selected.includes(user.id)),
                    )
                    return usersToShow.map(user => user.name).join(', ')
                }}>
                {!multiple && <MenuItem value={null}>Selecione...</MenuItem>}
                {Boolean(users?.length) &&
                    users?.map(user => (
                        <MenuItem key={user.id} value={user.id}>
                            <span
                                className={
                                    user.inactive
                                        ? classes.inactiveUser
                                        : null
                                }>
                                {user.name}
                            </span>
                        </MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}

export default UserSelect
