import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import { Field } from 'formik'
import { Alert } from '@material-ui/lab'

const FindPersonForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <FormControl
                    variant="outlined"
                    fullWidth
                    error={props.errors.type}>
                    <InputLabel htmlFor="type">Buscar com</InputLabel>
                    <Field
                        as={Select}
                        id="type"
                        label="Buscar com"
                        labelId="type-label"
                        name="type"
                        required>
                        <MenuItem value={null}>Selecione...</MenuItem>
                        <MenuItem value="number">Número</MenuItem>
                        <MenuItem value="tax_id">CPF/CNPJ</MenuItem>
                    </Field>

                    {Boolean(props.errors.type) && (
                        <FormHelperText>{props.errors.type}</FormHelperText>
                    )}
                </FormControl>
            </Grid>

            <Alert style={{ width: '100%' }} severity="info">
                {props.values.type === 'tax_id'
                    ? 'Será questionado o CPF/CNPJ do contato e então será buscada uma pessoa com esse dado na Apresenta.me'
                    : 'Será buscada a Pessoa na Apresenta.me usando o número do contato sem questionar o contato'}
            </Alert>
        </Grid>
    )
}

export default FindPersonForm
