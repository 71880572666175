import React from 'react'

import { Doughnut } from 'react-chartjs-2'
import useChartJs from 'hooks/useChartJs'

const chartStructure = {
    bot:     { title: 'No Bot',         value: 0, color: 'rgba(255, 170, 11, 0.7)' },
    pending: { title: 'Aguardando',     value: 0, color: 'rgba(244, 83, 72, 0.7)' },
    open:    { title: 'Em Atendimento', value: 0, color: 'rgba(67, 223, 132, 0.7)'    },
    closed:  { title: 'Finalizado',     value: 0, color: 'rgba(69, 173, 242, 0.7)'  },
}

const ChartCounters = ({ dashboardData }) => {
    const { backgroundColor, getOptions } = useChartJs()

    const chartData = { ...chartStructure }
    Object.entries(dashboardData).forEach(([key, counter]) => (chartData[key].value = counter.total || 0))

    return (
        <Doughnut 
            options={
                getOptions({
                    plugins: {
                        legend: { position: 'bottom' },
                        title: { display: false },
                        datalabels: {
                            backgroundColor: context => context.dataset.backgroundColor,
                            borderColor: backgroundColor,
                            borderRadius: 50,
                            borderWidth: 2,
                            color: 'white',
                            display: true,
                            padding: { left: 8, right: 8 },
                            font: { weight: 'bold' },
                        },
                        tooltip: {
                            callbacks: {
                                afterBody: ([tooltip]) => {
                                    const status = Object.keys(chartData).at(tooltip.dataIndex)
                                    if (!(status in dashboardData) || !dashboardData[status])
                                        return null

                                    const title = []
                                    if ('ticket' in dashboardData[status])
                                        title.push(`Contatos: ${dashboardData[status]?.ticket}`)

                                    if ('group' in dashboardData[status])
                                        title.push(`Grupos: ${dashboardData[status]?.group}`)

                                    return title.join('\n')
                                },
                            },
                        }
                    },
                })
            } 
            data={{
                labels: Object.values(chartData).map(counter => counter.title),
                datasets: [
                    {
                        label: 'Total',
                        data: Object.values(chartData).map(counter => counter.value),
                        backgroundColor: Object.values(chartData).map(
                            counter => counter.color,
                        ),
                        borderColor: backgroundColor,
                        borderRadius: 6,
                    },
                ],
            }} 
        />
    )
}

export default ChartCounters
