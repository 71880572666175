import useSettings from 'hooks/useSettings'
import React, { createContext, useCallback } from 'react'

const SettingContext = createContext()

const SettingProvider = ({ children, settings, setSettings }) => {
    const { setSetting: saveSetting } = useSettings()

    const setSetting = useCallback(
        (setting, value) => {
            saveSetting(setting, value)
            setSettings({ ...settings, [setting]: value })
        },
        [settings, saveSetting, setSettings],
    )

    return (
        <SettingContext.Provider value={{ settings, setSettings, setSetting }}>
            {children}
        </SettingContext.Provider>
    )
}

export { SettingContext, SettingProvider }
