import { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Badge from '@material-ui/core/Badge'
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import GroupIcon from '@material-ui/icons/Group'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import FilterListIcon from '@material-ui/icons/FilterList'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'

import NewTicketModal from '../NewTicketModal'
import TicketsList from '../TicketsListCustom'
import TabPanel from '../TabPanel'

import { AuthContext } from '../../context/Auth/AuthContext'
import TicketsDepartmentSelect from '../TicketsDepartmentSelect'
import { Box, Collapse, IconButton } from '@material-ui/core'
import { TagsFilter } from '../TagsFilter'
import { UsersFilter } from '../UsersFilter'
import useCan from 'hooks/useCan'
import { DateTimeBetweenFilter } from 'components/DateTimeBetweenFilter'
import { UnansweredFilter } from 'components/UnansweredFilter'
import ConnectionSelect from 'components/ConnectionSelect'

const useStyles = makeStyles(theme => ({
    ticketsWrapper: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        borderRadius: 0,

        '& .MuiTabs-flexContainer .MuiButtonBase-root': {
            minWidth: 'unset',
        },
    },

    tabsHeader: {
        flex: 'none',
        backgroundColor: theme.palette.tabHeaderBackground,
    },

    tabsInternal: {
        flex: 'none',
        backgroundColor: theme.palette.tabHeaderBackground,
    },

    settingsIcon: {
        alignSelf: 'center',
        marginLeft: 'auto',
        padding: 8,
    },

    tab: {
        minWidth: 120,
        width: 120,
    },

    internalTab: {
        minWidth: 120,
        width: 120,
        padding: 5,
    },

    ticketOptionsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 5,
        alignItems: 'center',
        background: theme.palette.optionsBackground,
        padding: theme.spacing(1),
    },

    ticketSearchLine: {
        padding: theme.spacing(1),
    },

    serachInputWrapper: {
        flex: 1,
        background: theme.palette.total,
        display: 'flex',
        borderRadius: 40,
        padding: 4,
        marginRight: theme.spacing(1),
    },

    searchIcon: {
        color: 'grey',
        marginLeft: 6,
        marginRight: 6,
        alignSelf: 'center',
    },

    searchInput: {
        flex: 1,
        border: 'none',
        borderRadius: 30,
    },

    insiderTabPanel: {
        height: '100%',
        marginTop: '-72px',
        paddingTop: '72px',
    },

    insiderDoubleTabPanel: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-72px',
        paddingTop: '72px',
        height: '100%',
    },

    labelContainer: {
        width: 'auto',
        padding: 0,
    },
    iconLabelWrapper: {
        flexDirection: 'row',
        '& > *:first-child': {
            marginBottom: '3px !important',
            marginRight: 16,
        },
    },
    insiderTabLabel: {
        [theme.breakpoints.down(1600)]: {
            display: 'none',
        },
    },
    smallFormControl: {
        '& .MuiOutlinedInput-input': {
            padding: '12px 10px',
        },
        '& .MuiInputLabel-outlined': {
            marginTop: '-6px',
        },
    },
}))

const TicketsManagerTabs = () => {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const { user } = useContext(AuthContext)
    const [tab, setTab] = useState('open')
    const [tabOpen, setTabOpen] = useState('open')

    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false)

    const [openCount, setOpenCount] = useState(0)
    const [pendingCount, setPendingCount] = useState(0)
    const [botCount, setBotCount] = useState(0)

    const filters = JSON.parse(
        localStorage.getItem(`ticketFilter_${user.companyId}`) || '{}',
    )

    const [filterOpened, setFilterOpened] = useState(false)
    const [searchParam, setSearchParam] = useState(filters.searchParam || '')
    const [orderOlder, setOrderOlder] = useState(filters.orderOlder || false)

    const userDepartmentIds = Array.isArray(filters?.selectedDepartmentIds) ? filters.selectedDepartmentIds : user.departments?.map(q => q.id)
    const [selectedDepartmentIds, setSelectedDepartmentIds] = useState(userDepartmentIds ?? [])
    const [selectedTags, setSelectedTags] = useState(filters.selectedTags || [])
    const [selectedUsers, setSelectedUsers] = useState(filters.selectedUsers || ['noUser', user.id])
    const [selectedConnection, setSelectedConnection] = useState(filters.selectedConnection || null)
    const [selectedUnanswered, setSelectedUnanswered] = useState(filters.selectedUnanswered || {
        type: null,
        minutes: null,
    })
    const [messagesBetween, setMessagesBetween] = useState(filters.messagesBetween || {
        start: null,
        end: null,
    })
    const { can } = useCan()

    const filterCount =
        !!selectedConnection +
        !!selectedTags?.length +
        !!selectedUsers?.length +
        !!messagesBetween?.start +
        !!selectedUnanswered?.type

    useEffect(() => {
        if (
            !Array.isArray(filters?.selectedDepartmentIds) &&
            can('ticket:see:without:department')
        ) {
            setSelectedDepartmentIds(old => {
                old.unshift(null)
                return old
            })
        }
    }, [can, filters])

    useEffect(() => {
        localStorage.setItem(
            `ticketFilter_${user.companyId}`,
            JSON.stringify({
                orderOlder,
                searchParam,
                selectedDepartmentIds,
                selectedTags,
                selectedUsers,
                selectedConnection,
                messagesBetween,
                selectedUnanswered,
            }),
        )
    }, [
        orderOlder,
        searchParam,
        selectedDepartmentIds,
        selectedTags,
        selectedUsers,
        selectedConnection,
        messagesBetween,
        selectedUnanswered,
        user.companyId,
    ])

    const handleSearch = e => {
        const searchedTerm = e.target.value
        setSearchParam(searchedTerm)
    }

    const applyPanelStyle = status => {
        if (tabOpen !== status) {
            return { width: 0, height: 0 }
        }
    }

    const handleCloseOrOpenTicket = ticket => {
        setNewTicketModalOpen(false)
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`)
        }
    }

    const handleSelectedTags = selecteds => {
        const tags = selecteds?.map(t => t.id)
        setSelectedTags(tags)
    }

    useEffect(() => {
        const query = new URLSearchParams(location.search)
        const tabQuery = query.get('tab')

        if (tabQuery) {
            setTab(tabQuery)

            const statusQuery = query.get('status')
            if (statusQuery && tabQuery === 'open') setTabOpen(statusQuery)
        }
    }, [location.search])

    return (
        <Paper
            elevation={0}
            variant="outlined"
            className={classes.ticketsWrapper}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                onClose={ticket => {
                    handleCloseOrOpenTicket(ticket)
                }}
            />
            <Paper elevation={0} square className={classes.tabsHeader}>
                <Tabs
                    value={tab}
                    onChange={(e, newValue) => setTab(newValue)}
                    variant="fullWidth"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="icon label tabs example">
                    <Tab
                        value={'open'}
                        icon={<MoveToInboxIcon />}
                        label={'Abertas'}
                        classes={{ root: classes.tab }}
                    />
                    <Tab
                        value={'closed'}
                        icon={<CheckBoxIcon />}
                        label={'Resolvidos'}
                        classes={{ root: classes.tab }}
                    />
                    <Tab
                        value={'group'}
                        icon={<GroupIcon />}
                        label={'Grupos'}
                        classes={{ root: classes.tab }}
                    />
                </Tabs>
            </Paper>
            <Paper
                square
                elevation={0}
                style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.ticketOptionsBox}>
                    <IconButton
                        variant="outlined"
                        color="primary"
                        onClick={() => setNewTicketModalOpen(true)}>
                        <AddCircleIcon />
                    </IconButton>

                    <div className={classes.serachInputWrapper}>
                        <SearchIcon className={classes.searchIcon} />
                        <InputBase
                            className={classes.searchInput}
                            placeholder={'Buscar atendimento e mensagens'}
                            type="search"
                            value={searchParam}
                            onChange={handleSearch}
                        />
                    </div>
                    <TicketsDepartmentSelect
                        style={{ marginLeft: 6 }}
                        selectedDepartmentIds={selectedDepartmentIds}
                        userDepartments={user?.departments.sort((a, b) =>
                            a.name.localeCompare(b.name),
                        )}
                        onChange={values => {
                            setSelectedDepartmentIds(values)
                        }}
                    />

                    <Badge
                        overlap="circular"
                        color="secondary"
                        badgeContent={filterCount}
                        invisible={!filterCount}>
                        <IconButton
                            size="small"
                            onClick={() =>
                                setFilterOpened(filterOpened => !filterOpened)
                            }>
                            <FilterListIcon />
                        </IconButton>
                    </Badge>
                </div>

                <Collapse in={filterOpened}>
                    <FormControlLabel
                        label="Antigos primeiro"
                        labelPlacement="start"
                        control={
                            <Switch
                                size="small"
                                checked={orderOlder}
                                onChange={() =>
                                    setOrderOlder(prevState => !prevState)
                                }
                                name="orderOlder"
                                color="primary"
                            />
                        }
                    />

                    <TagsFilter onSelect={handleSelectedTags} />
                    <UsersFilter
                        onChange={setSelectedUsers}
                        value={selectedUsers}
                    />
                    <Box style={{ padding: '0px 10px 10px' }}>
                        <ConnectionSelect
                            value={selectedConnection}
                            onChange={setSelectedConnection}
                            size="small"
                            label={'Filtro de Conexões'}
                            defaultText={null}
                            nullSelect
                        />
                    </Box>
                    <DateTimeBetweenFilter
                        onChange={setMessagesBetween}
                        value={messagesBetween}
                    />
                    <UnansweredFilter
                        onChangeType={value =>
                            setSelectedUnanswered({
                                type: value,
                                minutes: null,
                            })
                        }
                        onChangeMinutes={value =>
                            setSelectedUnanswered({
                                ...selectedUnanswered,
                                minutes: value,
                            })
                        }
                        valueType={selectedUnanswered.type}
                        valueMinutes={selectedUnanswered.minutes}
                    />
                </Collapse>
            </Paper>
            <TabPanel
                value={tab}
                name="open"
                className={classes.ticketsWrapper}>
                <Tabs
                    value={tabOpen}
                    onChange={(e, newValue) => setTabOpen(newValue)}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth">
                    <Tab
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={openCount}
                                color="primary">
                                Atendendo
                            </Badge>
                        }
                        value={'open'}
                    />
                    <Tab
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={pendingCount}
                                color="secondary">
                                Aguardando
                            </Badge>
                        }
                        value={'pending'}
                    />
                    <Tab
                        label={
                            <Badge
                                className={classes.badge}
                                badgeContent={botCount}
                                color="secondary">
                                No Bot
                            </Badge>
                        }
                        value={'bot'}
                    />
                </Tabs>
                <Paper className={classes.ticketsWrapper}>
                    <TicketsList
                        searchParam={searchParam}
                        status="open"
                        orderOlder={orderOlder}
                        selectedDepartmentIds={selectedDepartmentIds}
                        tags={selectedTags}
                        users={selectedUsers}
                        connections={selectedConnection}
                        messagesBetween={messagesBetween}
                        unansweredMessages={selectedUnanswered}
                        isGroup={false}
                        updateCount={val => setOpenCount(val)}
                        style={applyPanelStyle('open')}
                    />
                    <TicketsList
                        // Não filtra por usuários pois tickets pendentes nunca tem usuário definido
                        searchParam={searchParam}
                        status="pending"
                        orderOlder={orderOlder}
                        selectedDepartmentIds={selectedDepartmentIds}
                        tags={selectedTags}
                        connections={selectedConnection}
                        messagesBetween={messagesBetween}
                        unansweredMessages={selectedUnanswered}
                        isGroup={false}
                        updateCount={val => setPendingCount(val)}
                        style={applyPanelStyle('pending')}
                    />
                    <TicketsList
                        searchParam={searchParam}
                        status="bot"
                        orderOlder={orderOlder}
                        selectedDepartmentIds={selectedDepartmentIds}
                        tags={selectedTags}
                        users={selectedUsers}
                        connections={selectedConnection}
                        messagesBetween={messagesBetween}
                        unansweredMessages={selectedUnanswered}
                        isGroup={false}
                        updateCount={val => setBotCount(val)}
                        style={applyPanelStyle('bot')}
                    />
                </Paper>
            </TabPanel>
            <TabPanel
                value={tab}
                name="closed"
                className={classes.ticketsWrapper}>
                <TicketsList
                    searchParam={searchParam}
                    status="closed"
                    orderOlder={orderOlder}
                    tags={selectedTags}
                    users={selectedUsers}
                    connections={selectedConnection}
                    messagesBetween={messagesBetween}
                    unansweredMessages={selectedUnanswered}
                    selectedDepartmentIds={selectedDepartmentIds}
                />
            </TabPanel>
            <TabPanel
                value={tab}
                name="group"
                className={classes.ticketsWrapper}>
                <TicketsList
                    searchParam={searchParam}
                    orderOlder={orderOlder}
                    isGroup={true}
                    tags={selectedTags}
                    status={['pending', 'open', 'bot']}
                    users={selectedUsers}
                    connections={selectedConnection}
                    messagesBetween={messagesBetween}
                    unansweredMessages={selectedUnanswered}
                    selectedDepartmentIds={selectedDepartmentIds}
                />
            </TabPanel>
        </Paper>
    )
}

export default TicketsManagerTabs
