import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { contrastColor } from 'helpers/helpers'

const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}))

const DepartmentSelectCustom = ({
    selectedDepartmentIds,
    companyId,
    onChange,
    style = {},
    clearable = false,
    multiple = true,
    chip = true,
    label = 'Setores',
}) => {
    const classes = useStyles()
    const [departments, setDepartments] = useState([])

    useEffect(() => {
        ;(async () => {
            try {
                const { data } = await api.get('/department', {
                    companyId,
                })
                setDepartments(data)
            } catch (err) {
                toastError(err)
            }
        })()
    }, [companyId])

    const handleChange = e => {
        onChange(e.target.value)
    }

    const renderValue = selected => (
        <div className={classes.chips}>
            {selected?.length > 0 &&
                selected.map(id => {
                    const department = departments.find(q => q.id === id)
                    return department ? (
                        <Chip
                            key={id}
                            style={{
                                backgroundColor: department.color,
                                color: contrastColor(department?.color),
                            }}
                            variant="outlined"
                            label={department.name}
                            className={classes.chip}
                        />
                    ) : null
                })}
        </div>
    )

    return (
        <FormControl
            fullWidth
            size="small"
            variant="outlined"
            style={{ minWidth: '8rem', ...style }}>
            <InputLabel>{label}</InputLabel>
            <Select
                multiple={multiple}
                label={label}
                value={selectedDepartmentIds}
                onChange={handleChange}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                renderValue={chip ? renderValue : null}>
                {clearable && <MenuItem value={null}>Selecione...</MenuItem>}
                {departments.map(department => (
                    <MenuItem key={department.id} value={department.id}>
                        {department.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default DepartmentSelectCustom
