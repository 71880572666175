import React, { useContext, useEffect, useReducer, useState } from 'react'

import {
    Button,
    Grid,
    IconButton,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import MainHeaderButtonsWrapper from '../../components/MainHeaderButtonsWrapper'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import Title from '../../components/Title'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { AddCircleOutline, DeleteOutline, Edit } from '@material-ui/icons'
import DepartmentModal from '../../components/DepartmentModal'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../components/ConfirmationModal'
import { SocketContext } from '../../context/Socket/SocketContext'
import useCan from 'hooks/useCan'

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
    customTableCell: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const reducer = (state, action) => {
    if (action.type === 'LOAD_DEPARTMENTS') {
        const departments = action.payload
        const newDepartments = []

        departments.forEach(department => {
            const departmentIndex = state.findIndex(q => q.id === department.id)
            if (departmentIndex !== -1) {
                state[departmentIndex] = department
            } else {
                newDepartments.push(department)
            }
        })

        return [...state, ...newDepartments]
    }

    if (action.type === 'UPDATE_DEPARTMENTS') {
        const department = action.payload
        const departmentIndex = state.findIndex(u => u.id === department.id)

        if (departmentIndex !== -1) {
            state[departmentIndex] = department
            return [...state]
        } else {
            return [department, ...state]
        }
    }

    if (action.type === 'DELETE_DEPARTMENT') {
        const departmentId = action.payload
        const departmentIndex = state.findIndex(q => q.id === departmentId)
        if (departmentIndex !== -1) {
            state.splice(departmentIndex, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const Departments = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [departments, dispatch] = useReducer(reducer, [])
    const [loading, setLoading] = useState(false)

    const [departmentModalOpen, setDepartmentModalOpen] = useState(false)
    const [selectedDepartment, setSelectedDepartment] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)

    useEffect(() => {
        ;(async () => {
            setLoading(true)
            try {
                const { data } = await api.get('/department')
                dispatch({ type: 'LOAD_DEPARTMENTS', payload: data })

                setLoading(false)
            } catch (err) {
                toastError(err)
                setLoading(false)
            }
        })()
    }, [])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socket.on(`company-${companyId}-department`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_DEPARTMENTS', payload: data.department })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_DEPARTMENT', payload: data.departmentId })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [socketManager])

    const handleOpenDepartmentModal = () => {
        setDepartmentModalOpen(true)
        setSelectedDepartment(null)
    }

    const handleCloseDepartmentModal = () => {
        setDepartmentModalOpen(false)
        setSelectedDepartment(null)
    }

    const handleEditDepartment = department => {
        setSelectedDepartment(department)
        setDepartmentModalOpen(true)
    }

    const handleCloseConfirmationModal = () => {
        setConfirmModalOpen(false)
        setSelectedDepartment(null)
    }

    const handleDeleteDepartment = async departmentId => {
        try {
            await api.delete(`/department/${departmentId}`)
            toast.success('Setor excluído com sucesso!')
        } catch (err) {
            toastError(err)
        }
        setSelectedDepartment(null)
    }

    if (
        !canOrReturn(
            [
                'department:page',
                'department:delete',
                'department:create',
                'department:update',
            ],
            '/tickets',
        )
    )
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    selectedDepartment &&
                    `Excluir ${selectedDepartment.name}?`
                }
                open={confirmModalOpen}
                onClose={handleCloseConfirmationModal}
                onConfirm={() => handleDeleteDepartment(selectedDepartment.id)}
            >
                Você tem certeza? Essa ação não pode ser revertida! Os atendimentos desse setor continuarão existindo, mas não terão mais nenhum setor atribuída.
            </ConfirmationModal>
            <DepartmentModal
                open={departmentModalOpen}
                onClose={handleCloseDepartmentModal}
                departmentId={selectedDepartment?.id}
            />
            <MainHeader>
                <Title>Setores</Title>
                <MainHeaderButtonsWrapper>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenDepartmentModal}>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            style={{ gap: 4 }}>
                            <AddCircleOutline />
                            Adicionar
                        </Grid>
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper className={classes.mainPaper} variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                ID
                            </TableCell>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                Cor
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {departments.map(department => (
                                <TableRow key={department.id}>
                                    <TableCell align="left">
                                        {department.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {department.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        <div
                                            className={classes.customTableCell}>
                                            <span
                                                style={{
                                                    backgroundColor:
                                                        department.color,
                                                    width: 60,
                                                    height: 20,
                                                    alignSelf: 'center',
                                                }}
                                            />
                                        </div>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleEditDepartment(department)
                                            }>
                                            <Edit />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setSelectedDepartment(department)
                                                setConfirmModalOpen(true)
                                            }}>
                                            <DeleteOutline />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Departments
