import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    edgesContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        paddingBlock: 5,
    },

    edges: {
        position: 'relative',
        fontSize: '0.6rem',
        lineHeight: 1,
        paddingInline: 5,
        color: '#284359',
    },
}))

function FindPersonNode(myNode) {
    const { data, ...props } = myNode
    const classes = useStyles()

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Pessoa no Sistema"
                icon={AssignmentIndIcon}
                noContainer>
                <div className={classes.edgesContainer}>
                    <div className={[classes.edgeNo, classes.edges].join(' ')}>
                        <CustomStartHandle
                            position={Position.Left}
                            id={props.id + 'no'}
                            nodeId={props.id}
                        />
                        Não achou
                    </div>

                    <div className={[classes.edgeYes, classes.edges].join(' ')}>
                        Achou
                        <CustomStartHandle
                            position={Position.Right}
                            id={props.id + 'yes'}
                            nodeId={props.id}
                        />
                    </div>
                </div>
            </CustomNodeBase>
        </div>
    )
}

export default FindPersonNode
