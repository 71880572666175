import React, { useState, useEffect, useContext } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Colorize } from '@material-ui/icons'
import { ColorBox } from 'material-ui-color'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import { AuthContext } from '../../context/Auth/AuthContext'
import { IconButton, InputAdornment } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}))

const TagSchema = Validator.object().shape({
    name: Validator.string().min(3).required(),
})

const TagModal = ({ open, onClose, tagId, reload }) => {
    const classes = useStyles()
    const { user } = useContext(AuthContext)
    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false)

    const initialState = {
        name: '',
        color: '',
    }

    const [tag, setTag] = useState(initialState)

    useEffect(() => {
        try {
            ;(async () => {
                if (!tagId) return

                const { data } = await api.get(`/tags/${tagId}`)
                setTag(prevState => {
                    return { ...prevState, ...data }
                })
            })()
        } catch (err) {
            toastError(err)
        }
    }, [tagId, open])

    const handleClose = () => {
        setTag(initialState)
        setColorPickerModalOpen(false)
        onClose()
    }

    const handleSaveTag = async values => {
        const tagData = { ...values, userId: user.id }
        try {
            if (tagId) {
                await api.put(`/tags/${tagId}`, tagData)
            } else {
                await api.post('/tags', tagData)
            }
            toast.success('Tag salvo com sucesso.')
            if (typeof reload == 'function') {
                reload()
            }
        } catch (err) {
            toastError(err)
        }
        handleClose()
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {tagId ? 'Editar Tag' : 'Nova Tag'}
                </DialogTitle>
                <Formik
                    initialValues={tag}
                    enableReinitialize={true}
                    validationSchema={TagSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveTag(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label="Nome"
                                        name="name"
                                        error={
                                            touched.name && Boolean(errors.name)
                                        }
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        onBlur={e =>
                                            setTag(prev => ({
                                                ...prev,
                                                name: e.target.value,
                                            }))
                                        }
                                        fullWidth
                                    />
                                </div>
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        fullWidth
                                        label="Cor"
                                        name="color"
                                        id="color"
                                        error={
                                            touched.color &&
                                            Boolean(errors.color)
                                        }
                                        helperText={
                                            touched.color && errors.color
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <div
                                                        style={{
                                                            backgroundColor:
                                                                values.color,
                                                        }}
                                                        className={
                                                            classes.colorAdorment
                                                        }></div>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <IconButton
                                                    size="small"
                                                    color="default"
                                                    onClick={() =>
                                                        setColorPickerModalOpen(
                                                            !colorPickerModalOpen,
                                                        )
                                                    }>
                                                    <Colorize />
                                                </IconButton>
                                            ),
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                    />
                                </div>
                                {colorPickerModalOpen && (
                                    <div>
                                        <ColorBox
                                            disableAlpha={true}
                                            hslGradient={false}
                                            style={{ margin: '20px auto 0' }}
                                            value={tag.color}
                                            onChange={val => {
                                                setTag(prev => ({
                                                    ...prev,
                                                    color: `#${val.hex}`,
                                                }))
                                            }}
                                        />
                                    </div>
                                )}
                                <br />
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label="Descrição de Uso"
                                        name="description"
                                        rows={3}
                                        multiline
                                        error={touched.description && Boolean(errors.description)}
                                        helperText={touched.description && errors.description}
                                        variant="outlined"
                                        margin="dense"
                                        onBlur={e =>
                                            setTag(prev => ({
                                                ...prev,
                                                description: e.target.value,
                                            }))
                                        }
                                        fullWidth
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    {tagId ? 'Salvar' : 'Adicionar'}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default TagModal
