import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'

import Filter from '../components/Filter'
import useStyles from '../components/Style'
import useDashboard from 'hooks/useDashboard'
import DashTextItem from '../components/DashTextItem'
import ChartUserTickets from './ChartUserTickets'
import ChartUserMessages from './ChartUserMessages'
import TableClientMessage from './TableClientMessage'

const Metrics = () => {
    const classes = useStyles()

    const { findMetricsDashboard } = useDashboard()
    
    const [dashboard, setDashboard] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    
    async function onFilter(params) {
        const data = await findMetricsDashboard(params)
        setDashboard(data.dashboard)
    }

    const informations = dashboard?.informations || {}
    const tickets = dashboard?.tickets || []
    const messages = dashboard?.messages || []
    const client = dashboard?.client || []

    return (
        <div>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Filter onFilter={onFilter} setLoading={setLoading} loading={loading} />

                    <Grid container spacing={2} style={{ justifyContent: "space-evenly", padding: '1rem 0' }}>

                        <DashTextItem reverse label={'minutos'} name={'Tempo Total de Atendimento'} value={informations?.minutes ?? 0} />

                        <DashTextItem reverse label={'mensagens'} name={'Total de Mensagens'} value={informations?.messages ?? 0} />

                        <DashTextItem reverse label={'contatos'} name={'Novos Contatos'} value={informations?.newContacts ?? 0} />

                        <DashTextItem reverse label={'atendimentos'} name={'Atendimentos Abertos'} value={(informations?.tickets?.pending?.total ?? 0) + (informations?.tickets?.open?.total ?? 0)} />

                        <DashTextItem reverse label={'atendimentos'} name={'Atendimentos Finalizados'} value={informations?.tickets?.closed?.total ?? 0} />

                    </Grid>
                    
                    <Grid item xs={12}>
                        <ChartUserMessages dashboardData={messages} />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartUserTickets dashboardData={tickets} />
                    </Grid>

                    <Grid item xs={12}>
                        <TableClientMessage contacts={client} />
                    </Grid>

                </Grid>
            </Container>
        </div>
    )
}

export default Metrics
