import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import useStyles from './Style';
import useCan from 'hooks/useCan';
import clsx from 'clsx'

const DashItem = ({ xs = 12, sm = 6, md = 4, lg = 2, title, value, icon, permission, page, ...props }) => {
    const classes = useStyles()
    const history = useHistory()
    const { can } = useCan()

    const hasPermission = !page || !permission || can(permission)

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} onClick={() => page && hasPermission && history.push(`/${page}`)}>
            <Paper
                className={clsx([classes.card, hasPermission ? '' : classes.cannt])}
                style={{ overflow: 'hidden', borderRadius: 16 }}
                elevation={6}
                {...props}
            >
                <Grid container style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <Grid item xs={3} style={{ maxWidth: 'unset' }}>
                        <Typography component="h1" variant="h4" paragraph style={{ textAlign: 'center', fontSize: '3rem', marginBottom: 0, }}>
                            {value ?? 0}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} style={{ textAlign: 'center' }}>
                        {icon}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h3" variant="h6" paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default DashItem