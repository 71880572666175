import React, { useState, useEffect, useReducer, useContext } from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'

import api from '../../services/api'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import QuickMessageDialog from '../../components/QuickMessageDialog'
import ConfirmationModal from '../../components/ConfirmationModal'
import toastError from '../../errors/toastError'
import { Grid } from '@material-ui/core'
import { isArray } from 'lodash'
import { SocketContext } from '../../context/Socket/SocketContext'
import { AuthContext } from '../../context/Auth/AuthContext'
import useCan from 'hooks/useCan'
import { AddCircleOutline, Lock, LockOpen } from '@material-ui/icons'
import { Can } from 'components/Can'
import BackdropLoading from 'components/BackdropLoading'

const reducer = (state, action) => {
    if (action.type === 'LOAD_QUICKMESSAGES') {
        const quickmessages = action.payload
        const newQuickmessages = []

        if (isArray(quickmessages)) {
            quickmessages.forEach(quickmessage => {
                const quickmessageIndex = state.findIndex(
                    u => u.id === quickmessage.id,
                )
                if (quickmessageIndex !== -1) {
                    state[quickmessageIndex] = quickmessage
                } else {
                    newQuickmessages.push(quickmessage)
                }
            })
        }

        return [...state, ...newQuickmessages]
    }

    if (action.type === 'UPDATE_QUICKMESSAGES') {
        const quickmessage = action.payload
        const quickmessageIndex = state.findIndex(u => u.id === quickmessage.id)

        if (quickmessageIndex !== -1) {
            state[quickmessageIndex] = quickmessage
            return [...state]
        } else {
            return [quickmessage, ...state]
        }
    }

    if (action.type === 'DELETE_QUICKMESSAGE') {
        const quickmessageId = action.payload

        const quickmessageIndex = state.findIndex(u => u.id === quickmessageId)
        if (quickmessageIndex !== -1) {
            state.splice(quickmessageIndex, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },

    tableCell: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '16vw',
    },
}))

const QuickMessages = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [selectedQuickmessage, setSelectedQuickmessage] = useState(null)
    const [deletingQuickmessage, setDeletingQuickmessage] = useState(null)
    const [quickmessageModalOpen, setQuickMessageDialogOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [searchParam, setSearchParam] = useState('')
    const [quickMessages, dispatch] = useReducer(reducer, [])
    const { user } = useContext(AuthContext)

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [searchParam])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            fetchQuickMessages()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam, pageNumber])

    useEffect(() => {
        const companyId = user.companyId
        const socket = socketManager.GetSocket(companyId)

        socket.on(`company${companyId}-quickmessage`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_QUICKMESSAGES', payload: data.record })
            }
            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_QUICKMESSAGE', payload: +data.id })
            }
        })
        return () => {
            socket.disconnect()
        }
    }, [user, socketManager])

    const fetchQuickMessages = async () => {
        try {
            const { data } = await api.get('/quick-messages', {
                filters: {
                    smart: searchParam,
                },
                include: {
                    quickMessageDepartment: 'departmentId',
                },
                page: pageNumber,
            })

            dispatch({ type: 'LOAD_QUICKMESSAGES', payload: data.data })
            setHasMore(quickMessages.length < data.total)
            setLoading(false)
        } catch (err) {
            toastError(err)
        }
    }

    const handleOpenQuickMessageDialog = () => {
        setSelectedQuickmessage(null)
        setQuickMessageDialogOpen(true)
    }

    const handleCloseQuickMessageDialog = () => {
        setSelectedQuickmessage(null)
        setQuickMessageDialogOpen(false)
        //window.location.reload();
        fetchQuickMessages()
    }

    const handleSearch = event => {
        setSearchParam(event.target.value)
    }

    const handleEditQuickmessage = quickmessage => {
        setSelectedQuickmessage(quickmessage)
        setQuickMessageDialogOpen(true)
    }

    const handleDeleteQuickmessage = async quickmessageId => {
        setLoading(true)
        try {
            await api.delete(`/quick-messages/${quickmessageId}`)
            toast.success('Atalho removido com sucesso!')
        } catch (err) {
            toastError(err)
        }
        setLoading(false)
        setDeletingQuickmessage(null)
        setSearchParam('')
        setPageNumber(1)
        fetchQuickMessages()
        dispatch({ type: 'RESET' })
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    const iCanSee = quickMessage => {
        if (quickMessage.type === 'general') return true

        if (quickMessage.type === 'personal')
            return quickMessage.userId === user.id

        if (quickMessage.type === 'department') {
            const quickDepartments = quickMessage.quickMessageDepartment.map(
                d => d.departmentId,
            )
            const userDepartments = user.departments?.map(q => q.id)

            return quickDepartments.some(q => userDepartments.includes(q))
        }
    }

    if (
        !canOrReturn(
            [
                'quickMessage:page',
                'quickMessage:delete',
                'quickMessage:create',
                'quickMessage:update',
            ],
            '/tickets',
        )
    )
        return null

    return (
        <MainContainer>
            {loading && <BackdropLoading />}
            <ConfirmationModal
                title={
                    deletingQuickmessage &&
                    `Excluir ${deletingQuickmessage.shortcode}?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() =>
                    handleDeleteQuickmessage(deletingQuickmessage.id)
                }>
                Esta ação é irreversível! Deseja prosseguir?
            </ConfirmationModal>
            <QuickMessageDialog
                resetPagination={() => {
                    setPageNumber(1)
                    fetchQuickMessages()
                }}
                open={quickmessageModalOpen}
                onClose={handleCloseQuickMessageDialog}
                aria-labelledby="form-dialog-title"
                quickmessageId={selectedQuickmessage && selectedQuickmessage.id}
            />
            <MainHeader>
                <Grid container>
                    <Grid xs={12} sm={6} md={8} item>
                        <Title>Respostas Rápidas</Title>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item spacing={2} container>
                        <Grid xs={6} sm={6} item>
                            <TextField
                                fullWidth
                                placeholder={'Pesquisar...'}
                                type="search"
                                value={searchParam}
                                onChange={handleSearch}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                style={{ color: 'gray' }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Can perform="quickMessage:create">
                            <Grid xs={6} sm={6} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleOpenQuickMessageDialog}
                                    color="primary">
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        style={{ gap: 4 }}>
                                        <AddCircleOutline />
                                        Adicionar
                                    </Grid>
                                </Button>
                            </Grid>
                        </Can>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Atalho</TableCell>
                            <TableCell align="center">Mensagem</TableCell>
                            <Can perform="quickMessage:see:all">
                                <TableCell align="center">Posso usar</TableCell>
                            </Can>
                            <TableCell align="center">
                                Nome do Arquivo
                            </TableCell>
                            <TableCell align="center">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {quickMessages.map(quickmessage => (
                                <TableRow key={quickmessage.id}>
                                    <TableCell align="center">
                                        {quickmessage.shortcode}
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        className={classes.tableCell}>
                                        {quickmessage.message}
                                    </TableCell>

                                    <Can perform="quickMessage:see:all">
                                        <TableCell align="center">
                                            {iCanSee(quickmessage) ? (
                                                <LockOpen />
                                            ) : (
                                                <Lock />
                                            )}
                                        </TableCell>
                                    </Can>

                                    <TableCell align="center">
                                        {quickmessage.mediaPath ?? 'Sem anexo'}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Can perform="quickMessage:update">
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleEditQuickmessage(
                                                        quickmessage,
                                                    )
                                                }>
                                                <EditIcon />
                                            </IconButton>
                                        </Can>

                                        <Can perform="quickMessage:delete">
                                            <IconButton
                                                size="small"
                                                onClick={e => {
                                                    setConfirmModalOpen(true)
                                                    setDeletingQuickmessage(
                                                        quickmessage,
                                                    )
                                                }}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Can>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={5} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default QuickMessages
