import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import { FormHelperText } from '@material-ui/core'
import { contrastColor } from 'helpers/helpers'

const useStyles = makeStyles(theme => ({
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
}))

const DepartmentSelect = ({
    value,
    onChange,
    multiple = true,
    label = 'Setores',
    error,
    onFocus,
}) => {
    const classes = useStyles()
    const [departments, setDepartments] = useState([])

    useEffect(() => {
        fetchDepartments()
    }, [])

    const fetchDepartments = async () => {
        try {
            const { data } = await api.get('/department')
            setDepartments(data)
        } catch (err) {
            toastError(err)
        }
    }

    const handleChange = e => {
        onChange(e.target.value)
    }

    return (
        <FormControl
            fullWidth
            size="small"
            variant="outlined"
            onFocus={onFocus}
            error={Boolean(error)}>
            <InputLabel>{label}</InputLabel>
            <Select
                label={label}
                multiple={multiple}
                labelWidth={60}
                value={value}
                onChange={handleChange}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                renderValue={selected => {
                    return (
                        <div className={classes.chips}>
                            {selected?.length > 0 && multiple ? (
                                selected.map(id => {
                                    const department = departments.find(
                                        q => q.id === id,
                                    )
                                    return department ? (
                                        <Chip
                                            key={id}
                                            style={{
                                                backgroundColor:
                                                    department.color,
                                                color: contrastColor(
                                                    department?.color,
                                                ),
                                            }}
                                            variant="outlined"
                                            label={department.name}
                                            className={classes.chip}
                                        />
                                    ) : null
                                })
                            ) : (
                                <Chip
                                    key={selected}
                                    variant="outlined"
                                    style={{
                                        backgroundColor: departments.find(
                                            q => q.id === selected,
                                        )?.color,
                                        color: contrastColor(
                                            departments.find(
                                                q => q.id === selected,
                                            )?.color,
                                        ),
                                    }}
                                    label={
                                        departments.find(q => q.id === selected)
                                            ?.name
                                    }
                                    className={classes.chip}
                                />
                            )}
                        </div>
                    )
                }}>
                {!multiple && <MenuItem value={null}>Selecione...</MenuItem>}
                {departments.map(department => (
                    <MenuItem key={department.id} value={department.id}>
                        {department.name}
                    </MenuItem>
                ))}
            </Select>
            {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}

export default DepartmentSelect
