import { useCallback, useState } from 'react'
import { Field } from 'formik'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import { cloneDeep } from 'lodash'
import {
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    makeStyles,
    MenuItem,
    Popover,
    Select,
    Switch,
    Tab,
    TextField,
    Tooltip,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import axios from 'axios'
import { SendSharp } from '@material-ui/icons'
import FormikCustomFieldAutocomplete from 'components/CustomFieldAutocomplete/FormikCustomFieldAutocomplete'
import MessageVariablesPicker from 'components/MessageVariablesPicker'

const useStyles = makeStyles(theme => ({
    hr: {
        borderStyle: 'dashed',
        borderColor: '#b9b9b9',
    },

    responseBox: {
        padding: '5px',
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },

    mapButton: {
        marginRight: '10px',
        width: '30%',
    },
}))

const RenderFieldsRequest = ({ tab, onRemove, item, ...props }) => {
    const [anchorEl, setAnchorEl] = useState(null)

    const handleMenuOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handleMenuClose = () => {
        setAnchorEl(null)
    }

    return (
        <span style={{ display: 'flex' }}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Field
                        as={TextField}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name={`${tab}[${item}].key`}
                        label="Chave"
                        value={props.values[tab][item]?.key}
                        onChange={ev =>
                            props.setFieldValue(
                                `${tab}[${item}].key`,
                                ev.target.value,
                            )
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        as={TextField}
                        name={`${tab}[${item}].value`}
                        value={props.values[tab][item]?.value}
                        label="Valor"
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        onChange={ev =>
                            props.setFieldValue(
                                `${tab}[${item}].value`,
                                ev.target.value,
                            )
                        }
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    size="small"
                                    onClick={handleMenuOpen}>
                                    <CodeOutlinedIcon fontSize="small" />
                                </IconButton>
                            ),
                        }}
                    />
                    <Popover
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}>
                        <MessageVariablesPicker
                            onClick={value =>
                                props.setFieldValue(
                                    `${tab}[${item}].value`,
                                    props.values[tab][item]?.value + value,
                                )
                            }
                        />
                    </Popover>
                </Grid>
            </Grid>

            <IconButton onClick={onRemove}>
                <DeleteIcon />
            </IconButton>
        </span>
    )
}

const RenderRequestMap = ({ tab, onRemove, item, ...props }) => {
    return (
        <span style={{ display: 'flex' }}>
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Field
                        as={TextField}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name={`${tab}[${item}].key`}
                        label="Chave"
                        value={props.values[tab][item]?.key}
                        onChange={ev =>
                            props.setFieldValue(
                                `${tab}[${item}].key`,
                                ev.target.value,
                            )
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <Field
                        as={FormikCustomFieldAutocomplete}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                        name={`${tab}[${item}].value`}
                        value={props.values[tab][item]?.value}
                        onChange={ev =>
                            props.setFieldValue(
                                `${tab}[${item}].value`,
                                ev.target.value,
                            )
                        }
                    />
                </Grid>
            </Grid>

            <IconButton onClick={onRemove}>
                <DeleteIcon />
            </IconButton>
        </span>
    )
}

const HttpForm = props => {
    const [tabValue, setTabValue] = useState('header')
    const [response, setResponse] = useState({})
    const classes = useStyles()

    const addHeader = useCallback(() => {
        const newValues = cloneDeep(props.values)
        if (!newValues.headers?.length) newValues.headers = []
        newValues.headers.push({ key: '', value: '' })

        props.setValues(newValues)
    }, [props])

    const addBody = useCallback(() => {
        const newValues = cloneDeep(props.values)
        if (!newValues?.body?.length) newValues.body = []
        newValues.body.push({ key: '', value: '' })

        props.setValues(newValues)
    }, [props])

    const addRequestMap = useCallback(() => {
        const newValues = cloneDeep(props.values)
        if (!newValues?.requestMap?.length) newValues.requestMap = []
        newValues.requestMap.push({ key: '', value: '' })

        props.setValues(newValues)
    }, [props])

    const removeHeader = index => {
        const newValues = cloneDeep(props.values)
        newValues.headers.splice(index, 1)

        props.setValues(newValues)
    }

    const removeBody = index => {
        const newValues = cloneDeep(props.values)
        newValues.body.splice(index, 1)

        props.setValues(newValues)
    }

    const removeRequestMap = index => {
        const newValues = cloneDeep(props.values)
        newValues.requestMap.splice(index, 1)

        props.setValues(newValues)
    }

    const handleChangeTab = (event, tabValue) => {
        setTabValue(tabValue)
    }

    const clickSendRequest = async event => {
        try {
            const response = await axios({
                method: props.values.request.toLowerCase(),
                url: props.values.url,
                headers:
                    props.values.headers?.reduce((acc, curr) => {
                        acc[curr.key] = curr.value
                        return acc
                    }, {}) || {},
                data:
                    props.values.body?.reduce((acc, curr) => {
                        acc[curr.key] = curr.value
                        return acc
                    }, {}) || {},
                responseType: 'json',
            })

            setResponse(response.data)
        } catch (error) {
            setResponse(error)
        }
        setTabValue('response')
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>
            <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="request">Tipo</InputLabel>
                    <Field
                        as={Select}
                        id="request"
                        label="Tipo"
                        labelId="request-label"
                        name="request"
                        required
                        {...props.getFieldProps(`request`)}
                        error={props.touched?.request && props.errors?.request}
                        helperText={
                            props.touched?.request && props.errors?.request
                        }>
                        <MenuItem value="get">GET</MenuItem>
                        <MenuItem value="post">POST</MenuItem>
                        <MenuItem value="put">PUT</MenuItem>
                        <MenuItem value="delete">DELETE</MenuItem>
                    </Field>
                </FormControl>
            </Grid>
            <Grid item xs={9}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="url"
                    label="URL"
                    {...props.getFieldProps(`url`)}
                    error={props.touched?.url && props.errors?.url}
                    helperText={props.touched?.url && props.errors?.url}
                />
            </Grid>
            <Grid item xs={1}>
                <IconButton onClick={clickSendRequest}>
                    <SendSharp />
                </IconButton>
            </Grid>

            <Grid item xs={12}>
                <hr className={classes.hr} />
            </Grid>

            <Grid item xs={12}>
                {}
                <TabContext value={tabValue}>
                    <TabList onChange={handleChangeTab} centered>
                        <Tab label="Header" value="header" />
                        <Tab label="URL Query" value="body" />
                        <Tab label="Resposta" value="response" />
                        <Tab
                            label="Mapeamento da Resposta"
                            value="response_map"
                        />
                    </TabList>
                    <TabPanel value="header">
                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    className={classes.mapButton}
                                    size="small"
                                    onClick={addHeader}
                                    variant="outlined">
                                    Adicionar Header
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {props.values.headers?.map((_, index) => {
                                    return (
                                        <RenderFieldsRequest
                                            tab="headers"
                                            item={index}
                                            key={index}
                                            {...props}
                                            onRemove={() => removeHeader(index)}
                                        />
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </TabPanel>
                    <TabPanel value="body">
                        <Grid item xs={12}>
                            <Button
                                className={classes.mapButton}
                                size="small"
                                onClick={addBody}
                                variant="outlined">
                                Adicionar Query
                            </Button>

                            <Tooltip
                                title="Se ativo, envia os dados do Atendimento e do Contato em conjunto com a requisição"
                                placement="top"
                                arrow>
                                <FormControlLabel
                                    label="Enviar Ticket e Contato"
                                    control={
                                        <Switch
                                            {...props.getFieldProps(
                                                `useTicketData`,
                                            )}
                                            checked={props.values.useTicketData}
                                            name="useTicketData"
                                            color="primary"
                                        />
                                    }
                                />
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            {props.values.body?.map((_, index) => {
                                return (
                                    <RenderFieldsRequest
                                        tab="body"
                                        item={index}
                                        key={index}
                                        {...props}
                                        onRemove={() => removeBody(index)}
                                    />
                                )
                            })}
                        </Grid>
                    </TabPanel>
                    <TabPanel value="response">
                        <Box className={classes.responseBox}>
                            {JSON.stringify(response, null, 2)}
                        </Box>
                    </TabPanel>
                    <TabPanel value="response_map">
                        <Grid item xs={12}>
                            <Button
                                className={classes.mapButton}
                                size="small"
                                onClick={addRequestMap}
                                variant="outlined">
                                Adicionar Mapeamento
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {props.values.requestMap?.map((_, index) => {
                                return (
                                    <RenderRequestMap
                                        tab="requestMap"
                                        onRemove={removeRequestMap}
                                        item={index}
                                        key={index}
                                        {...props}
                                    />
                                )
                            })}
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Grid>

            <Grid item xs={12}>
                <hr className={classes.hr} />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                />
            </Grid>
        </Grid>
    )
}

export default HttpForm
