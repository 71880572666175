import { useRef } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import styles from '../../general.module.scss'
import CustomNodeBase from '../../components/CustomNodeBase'
import ListIcon from '@material-ui/icons/List'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
    menuItem: {
        position: 'relative',
        width: '100%',
        fontSize: '0.5rem',
        paddingInline: 2,

        '&:nth-child(2n)': {
            backgroundColor: theme.palette.apre.semilight,
        },
    },

    menuItemError: {
        marginTop: 5,
        backgroundColor: theme.palette.error.semilight,
    },
}))

function MenuNode(myNode) {
    const { data, ...props } = myNode
    const classes = useStyles()
    const refCustomNode = useRef(null)

    const outsides = data?.items?.map((item, index) => {
        return (
            <div className={classes.menuItem} key={item.id}>
                <div>
                    {index + 1} - {item.message}
                </div>
                <CustomStartHandle
                    position={Position.Right}
                    id={item.id}
                    nodeId={props.id}
                />
            </div>
        )
    })

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Menu"
                icon={ListIcon}
                ref={refCustomNode}
                noContainer>
                <div className={styles.content}>
                    {data?.message || (
                        <Box onClick={() => refCustomNode.current?.open()}>
                            Clique para definir uma mensagem!
                        </Box>
                    )}
                </div>
                {data?.items?.length && outsides}

                <div className={clsx(classes.menuItem, classes.menuItemError)}>
                    <CustomStartHandle
                        position={Position.Right}
                        id={props.id + 'error'}
                        nodeId={props.id}
                    />
                    Errou mais de 3 vezes
                </div>
            </CustomNodeBase>
        </div>
    )
}

export default MenuNode
