import ApiTokens from "./ApiTokens"
import Iframe from "./Iframe"

const Integrations = () => {

    return (
        <>
            <ApiTokens />

            <br />
            <hr />
            <br />

            <Iframe />
        </>
    )
}

export default Integrations
