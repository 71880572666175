import {
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@material-ui/core'
import {
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Search,
    Visibility,
} from '@material-ui/icons'
import BackdropLoading from 'components/BackdropLoading'
import MainContainer from 'components/MainContainer'
import MainHeader from 'components/MainHeader'
import Title from 'components/Title'
import UserSelect from 'components/UserSelect'
import { format, parseISO } from 'date-fns'
import { useFilters } from 'hooks/useFilters'
import { useCallback, useEffect, useRef, useState } from 'react'
import api from 'services/api'
import ptBr from 'translations/pt-br'

const EVENTS = {
    updated: 'Alteração',
    created: 'Criação',
    deleted: 'Exclusão',
}

const MODELS = [
    'Ticket',
    'Contact',
    'QuickMessage',
    'Transmission',
    'Setting',
    'Ticket',
    'Media',
    'CustomField',
    'Department',
    'Permission',
    'Role',
    'User',
    'Tag',
]

const ActivityLog = () => {
    const {filters, changeFilters} = useFilters('activityLog')
    const [loading, setLoading] = useState(false)
    const [activities, setActivities] = useState([])
    const [page, setPage] = useState(0)
    const [selectedLog, setSelectedLog] = useState(false)
    let reloadTimeout = useRef()
    const hasMore = activities?.length === 10

    const reload = useCallback(() => {
        clearTimeout(reloadTimeout.current)
        reloadTimeout.current = setTimeout(() => {
            setLoading(true)
            api.get('activity', {
                filters,
                page: page + 1,
                perPage: 10,
                sort: '-createdAt',
                include: {
                    causer: 'id,name',
                },
            })
                .then(({ data }) => {
                    setActivities(data.data)
                })
                .finally(() => {
                    setLoading(false)
                })
        }, 200)
    }, [page, filters])

    useEffect(() => {
        reload()
    }, [reload, page])

    useEffect(() => {
        setPage(0)
    }, [filters])

    return (
        <MainContainer>
            {loading && <BackdropLoading />}

            <LogModal
                selectedLog={selectedLog}
                onClose={() => setSelectedLog(null)}
            />

            <MainHeader>
                <Title>Log de Atividades</Title>
            </MainHeader>

            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <TextField
                        fullWidth
                        placeholder={'Pesquisar...'}
                        type="search"
                        value={filters.smart ?? ''}
                        onChange={e => changeFilters('smart', e.target.value)}
                        variant="outlined"
                        size="small"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Search style={{ color: 'gray' }} />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel id="label-modelType">Módulo</InputLabel>

                        <Select
                            labelId="label-modelType"
                            label="Módulo"
                            value={filters.modelType ?? ''}
                            onChange={e =>
                                changeFilters('modelType', e.target.value)
                            }>
                            <MenuItem value={null}>Selecione...</MenuItem>

                            {MODELS.map(model => (
                                <MenuItem value={model} key={model}>
                                    {ptBr[model] ?? model}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={4}>
                    <UserSelect
                        value={filters.causerId ?? ''}
                        onChange={e => changeFilters('causerId', e)}
                    />
                </Grid>
            </Grid>

            <Divider style={{ width: '100%', margin: 'revert' }} />

            <Paper variant="outlined">
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Ver</TableCell>
                            <TableCell align="center">Data</TableCell>
                            <TableCell align="center">Usuário</TableCell>
                            <TableCell align="center">Evento</TableCell>
                            <TableCell align="center">Módulo</TableCell>
                            <TableCell align="center">ID Módulo</TableCell>
                            <TableCell align="center">Descrição</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {activities.map(log => (
                            <TableRow key={log.id}>
                                <TableCell align="center" width="5%">
                                    <IconButton
                                        size="small"
                                        onClick={() => setSelectedLog(log)}>
                                        <Visibility />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center" width="10%">
                                    {format(
                                        parseISO(log.createdAt),
                                        'dd/MM/yyyy HH:mm:ss',
                                    )}
                                </TableCell>
                                <TableCell align="center" width="10%">
                                    {log.causer?.id
                                        ? `${log.causer?.name} #${log.causer?.id}`
                                        : ''}
                                </TableCell>
                                <TableCell align="center" width="10%">
                                    {EVENTS[log.event]}
                                </TableCell>
                                <TableCell align="center" width="20%">
                                    {ptBr[log.modelType] ?? log.modelType}
                                </TableCell>
                                <TableCell align="center" width="5%">
                                    {log.modelId}
                                </TableCell>
                                <TableCell align="center" width="20%">
                                    {log.description}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            <TablePaginationActions
                page={page}
                onPageChange={setPage}
                hasMore={hasMore}
            />
        </MainContainer>
    )
}

function TablePaginationActions({ page, onPageChange, hasMore }) {
    const handleBackButtonClick = () => {
        onPageChange(page - 1)
    }

    const handleNextButtonClick = () => {
        onPageChange(page + 1)
    }

    return (
        <div>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                <KeyboardArrowLeft />
            </IconButton>
            <IconButton onClick={handleNextButtonClick} disabled={!hasMore}>
                <KeyboardArrowRight />
            </IconButton>
        </div>
    )
}

const LogModal = ({ selectedLog, onClose }) => {
    return (
        <Dialog open={Boolean(selectedLog)} fullWidth maxWidth="md" onClose={onClose}>
            <DialogTitle>
                {ptBr[selectedLog?.modelType] ?? selectedLog?.modelType}
                {' - '}
                {selectedLog?.modelId}
            </DialogTitle>
            <DialogContent>
                <div style={{ display: 'flex', gap: 10 }}>
                    {Boolean(selectedLog?.properties?.previous) && (
                        <div style={{ wordBreak: 'break-word', flex: '1 1 50%' }}>
                            <Typography variant="h5">Antes:</Typography>
                            <div>
                                {Object.entries(
                                    selectedLog?.properties?.previous,
                                ).map(([key, value]) => (
                                    <div key={key}>
                                        <b>{ptBr[key] ?? key}</b>:{' '}
                                        {JSON.stringify(value)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    {Boolean(selectedLog?.properties?.new) && (
                        <div style={{ wordBreak: 'break-word', flex: '1 1 50%' }}>
                            <Typography variant="h5">Depois:</Typography>
                            <div>
                                {Object.entries(
                                    selectedLog?.properties?.new,
                                ).map(([key, value]) => (
                                    <div key={key}>
                                        <b>{ptBr[key] ?? key}</b>:{' '}
                                        {JSON.stringify(value)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ActivityLog
