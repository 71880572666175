import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Drawer from "@material-ui/core/Drawer";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import ContactDrawerSkeleton from "../ContactDrawerSkeleton";
import MarkdownWrapper from "../MarkdownWrapper";
import { CardHeader } from "@material-ui/core";
import ContactModal from "../ContactModal";
import { Can } from "components/Can";

const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		display: "flex",
		borderTop: "1px solid rgba(0, 0, 0, 0.12)",
		borderRight: "1px solid rgba(0, 0, 0, 0.12)",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		borderRadius: "4px",
		// borderTopRightRadius: 4,
		// borderBottomRightRadius: 4,
	},
	header: {
		display: "flex",
		borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
		backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
		alignItems: "center",
		padding: theme.spacing(0, 1),
		minHeight: "73px",
		justifyContent: "flex-start",
	},
	content: {
		display: "flex",
		backgroundColor: theme.palette.contactdrawer, //DARK MODE PLW DESIGN//
		flexDirection: "column",
		padding: "8px 0px 8px 8px",
		height: "100%",
		overflowY: "scroll",
		...theme.scrollbarStyles,
	},

	contactAvatar: {
		margin: 15,
		width: 100,
		height: 100,
	},

	contactHeader: {
		display: "flex",
		padding: 8,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		"& > *": {
			margin: 4,
		},
	},

	contactDetails: {
		marginTop: 8,
		padding: 8,
		display: "flex",
		flexDirection: "column",
	},
	contactExtraInfo: {
		marginTop: 4,
		padding: 6,
	},
}));

const ContactDrawer = ({ open, handleDrawerClose, contact, ticket, loading }) => {
	const classes = useStyles();

	const [modalOpen, setModalOpen] = useState(false);
	const [openForm, setOpenForm] = useState(false);

	useEffect(() => {
		setOpenForm(false);
	}, [open, contact]);

	return (
        <>
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="right"
                open={open}
                PaperProps={{ style: { position: 'absolute' } }}
                BackdropProps={{ style: { position: 'absolute' } }}
                ModalProps={{
                    container: document.getElementById('drawer-container'),
                    style: { position: 'absolute' },
                }}
                classes={{
                    paper: classes.drawerPaper,
                }}>
                <ContactModal
                    open={modalOpen}
                    onClose={() => setModalOpen(false)}
                    contactId={contact.id}></ContactModal>
                <div className={classes.header}>
                    <IconButton onClick={handleDrawerClose}>
                        <CloseIcon />
                    </IconButton>
                    <Typography style={{ justifySelf: 'center' }}>
                        Dados do contato
                    </Typography>
                </div>
                {loading ? (
                    <ContactDrawerSkeleton classes={classes} />
                ) : (
                    <div className={classes.content}>
                        <Paper
                            square
                            variant="outlined"
                            className={classes.contactHeader}>
                            <CardHeader
                                onClick={() => {}}
                                style={{ width: '100%' }}
                                titleTypographyProps={{ noWrap: true }}
                                subheaderTypographyProps={{ noWrap: true }}
                                avatar={
                                    <Avatar
                                        src={contact.profilePicUrl}
                                        alt="contact_image"
                                        style={{ width: 60, height: 60 }}
                                    />
                                }
                                title={
                                    <Typography noWrap={false}>{contact.name}</Typography>
                                }
                                subheader={
                                    <>
                                        <Typography style={{ fontSize: 12 }}>
                                            <Link
                                                href={`tel:${contact.number}`}>
                                                {contact.number}
                                            </Link>
                                        </Typography>
                                        <Typography style={{ fontSize: 12 }}>
                                            <Link
                                                href={`mailto:${contact.email}`}>
                                                {contact.email}
                                            </Link>
                                        </Typography>
                                    </>
                                }
                            />
                            <Can perform="contact:update">
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => setModalOpen(!openForm)}
                                    style={{ fontSize: 12 }}>
                                    Editar contato
                                </Button>
                            </Can>
                        </Paper>
                        <Paper
                            square
                            variant="outlined"
                            className={classes.contactDetails}>
                            <Typography variant="subtitle1">
                                Outras informações
                            </Typography>
                            {contact?.extraInfo?.map(info => (
                                <Paper
                                    key={info.id}
                                    square
                                    variant="outlined"
                                    className={classes.contactExtraInfo}>
                                    <InputLabel>
                                        {info.customField?.name}
                                    </InputLabel>
                                    <Typography
                                        component="div"
                                        noWrap
                                        style={{ paddingTop: 2 }}>
                                        <MarkdownWrapper>
                                            {info.value}
                                        </MarkdownWrapper>
                                    </Typography>
                                </Paper>
                            ))}
                        </Paper>
                    </div>
                )}
            </Drawer>
        </>
    )
};

export default ContactDrawer;
