import api from '../../services/api'

const useDashboard = () => {
    const findGeneralDashboard = async params => {
        const { data } = await api.request({
            url: `/dashboard/general`,
            method: 'GET',
            params,
        })
        return data
    }

    const findTicketsDashboard = async params => {
        const { data } = await api.request({
            url: `/dashboard/tickets`,
            method: 'GET',
            params,
        })
        return data
    }

    const findMetricsDashboard = async params => {
        const { data } = await api.request({
            url: `/dashboard/metrics`,
            method: 'GET',
            params,
        })
        return data
    }

    return {
        findGeneralDashboard,
        findTicketsDashboard,
        findMetricsDashboard,
    }
}

export default useDashboard
