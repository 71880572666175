import { toast } from 'react-toastify'
import { isString } from 'lodash'

const toastError = err => {
    const errorMsg = err.response?.data?.error
    if (errorMsg) {
        toast.error(
            <span
                dangerouslySetInnerHTML={{
                    __html: errorMsg,
                }}
            />,
            {
                toastId: errorMsg,
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light',
            },
        )
        return
    }
    if (isString(err)) {
        toast.error(err)
        return
    } else {
        console.error(err)
        toast.error('Ocorreu um erro inesperado!. Erro: ' + err.message)
        return
    }
}

export default toastError
