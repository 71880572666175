import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { useHistory } from 'react-router-dom'
import useStyles from './Style'
import useCan from 'hooks/useCan'
import clsx from 'clsx'

const DashTextItem = ({ xs = 12, sm = 6, md = 4, lg = 2, name, label, value, color, reverse, permission, page, ...props }) => {
    const classes = useStyles()
    const history = useHistory()
    const { can } = useCan()

    const hasPermission = !page || !permission || can(permission)

    return (
        <Grid item xs={xs} sm={sm} md={md} lg={lg} onClick={() => page && hasPermission && history.push(`/${page}`)}>
            <Paper
                className={clsx([classes.card, hasPermission ? '' : classes.cannt])}
                style={{ overflow: 'hidden', borderRadius: 16 }}
                elevation={6}
                {...props}
            >
                <Grid container style={{ justifyContent: 'center', alignItems: 'center', flexDirection: reverse ? 'column-reverse' : 'column' }}>
                    <Grid item style={{ maxWidth: 'unset' }}>
                        <Typography component="h1" variant="h4" paragraph style={{ textAlign: 'center', fontSize: '2rem', marginBottom: 0, color }}>
                            {value}
                        </Typography>
                        <Typography component="h4" variant="subtitle1" paragraph style={{ textAlign: 'center', marginBottom: 0, color }}>
                            {label}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h3" variant="h6" paragraph style={{ textAlign: 'center', marginBottom: 0 }}>
                            {name}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    )
}

export default DashTextItem