import React from 'react'
import { useHistory } from 'react-router-dom'

import DashItem from '../components/DashItem'

import CallIcon from '@material-ui/icons/Call'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MessageIcon from '@material-ui/icons/Message'
import AndroidIcon from '@material-ui/icons/Android';
import { ContactPhoneOutlined } from '@material-ui/icons'

const InformationCards = ({ dashboardData, companyData }) => {
    const history = useHistory()

    const totalTickets = Object.values(dashboardData).reduce((total, current) => current.total + total, 0)
    
    return (
        <>
            <DashItem
                title="No Bot"
                value={dashboardData?.bot?.total}
                onClick={() => history.push('/tickets/?tab=open&status=bot')}
                icon={
                    <AndroidIcon
                        style={{
                            fontSize: 48,
                            color: '#FFFFFF',
                            opacity: 0.5
                        }}
                    />
                }
            />

            <DashItem
                title="Aguardando"
                value={dashboardData?.pending?.total}
                onClick={() => history.push('/tickets/?tab=open&status=pending')}
                icon={
                    <CallIcon
                        style={{
                            fontSize: 48,
                            color: '#FFFFFF',
                            opacity: 0.5
                        }}
                    />
                }
            />

            <DashItem
                title="Em Atendimento"
                value={dashboardData?.open?.total}
                onClick={() => history.push('/tickets/?tab=open&status=open')}
                icon={
                    <HourglassEmptyIcon
                        style={{
                            fontSize: 48,
                            color: '#FFFFFF',
                            opacity: 0.5
                        }}
                    />
                }
            />

            <DashItem
                title="Finalizados"
                value={dashboardData?.closed?.total}
                onClick={() => history.push('/tickets/?tab=closed')}
                icon={
                    <CheckCircleIcon
                        style={{
                            fontSize: 48,
                            color: '#FFFFFF',
                            opacity: 0.5
                        }}
                    />
                }
            />

            <DashItem
                title="Atendimentos"
                value={totalTickets}
                onClick={() => history.push('/tickets/')}
                icon={
                    <MessageIcon
                        style={{
                            fontSize: 48,
                            color: '#FFFFFF',
                            opacity: 0.5
                        }}
                    />
                }
            />

            <DashItem
                title="Novos Contatos"
                page="contacts"
                permission="contact:page"
                value={companyData?.newContacts}
                icon={
                    <ContactPhoneOutlined
                        style={{
                            fontSize: 48,
                            color: '#FFFFFF',
                            opacity: 0.5
                        }}
                    />
                }
            />
        </>
    )
}

export default InformationCards
