import React from 'react'
import DashItem from '../components/DashItem'

import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined'
import SyncAltIcon from '@material-ui/icons/SyncAlt'
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LinearScaleIcon from '@material-ui/icons/LinearScale'
import { Grid } from '@material-ui/core'
// import Title from '../components/Title'

const InformationCompany = ({ companyData }) => {
    
    return (
        <>
            {/* <Title>Plano:</Title> */}
            <Grid container spacing={2} justifyContent="center">
                <DashItem
                    xs={null} sm={null} md={null} lg={null}
                    page="users"
                    title="Usuários"
                    permission="user:page"
                    value={companyData.users}
                    icon={
                        <PeopleAltOutlinedIcon
                            style={{
                                fontSize: 48,
                                color: '#FFFFFF',
                                opacity: 0.5
                            }}
                        />
                    }
                />

                <DashItem
                    xs={null} sm={null} md={null} lg={null}
                    page="departments"
                    permission="department:page"
                    title="Setores"
                    value={companyData.departments}
                    icon={
                        <AccountTreeOutlinedIcon
                            style={{
                                fontSize: 48,
                                color: '#FFFFFF',
                                opacity: 0.5
                            }}
                        />
                    }
                />

                <DashItem
                    xs={null} sm={null} md={null} lg={null}
                    page="connections"
                    permission="connection:page"
                    title="Conexões"
                    value={companyData.connections}
                    icon={
                        <SyncAltIcon
                            style={{
                                fontSize: 48,
                                color: '#FFFFFF',
                                opacity: 0.5
                            }}
                        />
                    }
                />

                <DashItem
                    xs={null} sm={null} md={null} lg={null}
                    page="tickets"
                    title="Mensagens"
                    value={companyData.messages}
                    icon={
                        <ForumOutlinedIcon
                            style={{
                                fontSize: 48,
                                color: '#FFFFFF',
                                opacity: 0.5
                            }}
                        />
                    }
                />

                <DashItem
                    xs={null} sm={null} md={null} lg={null}
                    permission="contact:page"
                    page="contacts"
                    title="Grupos"
                    value={companyData.groups}
                    icon={
                        <SupervisorAccountIcon
                            style={{
                                fontSize: 48,
                                color: '#FFFFFF',
                                opacity: 0.5
                            }}
                        />
                    }
                />

                <DashItem
                    xs={null} sm={null} md={null} lg={null}
                    page="tags"
                    permission="tag:page"
                    title="Tags"
                    value={companyData.tags}
                    icon={
                        <LocalOfferIcon
                            style={{
                                fontSize: 48,
                                color: '#FFFFFF',
                                opacity: 0.5
                            }}
                        />
                    }
                />

                <DashItem
                    xs={null} sm={null} md={null} lg={null}
                    page="chat-flows"
                    permission="chatFlow:page"
                    title="Bots"
                    value={companyData.chatFlows}
                    icon={
                        <LinearScaleIcon
                            style={{
                                fontSize: 48,
                                color: '#FFFFFF',
                                opacity: 0.5
                            }}
                        />
                    }
                />
            </Grid>
        </>
    )
}

export default InformationCompany
