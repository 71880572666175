import api from '../../services/api'

const useQuickMessages = () => {
    const save = async data => {
        const { data: responseData } = api.post(`/quick-messages`, data)
        return responseData
    }

    const update = async data => {
        const { data: responseData } = await api.put(
            `/quick-messages/${data.id}`,
            data,
        )
        return responseData
    }

    const deleteRecord = async id => {
        const { data } = await api.delete(`/quick-messages/${id}`)
        return data
    }

    const list = async params => {
        const { data } = await api.get('/quick-messages/', {
            filters: params,
            include: {
                file: '*, @url'
            }
        })
        return data.data
    }

    return {
        save,
        update,
        deleteRecord,
        list,
    }
}

export default useQuickMessages
