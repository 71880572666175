import {
    Message,
    List,
    Timer,
    RateReview,
    Group,
    Person,
    LocalOffer,
    NotificationsActive,
    CallSplit,
    Done,
    AssignmentInd,
    MonetizationOn,
    Wifi,
    EmojiPeople,
} from '@material-ui/icons'
import Apresentame from 'icons/Apresentame'

export const types = [
    {
        type: 'message',
        label: 'Mensagem',
        icon: <Message />,
    },
    { type: 'menu', label: 'Menu', icon: <List /> },
    { type: 'sleep', label: 'Espera', icon: <Timer /> },
    {
        type: 'ask',
        label: 'Pergunta',
        icon: <RateReview />,
    },
    { type: 'department', label: 'Setor', icon: <Group /> },
    { type: 'user', label: 'Atendente', icon: <Person /> },
    { type: 'tag', label: 'Tag', icon: <LocalOffer /> },
    {
        type: 'notify',
        label: 'Notificar',
        icon: <NotificationsActive />,
    },
    {
        type: 'condition',
        label: 'Condição',
        icon: <CallSplit style={{ transform: 'rotate(180deg)' }} />,
    },
    {
        type: 'httpRequest',
        label: 'Requisição HTTP',
        icon: <Wifi />,
    },
    {
        type: 'connectionTransfer',
        label: 'Transferir Conexão',
        icon: <Done />,
    },
    {
        type: 'finish',
        label: 'Finalizar Bot',
        icon: <Done />,
    },
    {
        type: 'findPerson',
        label: 'Pessoa no Sistema',
        icon: <AssignmentInd />,
        subType: 'apresentame',
    },
    {
        type: 'sendInvoice',
        label: 'Enviar Boleto',
        icon: <MonetizationOn />,
        subType: 'apresentame',
    },
    {
        type: 'createLead',
        label: 'Criar Lead',
        icon: <EmojiPeople />,
        subType: 'apresentame',
    },
]

export const subTypes = [
    {
        type: 'apresentame',
        label: 'Apresenta.me',
        icon: <Apresentame style={{ width: '1.25rem', height: '1.25rem' }} />,
    },
]
