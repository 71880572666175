import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { toast } from 'react-toastify'
import BackdropLoading from 'components/BackdropLoading'
import { SettingContext } from 'pages/SettingsCustom/SettingsContext'

export default function Options({ ...props }) {
    const { settings, setSetting } = useContext(SettingContext)

    if (!Object.keys(settings)?.length) return <BackdropLoading />

    async function handleSettingChange(setting, value) {
        await setSetting(setting, value)
        toast.success('Operação atualizada com sucesso.')
    }

    return (
        <>
            <Grid spacing={3} container>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl fullWidth>
                        <InputLabel id="group-type-label">
                            Ignorar Mensagens de Grupos
                        </InputLabel>
                        <Select
                            labelId="group-type-label"
                            value={settings.ignoreGroup}
                            onChange={async e => {
                                handleSettingChange(
                                    'ignoreGroup',
                                    e.target.value,
                                )
                            }}>
                            <MenuItem value={false}>Desativado</MenuItem>
                            <MenuItem value={true}>Ativado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl fullWidth>
                        <InputLabel id="call-type-label">
                            Aceitar Chamada
                        </InputLabel>
                        <Select
                            labelId="call-type-label"
                            value={settings.call}
                            onChange={async e => {
                                handleSettingChange('call', e.target.value)
                            }}>
                            <MenuItem value={false}>Não Aceitar</MenuItem>
                            <MenuItem value={true}>Aceitar</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}
