import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import toastError from '../../errors/toastError'
import api from '../../services/api'
import useMediaUpload from '../../hooks/useMediaUpload'
import { toast } from 'react-toastify'
import { Alert } from '@material-ui/lab'

const ChatFlowImport = ({ open, onClose, onChange, ...props }) => {
    const { uploadFile } = useMediaUpload()
    const [flow, setFlow] = useState({})
    const [flowName, setFlowName] = useState('')
    const attachmentFile = useRef(null)

    const handleClose = () => {
        setFlow({})
        setFlowName('')
        onClose()
    }

    const handleAttachmentFile = async e => {
        const file = e.target.files[0]
        if (!file) return

        if (!['application/json', 'application/JSON'].includes(file.type))
            return toastError('O arquivo enviado é inválido! Envie um arquivo JSON')

        const uploadedFile = await uploadFile(file)
        setFlow(flow => ({...flow, file: uploadedFile}))
    }

    const handleSubmit = async (value) => {     
        if (!value.file)
            return toastError('Não foi informado um arquivo para importar')

        try {
            const importedFlow = (await api.post(`/chat-flow/upload`, value))?.data
            toast.success('ChatBot importado com sucesso!')
            onChange(importedFlow)
            handleClose()
        } catch (e) {
            toastError('Erro ao importar ChatBot. Erro: ' + e.message)
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" scroll="paper">
            <DialogTitle id="form-dialog-title">Importando ChatBot</DialogTitle>
            <Formik
                initialValues={{...flow, flowName}}
                enableReinitialize={true}
                onSubmit={handleSubmit}>
                {({ values, errors, isSubmitting }) => (
                    <Form>
                        <DialogContent dividers>
                            <Grid container spacing={1}> 
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        Os Campos Customizados e Tags faltantes serão
                                        cadastrados automaticamente, Setores e Usuários
                                        serão limpos!
                                    </Alert>
                                </Grid>

                                <Grid item xs={12}>
                                    <input
                                        style={{ display: 'none' }}
                                        type="file"
                                        ref={attachmentFile}
                                        accept="application/JSON"
                                        onChange={e => handleAttachmentFile(e)}
                                    />

                                    <Button
                                        color="primary"
                                        onClick={() => attachmentFile.current.click()}
                                        variant="outlined">
                                        Selecionar arquivo
                                    </Button>

                                    {flow.file?.originalname && (
                                        <Button startIcon={<AttachFileIcon />}>
                                            {flow.file?.originalname}
                                        </Button>
                                    )}
                                </Grid>

                                <Grid item xs={6}>
                                    {flow.file?.originalname && (
                                        <TextField
                                            name='name'
                                            size='small'
                                            variant="outlined"
                                            label="Nome do ChatBot"
                                            fullWidth
                                            onChange={e => setFlowName(e.target.value)}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={handleClose}
                                color="secondary"
                                disabled={isSubmitting}
                                variant="outlined">
                                Cancelar
                            </Button>
                            <Button
                                type="submit"
                                color="primary"
                                disabled={isSubmitting || !flow.file?.blob}
                                variant="contained">
                                Importar
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default ChatFlowImport
