import React, { useState, useEffect, useRef } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import ColorPicker from '../ColorPicker'
import {
    FormControlLabel,
    IconButton,
    InputAdornment,
    Paper,
    Switch,
} from '@material-ui/core'
import { Colorize } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}))

const DepartmentSchema = Validator.object().shape({
    name: Validator.string().min(2).max(50).required(),
    color: Validator.string().min(3).max(9).required(),
})

const initialState = {
    name: '',
    color: '',
    confidentialHistory: false,
}

const DepartmentModal = ({ open, onClose, departmentId }) => {
    const classes = useStyles()

    const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false)
    const [department, setDepartment] = useState(initialState)
    const nameRef = useRef()

    useEffect(() => {
        ;(async () => {
            if (!departmentId) return
            try {
                const { data } = await api.get(`/department/${departmentId}`)
                setDepartment(prevState => ({ ...prevState, ...data }))
            } catch (err) {
                toastError(err)
            }
        })()

        return () => {
            setDepartment(initialState)
        }
    }, [departmentId, open])

    const handleClose = () => {
        onClose()
        setDepartment(initialState)
    }

    const handleSaveDepartment = async values => {
        try {
            if (departmentId) {
                await api.put(`/department/${departmentId}`, values)
            } else {
                await api.post('/department', values)
            }
            toast.success('Setor salvo com sucesso!')
            handleClose()
        } catch (err) {
            toastError(err)
        }
    }

    return (
        <div className={classes.root}>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                open={open}
                onClose={handleClose}
                scroll="paper">
                <DialogTitle>
                    {departmentId ? 'Editar Setor' : 'Adicionar Setor'}
                </DialogTitle>
                <Paper>
                    <Formik
                        initialValues={department}
                        enableReinitialize={true}
                        validationSchema={DepartmentSchema}
                        onSubmit={(values, actions) => {
                            setTimeout(() => {
                                handleSaveDepartment(values)
                                actions.setSubmitting(false)
                            }, 400)
                        }}>
                        {({ touched, errors, isSubmitting, values }) => (
                            <Form>
                                <DialogContent dividers>
                                    <Field
                                        as={TextField}
                                        label="Nome"
                                        autoFocus
                                        name="name"
                                        error={
                                            touched.name && Boolean(errors.name)
                                        }
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                        inputRef={nameRef}
                                    />
                                    <Field
                                        as={TextField}
                                        label="Cor"
                                        name="color"
                                        id="color"
                                        onFocus={() => {
                                            setColorPickerModalOpen(true)
                                            nameRef.current.focus()
                                        }}
                                        error={
                                            touched.color &&
                                            Boolean(errors.color)
                                        }
                                        helperText={
                                            touched.color && errors.color
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <div
                                                        style={{
                                                            backgroundColor:
                                                                values.color,
                                                        }}
                                                        className={
                                                            classes.colorAdorment
                                                        }></div>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <IconButton
                                                    size="small"
                                                    color="default"
                                                    onClick={() =>
                                                        setColorPickerModalOpen(
                                                            true,
                                                        )
                                                    }>
                                                    <Colorize />
                                                </IconButton>
                                            ),
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                        className={classes.textField}
                                    />
                                    <ColorPicker
                                        open={colorPickerModalOpen}
                                        handleClose={() =>
                                            setColorPickerModalOpen(false)
                                        }
                                        onChange={color => {
                                            values.color = color
                                            setDepartment(() => {
                                                return { ...values, color }
                                            })
                                        }}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Field
                                                as={Switch}
                                                color="primary"
                                                name="confidentialHistory"
                                                checked={
                                                    values.confidentialHistory
                                                }
                                            />
                                        }
                                        label="Histórico de mensagens confidencial"
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="secondary"
                                        disabled={isSubmitting}
                                        variant="outlined">
                                        Cancelar
                                    </Button>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}>
                                        {departmentId ? `Salvar` : `Adicionar`}
                                        {isSubmitting && (
                                            <CircularProgress
                                                size={24}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </Paper>
            </Dialog>
        </div>
    )
}

export default DepartmentModal
