import { CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import { Code, GetApp, InsertDriveFile, PictureAsPdf } from '@material-ui/icons'
import LocationPreview from 'components/LocationPreview'
import ModalImageCors from 'components/ModalImageCors'
import VcardPreview from 'components/VcardPreview'
import GroupInviteCard from 'components/GroupInviteCard'
import { Document, Page, pdfjs } from 'react-pdf'
import Spreadsheet from 'icons/Spreadsheet'
import GoogleDoc from 'icons/GoogleDoc'
import { useState } from 'react'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`

const useStyles = makeStyles(theme => ({
    messageMedia: {
        objectFit: 'contain',
        width: 250,
        maxHeight: 200,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 8,
    },

    downloadMedia: {
        maxWidth: 350,
        minWidth: 250,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    fileThumb: {
        maxHeight: 100,
        overflow: 'hidden',
    },

    fileDescriptor: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        padding: 5,
        gap: 5,
    },

    fileDescriptorIcon: {
        padding: 5,
    },
    fileDescriptorData: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        fontSize: 15,
        textOverflow: 'ellipsis',

        '& > span': {
            fontSize: '0.8em',
        },
    },
    fileDescriptorDownload: {
        padding: 5,
    },
}))

function humanFileSize(size) {
    var i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024))
    return (
        +(size / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
    )
}

const FileIcon = ({ mime }) => {
    if (mime === 'pdf') return <PictureAsPdf />

    if (['csv'].includes(mime)) return <Spreadsheet />

    if (['text', 'msword', 'plain'].includes(mime)) return <GoogleDoc />

    if (['json', 'sql', 'xml'].includes(mime)) return <Code />

    return <InsertDriveFile />
}

const RenderMessageMedia = ({ message }) => {
    const classes = useStyles()

    const [downloading, setDownloading] = useState(false)

    const handleDownload = async () => {
        if (downloading || !message.mediaUrl)
            return

        setDownloading(true)
        let linkHref = message.mediaUrl
        try {
            const resposta = await fetch(message.mediaUrl);
            const blob = await resposta.blob();
            linkHref = URL.createObjectURL(blob);
        } catch (erro) {
            linkHref = message.mediaUrl
        }

        const link = document.createElement("a");
        link.download = `${message.file?.name}.${message.file?.extension}`;
        link.target = '_blank'
        link.href = linkHref
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloading(false)
    }

    if (
        ['locationMessage', 'liveLocationMessage'].includes(
            message.messageType,
        ) &&
        message.body.split('|').length >= 2
    ) {
        let locationParts = message.body.split('|')
        let imageLocation = locationParts[0]
        let linkLocation = locationParts[1]

        let descriptionLocation = ''

        if (locationParts.length > 2)
            descriptionLocation = message.body.split('|')[2]

        return (
            <LocationPreview
                image={imageLocation}
                link={linkLocation}
                description={descriptionLocation}
            />
        )
    }

    if (
        ['contactMessage', 'contactsArrayMessage'].includes(message.messageType)
    )
        return <VcardPreview vcard={message.body} />

    if (message.messageType === 'groupInviteMessage')
        return <GroupInviteCard message={message} />

    if (!message.mediaUrl) {
        return (
            <div>
                <i>
                    Não foi possível buscar o arquivo, visualize pelo celular.
                </i>
            </div>
        )
    }

    if (message.messageType === 'image') {
        const isSticker = (
            message.dataJson && message.dataJson instanceof Object
                ? message.dataJson
                : JSON.parse(message.dataJson ?? '{}')
        )?.message?.stickerMessage
        return (
            <ModalImageCors
                imageUrl={
                    message.isDeleted
                        ? message.mediaUrl
                        : message.mediaUrl.split('?').at(0)
                }
                isSticker={isSticker}
                hideDownload={message.isDeleted}
            />
        )
    }

    if (message.messageType === 'audio')
        return (
            <audio controls>
                <source src={message.mediaUrl} type="audio/ogg"></source>
            </audio>
        )

    if (message.messageType === 'video')
        return (
            <video
                className={classes.messageMedia}
                src={message.mediaUrl}
                controls
            />
        )

    const mediaType = message.file?.mimeType?.split('/')[1]
    return (
        <>
            <div className={classes.downloadMedia}>
                <div className={classes.fileThumb}>
                    {mediaType === 'pdf' && (
                        <Document
                            loading="Carregando..."
                            noData="" // Vazio para não apresentar nada
                            error="" // Vazio para não apresentar nada
                            file={message.mediaUrl}>
                            <Page
                                width="300"
                                pageNumber={1}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </Document>
                    )}
                </div>

                <div className={classes.fileDescriptor}>
                    <div className={classes.fileDescriptorIcon}>
                        <FileIcon mime={mediaType} />
                    </div>
                    <div className={classes.fileDescriptorData}>
                        {`${message.file?.name}.${message.file?.extension}`}
                        <span>
                            {humanFileSize(message.file?.size)} •{' '}
                            {message.file?.extension?.toUpperCase()}
                        </span>
                    </div>
                    <IconButton
                        className={classes.fileDescriptorDownload}
                        onClick={handleDownload}
                        size="small"
                    >
                        {downloading ? <CircularProgress size={24} /> : <GetApp />}
                    </IconButton>
                </div>
            </div>
        </>
    )
}

export default RenderMessageMedia
