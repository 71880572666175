import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { getUnixTime } from 'date-fns'
import ButtonWithSpinner from 'components/ButtonWithSpinner'
import ConnectionSelect from 'components/ConnectionSelect'
import toastError from 'errors/toastError'
import api from 'services/api'

const useStyles = makeStyles(theme => ({
    boxCard: {
        padding: '4px',
        marginBottom: '4px',
    },
    card: {
        padding: '4px',
        marginBottom: '4px',
        borderRadius: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    cardTitle: {
        fontWeight: 'bold', 
    },
    cardDescription: {
        fontSize: '12px'
    },
}))

const GoToGroup = ({ message, modalOpen, onClose }) => {
    const [selectedWhatsapp, setSelectedWhatsapp] = useState('')
    const history = useHistory()

    const handleAcceptInvite = async () => {
        api.post(`tickets/group`, {
            whatsappId: selectedWhatsapp,
            remoteId: message.remoteJid,
            messageUuid: message.uuid,
            group: message.dataJson.message.groupInviteMessage,
        }).then(r => {
            history.push(`/tickets/${r.data.uuid}`)
        }).catch(err => {
            toastError(err)
        })
    }

    return (
        <Dialog open={modalOpen} onClose={onClose}>
            <DialogTitle id="form-dialog-title">
                Criar Ticket
            </DialogTitle>
            <DialogContent dividers>
                <Grid style={{ width: 300 }} container spacing={2}>
                    <Grid xs={12} item>
                        <ConnectionSelect
                            value={selectedWhatsapp}
                            onChange={setSelectedWhatsapp}
                            label={'Selecione uma Conexões'}
                            defaultText={null}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancelar
                </Button>
                <ButtonWithSpinner
                    variant="contained"
                    type="button"
                    onClick={handleAcceptInvite}
                    color="primary"
                >
                    Salvar
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    )
}

const GroupCard = ({ message, handleGroup }) => {
    const classes = useStyles();

    let now = getUnixTime(new Date());
    let inviteExpiration = message.groupInviteMessage.inviteExpiration;
    let expiration = (now > inviteExpiration)

    return (
        <Box>
            <Box className={classes.card}>
                <Box>
                    <Typography className={classes.cardTitle}>
                        {message.groupInviteMessage.groupName}
                    </Typography>
                    <Typography className={classes.cardDescription}>
                        Convite para grupo do WhatsApp
                    </Typography>
                </Box>
            </Box>
            <Typography variant="body2" className={classes.boxCard}>
                {message.groupInviteMessage.caption}
            </Typography>
            <Divider/>
            <Button 
                disabled={expiration}
                fullWidth
                onClick={() => handleGroup(message.groupInviteMessage)}
            >
                {expiration ? 'O convite expirou' : 'Entrar no grupo'}
            </Button>
        </Box>
    )
}

const GroupInviteCard = ({ message }) => {
    const [opened, setOpened] = useState()

    return (
        <>
            <div style={{minWidth: '250px',}}>
                <GroupCard 
                    message={message.dataJson.message}
                    handleGroup={setOpened}
                />
            </div>
            <GoToGroup 
                message={message}
                modalOpen={opened}
                onClose={() => setOpened(null)} 
            />
        </>
    )
}

export default GroupInviteCard

