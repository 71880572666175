import React from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

import { toast } from 'react-toastify'
import ButtonWithSpinner from '../../../components/ButtonWithSpinner'

import { isEmpty } from 'lodash'
import moment from 'moment'
import DepartmentSelect from 'components/DepartmentSelect'
import UserSelect from 'components/UserSelect'

import useStyles from './Style'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { FormControlLabel, Switch, useTheme } from '@material-ui/core'
import useAuth from 'hooks/useAuth.js'

const EIGTH_HOURS = 28800000

export default function Filter({
    onFilter = async () => {},
    setLoading = () => {},
    loading = false,
    fullWidth = false,
}) {
    const classes = useStyles()
    const theme = useTheme()

    const { user } = useAuth()

    const filtersSaved = JSON.parse(localStorage.getItem(`dashboardFilter_${user.companyId}`) || '{}')

    // Limpa datas antigas caso a última atualizaçao foi maior que 8 horas
    if (filtersSaved.lastUpdate <= Date.now() - EIGTH_HOURS)
        filtersSaved.dateFrom = filtersSaved.dateTo = undefined

    const [dateFrom, setDateFrom] = React.useState(filtersSaved.dateFrom || moment('1', 'D').format('YYYY-MM-DD'))
    const [dateTo, setDateTo] = React.useState(filtersSaved.dateTo || moment().format('YYYY-MM-DD'))
    
    const [filterType, setFilterType] = React.useState(filtersSaved.filterType || 'date')
    const [period, setPeriod] = React.useState(filtersSaved.period || 0)
    const [selectedUserIds, setSelectedUserIds] = React.useState(filtersSaved.selectedUserIds || [])
    const [selectedDepartmentIds, setSelectedDepartmentIds] = React.useState(filtersSaved.selectedDepartmentIds || [])
    const [onlyActive, setOnlyActive] = React.useState(filtersSaved.onlyActive || true)

    async function handleChangePeriod(value) {
        setPeriod(value)
    }

    async function handleChangeFilterType(value) {
        setFilterType(value)
        if (value === 'date') {
            setPeriod(0)
        } else {
            setDateFrom(null)
            setDateTo(null)
        }
    }

    function handleFilterInvalid() {
        toast.error('Informe os filtros de Data ou Período corretamente')
        setLoading(false)
    }

    async function fetchData() {
        setLoading(true)

        let params = {
            users: selectedUserIds,
            departments: selectedDepartmentIds,
            only_active: onlyActive ? 1 : 0,
        }

        if (filterType === 'date') {
            if (isEmpty(dateFrom) || isEmpty(dateTo) || !moment(dateFrom).isValid() || !moment(dateTo).isValid())
                return handleFilterInvalid()

            params = {
                ...params,
                date_from: moment(dateFrom).format('YYYY-MM-DD'),
                date_to: moment(dateTo).format('YYYY-MM-DD'),
            }
        } else {
            if ((!Number.isInteger(period) && isEmpty(period)) || period <= 0)
                return handleFilterInvalid()

            params = {
                ...params,
                days: period,
            }
        }

        await onFilter(params)
        setLoading(false)
    }

    React.useEffect(() => {
        localStorage.setItem(`dashboardFilter_${user.companyId}`, JSON.stringify({filterType, period, dateFrom, dateTo, selectedUserIds, selectedDepartmentIds, onlyActive, lastUpdate: Date.now()}))
    }, [filterType, period, dateFrom, dateTo, selectedUserIds, selectedDepartmentIds, onlyActive, user.companyId])

    React.useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const widthFilter = { xs: 12, ...(fullWidth ? {} : { sm: 6, md: 4 }) }

    return (
        <>
            <Grid item {...widthFilter}>
                <FormControl className={classes.selectContainer}>
                    <InputLabel id="period-selector-label">
                        Tipo de Filtro
                    </InputLabel>
                    <Select
                        labelId="period-selector-label"
                        value={filterType}
                        onChange={e => handleChangeFilterType(e.target.value)}>
                        <MenuItem value="date">Filtro por Data</MenuItem>
                        <MenuItem value="period">Filtro por Período</MenuItem>
                    </Select>
                    <FormHelperText>
                        Selecione o período desejado
                    </FormHelperText>
                </FormControl>
            </Grid>

            {filterType === 'date' ? (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid item {...widthFilter}>
                        <KeyboardDatePicker
                            disableFuture
                            margin='dense'
                            label='Data Inicial'
                            size='small'
                            fullWidth
                            inputVariant="outlined"
                            format="DD/MM/yyyy"
                            name="dateFrom"
                            value={dateFrom}
                            maxDate={dateTo || undefined}
                            onChange={(date) => setDateFrom(date?.isValid() ? date.format('YYYY-MM-DD') : null)}
                        />
                    </Grid>
                    <Grid item {...widthFilter}>
                        <KeyboardDatePicker
                            disableFuture
                            margin='dense'
                            label='Data Final'
                            size='small'
                            fullWidth
                            inputVariant="outlined"
                            format="DD/MM/yyyy"
                            name="dateTo"
                            value={dateTo}
                            minDate={dateFrom || undefined}
                            onChange={(date) => setDateTo(date?.isValid() ? date.format('YYYY-MM-DD') : null)}
                        />
                    </Grid>
                </MuiPickersUtilsProvider>
            ) : (
                <Grid item {...widthFilter}>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="period-selector-label">
                            Período
                        </InputLabel>
                        <Select
                            labelId="period-selector-label"
                            id="period-selector"
                            value={period}
                            onChange={e => handleChangePeriod(e.target.value)}>
                            <MenuItem value={0}>Nenhum selecionado</MenuItem>
                            <MenuItem value={3}>Últimos 3 dias</MenuItem>
                            <MenuItem value={7}>Últimos 7 dias</MenuItem>
                            <MenuItem value={15}>Últimos 15 dias</MenuItem>
                            <MenuItem value={30}>Últimos 30 dias</MenuItem>
                            <MenuItem value={60}>Últimos 60 dias</MenuItem>
                            <MenuItem value={90}>Últimos 90 dias</MenuItem>
                        </Select>
                        <FormHelperText>
                            Selecione o período desejado
                        </FormHelperText>
                    </FormControl>
                </Grid>
            )}

            <Grid item {...widthFilter}>
                <DepartmentSelect
                    value={selectedDepartmentIds}
                    onChange={setSelectedDepartmentIds}
                />
            </Grid>

            <Grid item {...widthFilter}>
                <UserSelect
                    value={selectedUserIds}
                    onChange={setSelectedUserIds}
                    multiple
                />
            </Grid>

            {filterType !== 'date' && <Grid item {...widthFilter} style={{ height: 40 }} />}

            <Grid
                item
                {...widthFilter}
                className={classes.alignRight}
            >
                <FormControlLabel
                    style={{ color: theme.palette.text.primary}}
                    label="Apenas usuários ativos"
                    control={
                        <Switch
                            title='Apenas usuários ativos'
                            size="medium"
                            checked={onlyActive}
                            onChange={e => setOnlyActive(!!e.target.checked)}
                            name="onlyActive"
                            color="primary"
                        />
                    }
                />
                <ButtonWithSpinner
                    loading={loading}
                    onClick={() => fetchData()}
                    variant="contained"
                    color="primary"
                >
                    Filtrar
                </ButtonWithSpinner>
            </Grid>
        </>
    )
}
