import {
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { AuthContext } from 'context/Auth/AuthContext'
import { useContext, useEffect, useState } from 'react'
import api from 'services/api'

const ConnectionSelect = ({ value, onChange, error, label, defaultText = 'Selecione uma Conexão', showInactive = false, nullSelect, ...props }) => {
    const [whatsapps, setWhatsapps] = useState([])
    const { user } = useContext(AuthContext)
    const { companyId, whatsappId } = user

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (!value && !nullSelect && whatsappId !== null && whatsappId !== undefined)
                onChange(whatsappId)

            api.get(`/whatsapp`, { companyId, session: 0 }).then(({ data }) =>
                // Não me orgulho desse filtro, mas é mais rápido que ajustar o backend
                setWhatsapps(data.filter(w => showInactive || !w.inactive)),
            )
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, user, whatsappId, onChange, showInactive])

    return (
        <FormControl fullWidth variant="outlined" error={Boolean(error)} {...props}>
            {label && (
                <InputLabel id='label-connection-select' >{label}</InputLabel>
            )}

            <Select
                displayEmpty
                labelId="label-connection-select"
                label={label}
                value={value || ''}
                onChange={e => {
                    onChange(e.target.value)
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                renderValue={() => {
                    if (value === '' || !value) return defaultText

                    return whatsapps.find(w => w.id === value)?.name
                }}>
                <MenuItem value={null}>Selecione...</MenuItem>

                {whatsapps?.length > 0 &&
                    whatsapps.map((whatsapp, key) => (
                        <MenuItem dense key={key} value={whatsapp.id}>
                            <ListItemText primary={
                                <Typography component="span" style={{ fontSize: 14 }}>
                                    {whatsapp.name} ({whatsapp.status})
                                </Typography>
                            }/>
                        </MenuItem>
                    ))}
            </Select>
            {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}

export default ConnectionSelect
