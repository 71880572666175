import React, { useContext } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import { toast } from 'react-toastify'
import { SettingContext } from 'pages/SettingsCustom/SettingsContext'
import BackdropLoading from 'components/BackdropLoading'

export default function SettingsTicket({ ...props }) {
    const { settings, setSetting } = useContext(SettingContext)
    const { sendGreetingAccepted, transferAdvice, ticketReturn } = settings

    if (!Object.keys(settings)?.length) return <BackdropLoading />

    async function handleSettingChange(setting, value) {
        await setSetting(setting, value)
        toast.success('Operação atualizada com sucesso.')
    }

    return (
        <Grid spacing={3} container>
            <Grid xs={12} sm={6} md={4} item>
                <FormControl fullWidth>
                    <InputLabel id="sendGreetingAccepted-label">
                        Enviar saudação ao aceitar o ticket
                    </InputLabel>
                    <Select
                        labelId="sendGreetingAccepted-label"
                        value={sendGreetingAccepted}
                        onChange={e => {
                            handleSettingChange(
                                'sendGreetingAccepted',
                                e.target.value,
                            )
                        }}>
                        <MenuItem value={false}>Desabilitado</MenuItem>
                        <MenuItem value={true}>Habilitado</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
                <FormControl fullWidth>
                    <InputLabel id="transferAdvice-label">
                        Enviar mensagem de transferencia de Setor/agente
                    </InputLabel>
                    <Select
                        labelId="transferAdvice-label"
                        value={transferAdvice}
                        onChange={e => {
                            handleSettingChange(
                                'transferAdvice',
                                e.target.value,
                            )
                        }}>
                        <MenuItem value={false}>Desabilitado</MenuItem>
                        <MenuItem value={true}>Habilitado</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid xs={12} sm={6} md={4} item>
                <FormControl fullWidth>
                    <InputLabel id="ticketReturn-label">
                        Ao retornar atendimento:
                    </InputLabel>
                    <Select
                        labelId="ticketReturn-label"
                        value={ticketReturn}
                        onChange={e => {
                            handleSettingChange('ticketReturn', e.target.value)
                        }}>
                        <MenuItem value="default">
                            Ir para aguardando/bot
                        </MenuItem>
                        <MenuItem value="returnToUser">
                            Transferir para último atendente
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}
