import Validator from 'helpers/Validator'

const MessageSchema = Validator.object().shape({
    message: Validator.string().required().label('Mensagem'),
})

const MenuSchema = Validator.object().shape({
    items: Validator.array()
        .of(
            Validator.object().shape({
                message: Validator.string().required().label('Mensagem'),
            }),
        )
        .min(1, 'Precisa ter pelo menos um item.')
        .max(20, 'Muitos itens!')
        .label('Itens'),
})

const SleepSchema = Validator.object().shape({
    time: Validator.number()
        .min(1)
        .max(60 * 60 * 10, 'Este tempo é muito longo!').label('Tempo'),
})

const AskSchema = Validator.object().shape({
    answer: Validator.string().nullable().required().label('Variável'),
    // message: Validator.string().required(),
})

const NotifySchema = Validator.object().shape({
    number: Validator.string()
        .required()
        .matches(
            /^\+?55? ?(\(?[0-9]{2}\)?) ?([0-9]{4,5})-?([0-9]{4})$/,
            'O formato do telefone é inválido, use 55 99 99999-9999',
        ), // Verifica número Brasileiro
    notification: Validator.string().required().label('Mensagem'),
})

const ConditionSchema = Validator.object().shape({
    conditions: Validator.array()
        .of(
            Validator.object().shape({
                value: Validator.string()
                    .nullable()
                    .required()
                    .label('Valor'),
                operator: Validator.string().required().label('Operador'),
                comparison: Validator.string().nullable().label('Comparação'),
            }),
        )
        .min(1, 'Precisa ter pelo menos um item.')
        .max(20, 'Muitos itens!'),
})

const HttpSchema = Validator.object().shape({
    request: Validator.string().required(),
    url: Validator.string().url().required(),
})

const CreateLead = Validator.object().shape({
    map: Validator.object().shape({
        name: Validator.string().required(),
    }),
})

const FindPerson = Validator.object().shape({
    type: Validator.string().required().label('Buscar com'),
})


const validations = {
    sleep: SleepSchema,
    message: MessageSchema,
    menu: MenuSchema,
    ask: AskSchema,
    notify: NotifySchema,
    condition: ConditionSchema,
    httpRequest: HttpSchema,
    createLead: CreateLead,
    findPerson: FindPerson,
}
export default validations
