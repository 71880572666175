import React from 'react'

import { Bar } from 'react-chartjs-2'
import useChartJs from 'hooks/useChartJs'
import { Paper } from '@material-ui/core'
import useStyles from '../components/Style'

const chartStructure = {
    '00:00': 0, '01:00': 0, '02:00': 0, '03:00': 0, '04:00': 0, '05:00': 0, '06:00': 0, '07:00': 0, '08:00': 0, '09:00': 0, '10:00': 0, '11:00': 0, 
    '12:00': 0, '13:00': 0, '14:00': 0, '15:00': 0, '16:00': 0, '17:00': 0, '18:00': 0, '19:00': 0, '20:00': 0, '21:00': 0, '22:00': 0, '23:00': 0,
}

const ChartPeakTime = ({ dashboardData }) => {
    const { backgroundColor, generateLabels, clickLegend, random, getHeight, getOptions } = useChartJs()
    const classes = useStyles()

    if (dashboardData === undefined)
        return null

    const dataChart = { ...chartStructure }
    const labels = Object.keys(dataChart)

    Object.entries(dashboardData).forEach(([index, data]) => {
        dataChart[`${data.hour.toString().padStart(2, '0')}:00`] += data.total || 0
    })

    return (
        <Paper className={classes.chartHeight} style={{ height: getHeight(labels.length) }} >
            <Bar 
                options={
                    getOptions({
                        plugins: {
                            autocolors: { enabled: true, mode: 'data', offset: random() },
                            legend: { 
                                position: 'right',
                                labels: { generateLabels },
                                onClick: clickLegend
                            },
                            title: {
                                align: 'start',
                                display: true,
                                text: 'Horários de Pico',
                                font: { size: 18 },
                            },
                            datalabels: {
                                backgroundColor: context => context.dataset.backgroundColor,
                                borderColor: backgroundColor,
                                borderRadius: 50,
                                borderWidth: 2,
                                color: 'white',
                                display: true,
                                padding: { left: 8, right: 8 },
                                font: { weight: 'bold' },
                            },
                        },
                    })
                } 
                data={{
                    labels,
                    datasets: [
                        { label: 'Mensagens', data: Object.values(dataChart), borderRadius: 8 }
                    ],
                }} 
            />
        </Paper>
    )
}

export default ChartPeakTime
