import { useRef } from 'react'
import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import CustomNodeBase from '../../components/CustomNodeBase'
import MarkdownWrapper from 'components/MarkdownWrapper'
import RateReviewIcon from '@material-ui/icons/RateReview'
import { Box, makeStyles } from '@material-ui/core'
import styles from '../../general.module.scss'

const useStyles = makeStyles(theme => ({
    menuItemError: {
        marginTop: 5,
        backgroundColor: theme.palette.error.semilight,

        position: 'relative',
        width: '100%',
        fontSize: '0.5rem',
        paddingInline: 2,
        borderRadius: 3,
    },
}))

function AskNode(myNode) {
    const { data, ...props } = myNode
    const classes = useStyles()
    const refCustomNode = useRef(null)

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                ref={refCustomNode}
                node={myNode}
                name="Pergunta"
                icon={RateReviewIcon}
                noContainer>
                <div className={styles.content}>
                    {data.answer ? (
                        <MarkdownWrapper>
                            {data.type === 'manual'
                                ? 'Preenchido manualmente'
                                : data.message}
                        </MarkdownWrapper>
                    ) : (
                        <Box onClick={() => refCustomNode.current?.open()}>
                            Clique para configurar!
                        </Box>
                    )}
                </div>

                {/* CONECTOR DE SUCESSO */}
                <CustomStartHandle
                    position={Position.Right}
                    id={props.id.toString()}
                    nodeId={props.id}
                />

                {/* CONECTOR DE ERRO */}
                {data.type === 'menu' && (
                    <div className={classes.menuItemError}>
                        <CustomStartHandle
                            position={Position.Right}
                            id={props.id + 'error'}
                            nodeId={props.id}
                        />
                        Errou mais de 3 vezes
                    </div>
                )}
            </CustomNodeBase>
        </div>
    )
}

export default AskNode
