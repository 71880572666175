import { Field, useField } from 'formik'
import MessageWithPicker from 'pages/ChatFlow/components/MessageWithPicker'
import { Grid, TextField } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import DepartmentSelectCustom from 'components/DepartmentSelectCustom'

const FormikDepartmentSelect = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        helpers.setValue(value)
    }

    const options = {...field, ...props}
    return <DepartmentSelectCustom {...options} selectedDepartmentIds={options.value ? [options.value] : []} onChange={handleChange} />
}

const DepartmentForm = props => {
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Field
                    as={FormikDepartmentSelect}
                    name="departmentId"
                    label="Setor"
                    clearable
                    multiple={false}
                    {...props.getFieldProps(`departmentId`)}
                />
            </Grid>

            <Alert style={{ width: '100%' }} severity='info' >Se o campo "Setor" estiver vazio, o setor será removido do ticket</Alert>

            <Grid item xs={12}>
                <hr style={{ borderStyle: 'dashed', borderColor: '#b9b9b9' }} />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    {...props.getFieldProps(`message`)}
                />
            </Grid>
        </Grid>
    )
}

export default DepartmentForm
