import { Grid } from "@material-ui/core";
import Title from "pages/Dashboard/components/Title";
import DashTextItem from "../components/DashTextItem";

const DepartmentCards = ({ departments }) => {

    return (
        <>
            <Title>Setores: {departments.length}</Title>
            <Grid container spacing={1} justifyContent="center">
            {
                departments.map((department, key) => (
                    <DashTextItem key={key} value={department.users} label={'Usuários'} page="users" permission="user:page" {...department} />
                ))
            }
            </Grid>
        </>
    )
}

export default DepartmentCards