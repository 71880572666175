import React, { useState, useEffect, useRef, useCallback } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from '../../services/api'
import toastError from '../../errors/toastError'
import MessageVariablesPicker from '../MessageVariablesPicker'
import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from '@material-ui/core'
import FileUploader from 'components/FileUploader/FileUploader'
import DepartmentSelect from 'components/DepartmentSelect'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}))

const QuickMessageSchema = Validator.object().shape({
    shortcode: Validator.string().required().label('Atalho'),
    type: Validator.string(),
    quickMessageDepartment: Validator.array()
        .when('type', {
            is: 'department',
            then: schema => schema.min(1),
        })
        .label('Setores'),
})

const QuickMessageDialog = ({ open, onClose, quickmessageId, reload }) => {
    const classes = useStyles()
    const messageInputRef = useRef()

    const initialState = {
        shortcode: '',
        message: '',
        status: true,
        type: 'personal',
        quickMessageDepartment: [],
    }

    const [quickMessage, setQuickMessage] = useState(initialState)

    const reloadForm = useCallback(async () => {
        if (!quickmessageId) return

        return api
            .get(`/quick-messages/${quickmessageId}`, {
                include: {
                    file: '*,@url',
                    quickMessageDepartment: '*',
                },
            })
            .then(({ data }) => {
                data.quickMessageDepartment = data.quickMessageDepartment?.map(
                    q => q.departmentId,
                )

                setQuickMessage(prevState => ({ ...prevState, ...data }))
            })
            .catch(toastError)
    }, [quickmessageId])

    useEffect(() => {
        reloadForm()
    }, [quickmessageId, reloadForm])

    const handleClose = () => {
        setQuickMessage(initialState)
        onClose()
    }

    const handleSaveQuickMessage = async values => {
        try {
            if (quickmessageId)
                await api.put(`/quick-messages/${quickmessageId}`, values)
            else await api.post('/quick-messages', values)
            toast.success('Atalho adicionado com sucesso!')
            if (typeof reload == 'function') reload()
        } catch (err) {
            toastError(err)
        }
        handleClose()
    }

    const handleClickMsgVar = async (msgVar, setValueFunc) => {
        const el = messageInputRef.current
        const firstHalfText = el.value.substring(0, el.selectionStart)
        const secondHalfText = el.value.substring(el.selectionEnd)
        const newCursorPos = el.selectionStart + msgVar.length

        setValueFunc('message', `${firstHalfText}${msgVar}${secondHalfText}`)

        await new Promise(r => setTimeout(r, 100))
        messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos)
    }

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {quickmessageId ? `Editar` : `Adicionar`}
                </DialogTitle>
                <Formik
                    initialValues={quickMessage}
                    enableReinitialize={true}
                    validationSchema={QuickMessageSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveQuickMessage(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        values,
                        setFieldTouched,
                    }) => (
                        <Form>
                            <DialogContent dividers>
                                <Grid spacing={2} container>
                                    <Grid xs={12} item>
                                        <Field
                                            as={TextField}
                                            autoFocus
                                            label="Atalho"
                                            name="shortcode"
                                            error={
                                                touched.shortcode &&
                                                Boolean(errors.shortcode)
                                            }
                                            helperText={
                                                touched.shortcode &&
                                                errors.shortcode
                                            }
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid xs={12} item>
                                        <Field
                                            as={TextField}
                                            label="Resposta"
                                            name="message"
                                            inputRef={messageInputRef}
                                            error={
                                                touched.message &&
                                                Boolean(errors.message)
                                            }
                                            helperText={
                                                touched.message &&
                                                errors.message
                                            }
                                            variant="outlined"
                                            margin="dense"
                                            multiline={true}
                                            rows={7}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item>
                                        <MessageVariablesPicker
                                            disabled={isSubmitting}
                                            onClick={value =>
                                                handleClickMsgVar(
                                                    value,
                                                    setFieldValue,
                                                )
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <FormControl
                                            margin="dense"
                                            variant="outlined"
                                            fullWidth>
                                            <InputLabel id="type-label">
                                                Tipo
                                            </InputLabel>
                                            <Field
                                                as={Select}
                                                id="type"
                                                label="Tipo"
                                                labelId="type-label"
                                                name="type">
                                                <MenuItem value="personal">
                                                    Pessoal
                                                </MenuItem>
                                                <MenuItem value="department">
                                                    Setor
                                                </MenuItem>
                                                <MenuItem value="general">
                                                    Geral
                                                </MenuItem>
                                            </Field>
                                        </FormControl>
                                    </Grid>

                                    {values.type === 'department' && (
                                        <Grid item xs={12}>
                                            <DepartmentSelect
                                                onChange={e =>
                                                    setFieldValue(
                                                        'quickMessageDepartment',
                                                        e,
                                                    )
                                                }
                                                value={
                                                    values.quickMessageDepartment
                                                }
                                                label="Setores"
                                                onFocus={e =>
                                                    setFieldTouched(
                                                        'quickMessageDepartment',
                                                    )
                                                }
                                                error={
                                                    errors.quickMessageDepartment
                                                }
                                            />
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        <FileUploader
                                            value={values.file}
                                            changeValue={e =>
                                                setFieldValue('file', e)
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    Salvar
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default QuickMessageDialog
