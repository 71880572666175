import { Handle, Position } from 'reactflow'
import CustomStartHandle from '../../components/CustomStartHandle'
import { useEffect, useState } from 'react'
import api from 'services/api'
import styles from '../../general.module.scss'
import CustomNodeBase from '../../components/CustomNodeBase'
import MarkdownWrapper from 'components/MarkdownWrapper'
import GroupIcon from '@material-ui/icons/Group'
import { contrastColor } from 'helpers/helpers'

function DepartmentNode(myNode) {
    const { data, ...props } = myNode
    const [department, setDepartment] = useState()

    useEffect(() => {
        if (!data.departmentId) return setDepartment(null)

        api.get(`/department/${data.departmentId}`).then(r => {
            setDepartment(r.data)
        })
    }, [data.departmentId])

    return (
        <div>
            <Handle type="target" position={Position.Top} />
            <CustomNodeBase
                node={myNode}
                name="Troca de Setor"
                icon={GroupIcon}>
                <div className={styles.blockList}>
                    <div
                        className={styles.blockContent}
                        style={{
                            backgroundColor: department?.color,
                            color: contrastColor(department?.color),
                        }}>
                        {department?.name || 'Remover setor'}
                    </div>
                </div>

                {data.message && (
                    <div className={styles.blockList}>
                        <span className={styles.blockTitle}>Mensagem:</span>
                        <div className={styles.blockContent}>
                            <MarkdownWrapper>{data.message}</MarkdownWrapper>
                        </div>
                    </div>
                )}
            </CustomNodeBase>
            <CustomStartHandle position={Position.Bottom} nodeId={props.id} />
        </div>
    )
}

export default DepartmentNode
