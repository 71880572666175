import React, { useEffect, useRef, useState } from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import api from '../../services/api'
import ConfirmationModal from '../ConfirmationModal'
import TransferTicketModalCustom from '../TransferTicketModalCustom'
import toastError from '../../errors/toastError'
import { Can } from '../Can'
import useSettings from 'hooks/useSettings'

const TicketOptionsMenu = ({
    children,
    ticket,
    menuOpen,
    handleClose,
    anchorEl,
    noActions,
    handleIframeOpen,
}) => {
    const { getSetting } = useSettings()
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const [transferTicketModalOpen, setTransferTicketModalOpen] =
        useState(false)
    const [iframes, setIframes] = useState([])
    const isMounted = useRef(true)

    useEffect(() => {
        getSetting('iframes').then(iframes => {
            setIframes(iframes?.filter(i => i.active))
        })

        return () => {
            isMounted.current = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDeleteTicket = async () => {
        try {
            await api.delete(`/tickets/${ticket.id}`)
        } catch (err) {
            toastError(err)
        }
    }

    const handleOpenConfirmationModal = e => {
        setConfirmationOpen(true)
        handleClose()
    }

    const handleOpenTransferModal = e => {
        setTransferTicketModalOpen(true)
        handleClose()
    }

    const handleCloseTransferTicketModal = () => {
        if (isMounted.current) {
            setTransferTicketModalOpen(false)
        }
    }

    return (
        <>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}
            >
                {children}
                {!noActions &&
                <>
                    <MenuItem onClick={handleOpenTransferModal}>
                        🔀 Transferir
                    </MenuItem>
                    {Boolean(iframes?.length) &&
                        iframes.map(iframe => (
                            <MenuItem
                                key={iframe.uuid}
                                onClick={() => handleIframeOpen(iframe)}>
                                {iframe.name}
                            </MenuItem>
                        ))}
                    <Can perform="ticket:delete:all">
                        <MenuItem onClick={handleOpenConfirmationModal}>
                            🗑️ Deletar
                        </MenuItem>
                    </Can>
                </>}
            </Menu>
            {!noActions &&
            <>
                <ConfirmationModal
                    title={`Deletar o ticket do contato ${ticket.contact.name} #${ticket.id}?`}
                    open={confirmationOpen}
                    onClose={setConfirmationOpen}
                    onConfirm={handleDeleteTicket}>
                    Atenção! Todas as mensagens relacionadas ao ticket serão
                    perdidas.
                </ConfirmationModal>
                <TransferTicketModalCustom
                    modalOpen={transferTicketModalOpen}
                    onClose={handleCloseTransferTicketModal}
                    ticketid={ticket.id}
                    currentStatus={ticket.status}
                />
            </>}
        </>
    )
}

export default TicketOptionsMenu
