import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { cloneDeep } from 'lodash'
import { useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import MessageWithPicker from '../../components/MessageWithPicker'
import { Field } from 'formik'
import { ArrowDownward, ArrowUpward, ExpandMore } from '@material-ui/icons'

const ItemList = ({ onRemove, item, ...props }) => {
    const handleFocus = () => {
        let items = props.touched.items
        if (!items?.length) items = []

        items[item] = {
            message: true,
        }

        props.setTouched({ ...props.touched, items })
    }

    return (
        <FormControl
            variant="outlined"
            fullWidth
            style={{ marginBottom: 10 }}
            onFocus={handleFocus}
            error={
                props.touched.items?.[item]?.message &&
                props.errors.items?.[item]?.message
            }>
            <InputLabel>Opção {item + 1}</InputLabel>
            <OutlinedInput
                label={'Opção ' + (item + 1)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={onRemove}>
                            <DeleteIcon />
                        </IconButton>
                    </InputAdornment>
                }
                value={props.values.items[item].message}
                onChange={ev =>
                    props.setFieldValue(
                        `items[${item}].message`,
                        ev.target.value,
                    )
                }
            />

            {props.touched.items?.[item]?.message &&
                props.errors.items?.length && (
                    <FormHelperText>
                        {props.errors.items[item]?.message}
                    </FormHelperText>
                )}
        </FormControl>
    )
}

const MenuForm = props => {
    const addOption = useCallback(() => {
        const newValues = cloneDeep(props.values)
        if (!newValues.items?.length) newValues.items = []
        newValues.items.push({ id: uuid(), message: '' })

        props.setValues(newValues)
    }, [props])

    const removeItem = index => {
        const items = props.touched.items
        items.splice(index, 1)
        props.setTouched({ ...props.touched, items })

        const newValues = cloneDeep(props.values)
        newValues.items.splice(index, 1) // Criado clone para não alterar o data por referencia

        props.setValues(newValues)
    }

    const moveItem = (index, direction) => {
        const newIndex = index + direction
        if (newIndex < 0 || newIndex >= props.values.items.length) return // Evita ultrapassar os limites

        const newItems = [...props.values.items]
        ;[newItems[index], newItems[newIndex]] = [
            newItems[newIndex],
            newItems[index],
        ] // Troca os elementos
        props.setFieldValue('items', newItems) // Atualiza o estado
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="title"
                    label="Título"
                    error={props.touched?.title && props.errors?.title}
                    helperText={props.touched?.title && props.errors?.title}
                    {...props.getFieldProps(`title`)}
                />
            </Grid>

            <Grid item xs={12}>
                <MessageWithPicker
                    name="message"
                    label="Mensagem"
                    error={props.touched?.message && props.errors?.message}
                    helperText={props.touched?.message && props.errors?.message}
                    {...props.getFieldProps(`message`)}
                />
            </Grid>

            <Grid item xs={12}>
                <Button fullWidth onClick={addOption} variant="contained">
                    Adicionar opção
                </Button>
            </Grid>

            <Grid item xs={12}>
                <hr style={{ borderStyle: 'dashed', borderColor: '#b9b9b9' }} />
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {props.values.items?.map((item, index) => (
                        <>
                            <Grid item xs={1} justifyContent="center">
                                <IconButton
                                    disabled={index === 0}
                                    size="small"
                                    onClick={() => moveItem(index, -1)}>
                                    <ArrowUpward />
                                </IconButton>
                                <IconButton
                                    disabled={
                                        index === props.values.items.length - 1
                                    }
                                    size="small"
                                    onClick={() => moveItem(index, 1)}>
                                    <ArrowDownward />
                                </IconButton>
                            </Grid>
                            <Grid item xs={11}>
                                <ItemList
                                    item={index}
                                    key={index}
                                    onRemove={() => removeItem(index)}
                                    {...props}
                                />
                            </Grid>
                        </>
                    ))}
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        Mensagem em caso de erro
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: 'column' }}>
                        <MessageWithPicker
                            name="errorMessage"
                            label="Mensagem em caso de erro"
                            error={
                                props.touched?.errorMessage &&
                                props.errors?.errorMessage
                            }
                            helperText={
                                props.touched?.errorMessage &&
                                props.errors?.errorMessage
                            }
                            {...props.getFieldProps(`errorMessage`)}
                        />
                    </AccordionDetails>
                </Accordion>
            </Grid>
        </Grid>
    )
}

export default MenuForm
