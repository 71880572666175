import React, { useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
    makeStyles,
    createTheme,
    ThemeProvider,
} from '@material-ui/core/styles'
import { IconButton, isWidthDown, MenuItem, withWidth } from '@material-ui/core'
import { DoneAll, MoreVert, PlayCircleFilled, Replay } from '@material-ui/icons'

import api from '../../services/api'
import TicketOptionsMenu from '../TicketOptionsMenu'
import ButtonWithSpinner from '../ButtonWithSpinner'
import toastError from '../../errors/toastError'
import { AuthContext } from '../../context/Auth/AuthContext'
import { TicketsContext } from '../../context/Tickets/TicketsContext'
import UndoRoundedIcon from '@material-ui/icons/UndoRounded'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import { green } from '@material-ui/core/colors'
import CloseTicketModal from 'components/CloseTicketModal'
import SearchMessage from 'components/SearchMessage'
import ConfirmationModal from 'components/ConfirmationModal'
import { useTheme } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
    actionButtons: {
        marginRight: 6,
        flex: 'none',
        alignSelf: 'center',
        marginLeft: 'auto',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
}))

const TicketActionButtonsCustom = ({
    ticket,
    onSearch,
    noActions = false,
    handleIframeOpen,
    ...props
}) => {
    const classes = useStyles()
    const history = useHistory()
    const [anchorEl, setAnchorEl] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openFinish, setOpenFinish] = useState(false)
    const [openSearch, setOpenSearch] = useState(false)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const ticketOptionsMenuOpen = Boolean(anchorEl)
    const { user } = useContext(AuthContext)
    const { setCurrentTicket } = useContext(TicketsContext)
    const theme = useTheme()

    const isSmallScreen = useMemo(() => isWidthDown('xs', props.width), [props.width])

    const customTheme = createTheme({
        palette: {
            primary: green,
        },
    })

    const handleOpenTicketOptionsMenu = e => {
        setAnchorEl(e.currentTarget)
    }

    const handleCloseTicketOptionsMenu = e => {
        setAnchorEl(null)
    }

    const handleUpdateTicketStatus = async (e, status, userId) => {      
        if (status === 'closed') return setOpenFinish(true)
            
        setLoading(true)
        try {
            await api.put(`/tickets/${ticket.id}`, {
                status: status,
                userId: userId || null,
                flowId: null,
                step: null,
            })

            setLoading(false)
            if (status === 'open') {
                setCurrentTicket({ ...ticket, code: '#open' })
            } else {
                setCurrentTicket({ id: null, code: null })
                history.push('/tickets')
            }
        } catch (err) {
            setLoading(false)
            toastError(err)
        }
    }

    if (!ticket?.contact) return null

    return (
        <div className={classes.actionButtons}>
            <CloseTicketModal
                ticket={ticket}
                open={openFinish}
                onClose={() => setOpenFinish(false)}
            />
            <SearchMessage
                ticket={ticket}
                open={openSearch}
                onClick={onSearch}
                onClose={() => setOpenSearch(false)}
            />
            <ConfirmationModal
                title={`Retornar o atendimento do contato ${ticket.contact.name} #${ticket.id}?`}
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={e => handleUpdateTicketStatus(e, 'pending', null)}>
                O atendimento voltará para o status aguardando.
            </ConfirmationModal>

            {!noActions && ticket.status === 'closed' && (
                <ButtonWithSpinner
                    loading={loading}
                    startIcon={<Replay />}
                    size="small"
                    onClick={e =>
                        handleUpdateTicketStatus(e, 'open', user?.id)
                    }>
                    Reabrir
                </ButtonWithSpinner>
            )}

            <ThemeProvider theme={customTheme}>
                {!isSmallScreen && 
                <>
                    {ticket.status === 'open' && (
                        <Tooltip title="Enviar p/ Aguardando">
                            <ButtonWithSpinner
                                loading={loading}
                                component={IconButton}
                                onClick={e => setConfirmationOpen(true)}>
                                <UndoRoundedIcon htmlColor={theme.mode === "light" ? '#284359' : '#FFFFFF'}/>
                            </ButtonWithSpinner>
                        </Tooltip>
                    )}
                    {['pending', 'bot'].includes(ticket.status) && (
                        <Tooltip title="Aceitar">
                            <ButtonWithSpinner
                                loading={loading}
                                component={IconButton}
                                onClick={e =>
                                    handleUpdateTicketStatus(e, 'open', user?.id)
                                }>
                                <PlayCircleFilled htmlColor="green" />
                            </ButtonWithSpinner>
                        </Tooltip>
                    )}
                    <Tooltip title="Finalizar Atendimento">
                        <ButtonWithSpinner
                            loading={loading}
                            component={IconButton}
                            onClick={e =>
                                handleUpdateTicketStatus(e, 'closed', user?.id)
                            }>
                            <DoneAll htmlColor="red" />
                        </ButtonWithSpinner>
                    </Tooltip>
                    <Tooltip title="Buscar">
                        <ButtonWithSpinner
                            loading={loading}
                            component={IconButton}
                            onClick={() => setOpenSearch(true)}>
                            <SearchIcon htmlColor={theme.mode === "light" ? '#284359' : '#FFFFFF'} />
                        </ButtonWithSpinner>
                    </Tooltip>
                </>}

                {!noActions &&
                <IconButton onClick={handleOpenTicketOptionsMenu}>
                    <MoreVert htmlColor={theme.mode === "light" ? '#284359' : '#FFFFFF'} />
                </IconButton>}

                <TicketOptionsMenu
                    ticket={ticket}
                    anchorEl={anchorEl}
                    noActions={noActions}
                    menuOpen={ticketOptionsMenuOpen}
                    handleClose={handleCloseTicketOptionsMenu}
                    handleIframeOpen={handleIframeOpen}
                >
                    {isSmallScreen && 
                    <>
                        {ticket.status === 'open' && (
                            <MenuItem onClick={e => setConfirmationOpen(true)}>
                                <UndoRoundedIcon htmlColor={theme.mode === "light" ? '#284359' : '#FFFFFF'}/>&nbsp;Enviar p/ Aguardando
                            </MenuItem>
                        )}
                        {['pending', 'bot'].includes(ticket.status) && (
                            <MenuItem onClick={e => handleUpdateTicketStatus(e, 'open', user?.id)}>
                                <PlayCircleFilled htmlColor="green" />&nbsp;Aceitar
                            </MenuItem>
                        )} 
                        <MenuItem onClick={e => handleUpdateTicketStatus(e, 'closed', user?.id)}>
                            <DoneAll htmlColor="red" />&nbsp;Finalizar Atendimento
                        </MenuItem>
                        <MenuItem onClick={() => setOpenSearch(true)}>
                            <SearchIcon htmlColor={theme.mode === "light" ? '#284359' : '#FFFFFF'} />&nbsp;Buscar
                        </MenuItem>
                    </>}
                </TicketOptionsMenu>
            </ThemeProvider>
        </div>
    )
}

export default withWidth()(TicketActionButtonsCustom)
