const ptBr = {
    // Models
    Contact: 'Contato',
    contact: 'Contato',
    QuickMessage: 'Resposta rápida',
    quickMessage: 'Resposta rápida',
    QuickMessageDepartment: 'Resposta rápida - Setor',
    quickMessageDepartment: 'Resposta rápida - Setor',
    TransmissionList: 'Lista de Transmissão - Contato',
    transmissionList: 'Lista de Transmissão - Contato',
    Transmission: 'Lista de Transmissão',
    transmission: 'Lista de Transmissão',
    Setting: 'Configuração',
    setting: 'Configuração',
    Ticket: 'Atendimento',
    ticket: 'Atendimento',
    Media: 'Arquivo',
    media: 'Arquivo',
    CustomField: 'Campo Customizado',
    customField: 'Campo Customizado',
    CustomFieldValue: 'Contato - Campo customizado',
    customFieldValue: 'Contato - Campo customizado',
    Department: 'Setor',
    department: 'Setor',
    Permission: 'Permissão',
    permission: 'Permissão',
    Role: 'Cargo',
    role: 'Cargo',
    UserDepartment: 'Usuário - Setor',
    userDepartment: 'Usuário - Setor',

    // Campos
    whatsappId: 'Conexão',
    name: 'Nome',
    number: 'Número',
    email: 'E-mail',
    phone: 'Telefone',
    flowId: 'ChatBot',
    delay: 'Delay',
    runAfter: 'Data de início',
    startTime: 'Horário de início',
    endTime: 'Horário de fim',
    password: 'Senha',
    roleId: 'Cargo',
}

export default ptBr
