import { Button, Dialog, DialogActions, DialogTitle, Tooltip } from '@material-ui/core'
import BackdropLoading from 'components/BackdropLoading'
import toastError from 'errors/toastError'
import useAuth from 'hooks/useAuth.js'
import useWhatsApps from 'hooks/useWhatsApps'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import api from 'services/api'

export default function CloseTicketModal({ open, onClose, ticket }) {
    const history = useHistory()
    const { user } = useAuth()
    const { whatsApps } = useWhatsApps()
    const [settings, setSettings] = useState({})
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!open) return

        const newSettings = {}

        const whatsapp = whatsApps?.find(wpp => wpp.id === ticket.whatsappId)

        newSettings.ratingMessage = !['', null, undefined].includes( whatsapp?.ratingMessage )
        newSettings.completionMessage = !['', null, undefined].includes( whatsapp?.completionMessage )

        setSettings(newSettings)
    }, [open, ticket, whatsApps])

    const handleCloseTicket = async type => {
        setLoading(true)

        const payload = {
            status: 'closed',
            userId: user?.id,
        }

        switch (type) {
            case 'evaluate':
                break
            case 'conclude':
                payload.dontSendEvaluation = true
                break
            case 'finish':
                payload.dontSendEvaluation = true
                payload.dontSendCompletion = true
                break
            default:
        }

        try {
            await api.put(`/tickets/${ticket.id}`, payload)
        } catch (err) {
            toastError(err)
        }
        setLoading(false)

        history.push(`/tickets/`)
        onClose()
    }

    const canShowEvaluate = ticket.userId && settings.ratingMessage
    const canShowConclude = settings.completionMessage    

    return (
        <Dialog
            open={Boolean(open && Object.keys(settings).length)}
            onClose={onClose}
            maxWidth="md">
            <DialogTitle>
                {canShowEvaluate || canShowConclude ? 'Como você deseja finalizar' : 'Finalizar'} o atendimento?
            </DialogTitle>
            <DialogActions>
                {loading && <BackdropLoading />}
                {canShowEvaluate && (
                    <Tooltip
                        title="Finaliza e envia a mensagem de avaliação do atendimento"
                        placement="top"
                        arrow>
                        <Button
                            onClick={() => handleCloseTicket('evaluate')}
                            color="primary"
                            autoFocus>
                            Finalizar e Avaliar
                        </Button>
                    </Tooltip>
                )}
                {canShowConclude && (
                    <Tooltip
                        title="Finaliza e envia a mensagem de conclusão"
                        placement="top"
                        arrow>
                        <Button
                            onClick={() => handleCloseTicket('conclude')}
                            color="primary">
                            Finalizar com conclusão
                        </Button>
                    </Tooltip>
                )}
                <Tooltip
                    title="Finaliza sem enviar nenhuma mensagem"
                    placement="top"
                    arrow>
                    <Button
                        onClick={() => handleCloseTicket('finish')}
                        color="primary">
                        {canShowEvaluate || canShowConclude ? 'Apenas Finalizar' : 'Finalizar'}
                    </Button>
                </Tooltip>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
