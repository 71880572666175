export const phoneBrParser = (phoneString = '', forceOnlyNumber = true) => {
    phoneString = phoneString.replace(/\s/g, '')
    phoneString = phoneString.replace(/[()]/g, '')

    const regex =
        /^(?:(?:\+|00)?(55)\s?)?(?:\(?([0-0]?[0-9]{1}[0-9]{1})\)?\s?)??(?:((?:9\d|[2-9])\d{3}-?\d{4}))$/
    const matches = phoneString.match(regex)

    if (!matches) return null

    let ddi = matches[1] || ''
    let ddd = matches[2] ? matches[2].replace(/^0/, '') : ''
    let number = matches[3] || ''

    const onlyNumber = number.replace(/-/g, '')

    if (forceOnlyNumber) number = onlyNumber

    if (ddi && ddi === '55' && !ddd && [8, 9].includes(onlyNumber.length)) {
        // Validação para DDI 55, ex.: +55 (55) 99999-9999
        ddd = ddi
    }

    if (!ddi && !ddd && !number) return null

    return { ddi, ddd, number }
}

/**
 * Returns the contrast color (white or black) for a given background color in hex.
 *
 * @param {string} hexColor - Background color in hex (e.g. #ffffff)
 * @return {string} Contrast color (white or black) in hex
 */
export function contrastColor(color) {
    let r, g, b

    if (!color) return '#000'

    if (color.startsWith('#')) {
        color = color.replace(/^#/, '')
        r = parseInt(color.slice(0, 2), 16)
        g = parseInt(color.slice(2, 4), 16)
        b = parseInt(color.slice(4, 6), 16)
    } else if (color.startsWith('rgb')) {
        const match = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
        r = parseInt(match[1])
        g = parseInt(match[2])
        b = parseInt(match[3])
    } else {
        throw new Error('Invalid color format')
    }

    const contrast = (r * 299 + g * 587 + b * 114) / 1000;
    return contrast > 128 ? '#000000' : '#ffffff';
}
