
export const CanvasBackground = {
    id: 'CanvasBackground',
    beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color ?? 'rgba(0, 0, 0, 0.0)';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
    }
}