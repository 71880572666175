import { IconButton, LinearProgress, makeStyles } from '@material-ui/core'
import {
    CloudUpload,
    Code,
    Delete,
    InsertDriveFile,
    LibraryMusic,
    PictureAsPdf,
    VideoLibrary,
    Visibility,
} from '@material-ui/icons'
import toastError from 'errors/toastError'
import api from 'services/api'
import clsx from 'clsx'
import { useState } from 'react'
import GoogleDoc from 'icons/GoogleDoc'
import Spreadsheet from 'icons/Spreadsheet'

const getMimePart = (mimeType, part = 1) => mimeType?.split('/')[part - 1]

const FileThumb = ({ file }) => {
    const classes = useStyles()

    if (getMimePart(file.mimeType) === 'image')
        return (
            <img
                src={file.url}
                className={classes.fileIcon}
                alt={file.fileName}
                width={100}
            />
        )

    if (getMimePart(file.mimeType) === 'video')
        return <VideoLibrary className={classes.fileIcon} />

    if (getMimePart(file.mimeType) === 'audio')
        return <LibraryMusic className={classes.fileIcon} />

    switch (getMimePart(file.mimeType, 2)) {
        case 'pdf':
            return <PictureAsPdf className={classes.fileIcon} />
        case 'csv':
            return <Spreadsheet className={classes.fileIcon} />
        case 'plain':
        case 'msword':
            return <GoogleDoc className={classes.fileIcon} />
        case 'json':
        case 'sql':
        case 'xml':
            return <Code className={classes.fileIcon} />
        default:
            console.debug(getMimePart(file.mimeType, 2))
            return <InsertDriveFile className={classes.fileIcon} />
    }
}

const File = ({ file, deleteFile, showFileName = true, ...props }) => {
    const classes = useStyles()

    return (
        <div className={classes.fileRoot}>
            {file.uploading && <LinearProgress style={{ height: 2 }} />}

            <FileThumb file={file} />

            <div className={classes.fileContent}>
                {showFileName && file.fileName && (
                    <span className={classes.fileName}>{file.fileName}</span>
                )}

                <div className={classes.fileButtons}>
                    {!file.uploading && !file.response && (
                        <IconButton
                            size="small"
                            title="Visualizar arquivo"
                            onClick={e => window.open(file.url, '_blank')}>
                            <Visibility />
                        </IconButton>
                    )}
                    <IconButton
                        size="small"
                        title="Deletar arquivo"
                        onClick={deleteFile}>
                        <Delete />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}

const FileUploader = ({
    value,
    changeValue,
    useButtons,
    multiple,
    useBlob,
    showFileName,
    accept = '*',
    ...props
}) => {
    const classes = useStyles()
    const [dragging, setDragging] = useState(false)

    let files = value || []
    if (!Array.isArray(files)) files = [files]
    const filesToShow = files.filter(file => !file.delete)

    const handleAttachmentFile = async uploading => {
        const newFiles = uploading.map(file => {
            return {
                fileToUpload: file,
                url: URL.createObjectURL(file),
                uploading: true,
                fileName: file.name,
                mimeType: file.type,
            }
        })

        files.push(...newFiles)

        if (!multiple && files.length > 1) files.shift()

        handleChange(files)

        for (const fileIndex in files) {
            const attachment = files[fileIndex]
            if (!attachment.uploading) continue

            const formData = new FormData()
            formData.append('file', attachment.fileToUpload)

            api.post(`/upload`, formData)
                .then(({ data: file }) => {
                    attachment.response = file
                    attachment.mimeType = file.mimetype
                    attachment.stored = file.filename

                    if (!useBlob) delete attachment.response.binary

                    delete attachment.uploading
                    delete attachment.fileToUpload

                    handleChange(files)
                })
                .catch(error => {
                    attachment.uploading = false
                    attachment.error = error
                    files.splice(fileIndex, 1) // Remove o anexo
                    handleChange(files)

                    toastError(
                        'Houve um erro ao carregar um arquivo. ' +
                            error.message,
                    )
                })
        }
    }

    const deleteFile = fileIndex => {
        files[fileIndex].delete = true
        changeValue(files)
    }

    const handleChange = () => {
        if (!multiple) return changeValue(files[0])

        return changeValue(files)
    }

    const handleFileDrop = event => {
        event.preventDefault()
        setDragging(false)
        const files = []

        if (event.dataTransfer.items) {
            ;[...event.dataTransfer.items].forEach((item, i) => {
                if (item.kind === 'file') {
                    files.push(item.getAsFile())
                }
            })
        } else {
            files.push(...event.dataTransfer.files)
        }

        if (files.length) handleAttachmentFile(files)
    }

    return (
        <div className={classes.root}>
            {/* {useButtons && multiple && <div>aqui vão os botões</div>} */}

            <div className={classes.uploadBox}>
                <div
                    className={clsx([
                        classes.filesContainer,
                        { [classes.fileDragging]: dragging },
                    ])}>
                    {filesToShow?.map((file, index) => (
                        <File
                            key={index}
                            file={file}
                            showFileName={showFileName}
                            deleteFile={e => deleteFile(index)}
                        />
                    ))}
                    <div
                        className={clsx([
                            classes.fileRoot,
                            classes.uploadRoot,
                        ])}>
                        <div className={classes.fileContent}>
                            <div className={classes.fileUpload}>
                                <CloudUpload />
                                Escolha os arquivos ou arraste e solte aqui!
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <input
                        type="file"
                        multiple={multiple}
                        onChange={e =>
                            handleAttachmentFile([...e.target.files])
                        }
                        className={classes.fileInput}
                        accept={accept}
                        onDragEnter={e => setDragging(true)}
                        onDragLeave={e => setDragging(false)}
                        onDrop={handleFileDrop}
                    />
                </div>
            </div>
        </div>
    )
}

export default FileUploader

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },

    uploadBox: {
        position: 'relative',
    },

    filesContainer: {
        width: '100%',
        display: 'grid',
        gap: 5,
        gridTemplateColumns: 'repeat(auto-fill, minmax(145px, 1fr))',
        position: 'relative',
        zIndex: 50,
        pointerEvents: 'none',
        border: '2px dashed #efefef',
        borderRadius: 3,
        padding: 2,
        transition: 'all 0.2s ease-out',
    },

    fileDragging: {
        borderColor: '#919191',
        boxShadow: 'inset 0 0 20px 1px #919191',
    },

    fileInput: {
        bottom: 0,
        cursor: 'pointer',
        height: '100%',
        left: 0,
        opacity: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        width: '100%',
        zIndex: 10,
    },

    fileRoot: {
        background: '#f1f1f1',
        borderRadius: 6,
        margin: 0,
        overflow: 'hidden',
        padding: 0,
        position: 'relative',
        pointerEvents: 'auto',

        '&:after': {
            background: 'rgba(0, 0, 0, 0.25)',
            bottom: 0,
            content: '" "', // Precisa ser assim pois ao compilar, as aspas são removidas
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
        },
    },

    fileIcon: {
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        objectPosition: 'center',
    },

    fileContent: {
        position: 'relative',
        paddingBottom: '80%',
        width: '100%',
        zIndex: 1,
    },

    fileName: {
        position: 'absolute',
        bottom: 0,
        padding: 2,
        width: '100%',
        maxHeight: 32,
        backgroundColor: 'rgba(0, 0, 0, 0.322)',

        fontSize: 11,
        lineHeight: 1,
        color: 'white',
        textAlign: 'center',
        wordWrap: 'break-word',
    },

    fileButtons: {
        position: 'absolute',
        right: 0,
        maxWidth: '100%',

        '& button svg': {
            transition: 'all 200ms',
            color: 'rgba(255,255,255, 0.8)',
        },

        '& button:hover svg': {
            color: 'white',
        },
    },

    uploadRoot: {
        pointerEvents: 'none',
        '&:after': {
            content: '',
        },
    },

    fileUpload: {
        pointerEvents: 'none',
        userSelect: 'none',
        position: 'absolute',
        inset: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        fontSize: 11,
        lineHeight: 1.1,
        color: 'white',

        '& svg': {
            width: 45,
            height: 45,
        },
    },
}))
