import { useTheme } from '@material-ui/core'
import { merge } from 'lodash'

import ChartGradient from 'chartjs-plugin-gradient'
import ChartDeferred from 'chartjs-plugin-deferred'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartAutocolors from 'chartjs-plugin-autocolors';

import { CanvasBackground } from './ChartJsPlugins'

import {
    Chart as ChartJS,
    CategoryScale,
    PointElement,
    LinearScale,
    LineElement,
    ArcElement,
    BarElement,
    Tooltip,
    Legend,
    Filler,
    Title,
} from 'chart.js'

ChartJS.register(
    CanvasBackground,
    ChartDataLabels,
    ChartAutocolors,
    ChartGradient,
    ChartDeferred,
    CategoryScale,
    PointElement,
    LineElement,
    LinearScale,
    BarElement,
    ArcElement,
    Tooltip,
    Filler,
    Legend,
    Title,
)

const useChartJs = () => {
    const theme = useTheme()

    const fontColor = theme.palette.text.primary
    const backgroundColor = theme.palette.background.main
    const backgroundReverse = theme.palette.background.reverse

    const defaultOptions = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            CanvasBackground: { color: backgroundColor },
            deferred: { delay: 300 },
            legend: {
                fontColor: fontColor
            },
            title: {
                color: fontColor
            },
            scales: {
                y: {
                    title: {
                        color: fontColor
                    }
                },
                x: {
                    title: {
                        color: fontColor
                    }
                },
            }
        },
    }

    const generateLabels = (chart, dataset = 0) => {
        let datasetFind = chart.data.datasets.at(dataset)
        if (!datasetFind || !datasetFind.data.length)
            return [{ text: 'Nenhuma informação', fillStyle: fontColor, fontColor: fontColor, hidden: false }]

        const visibility = chart.data.labels.map((value, index) => !chart.getDataVisibility(index))
        return datasetFind.data.map((value, index) => ({
            text: chart.data.labels[index],
            fillStyle: datasetFind.backgroundColor[index],
            strokeStyle: datasetFind.backgroundColor[index],
            hidden: visibility[index],
            fontColor: fontColor,
        }))
    }

    const clickLegend = (event, legendItem, legend) => {
        const index = legend.chart.data.labels.indexOf(legendItem.text)
        legend.chart.toggleDataVisibility(index)
        legend.chart.update()
    }

    const getHeight = (total, multiply = 25) => {
        let height = 400

        if (total > 16)
            height += (total-16) * multiply
        
        return height
    }

    const random = (min = 0, max = 50) => Math.floor(Math.random() * (max - min + 1)) + min

    return {
        fontColor,
        backgroundColor,
        backgroundReverse,
        random,
        getHeight,
        clickLegend,
        generateLabels,
        getOptions: options => merge(defaultOptions, options),
    }
}

export default useChartJs
