import React from 'react'

import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Filter from '../components/Filter'
import useStyles from '../components/Style'
import useDashboard from 'hooks/useDashboard'
import ChartTickets from './ChartTickets'
import ChartDaily from './ChartDaily'
import ChartPeakTime from './ChartPeakTime'
import ChartSeconds from './ChartSeconds'
import ChartRating from './ChartRating'
import ChartContacts from './ChartContacts'

const Tickets = () => {
    const classes = useStyles()

    const { findTicketsDashboard } = useDashboard()
    
    const [dashboard, setDashboard] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    
    async function onFilter(params) {
        const data = await findTicketsDashboard(params)
        setDashboard(data.dashboard)
    }

    const contacts = dashboard?.contacts || []
    const tickets = dashboard?.tickets || []
    const ratings = dashboard?.ratings || []
    const accept = dashboard?.accept || []
    const timing = dashboard?.timing || []
    const peak = dashboard?.peak || []
    const daily = dashboard?.daily || {}

    return (
        <div>
            <Container maxWidth="xl" className={classes.container}>
                <Grid container spacing={2} justifyContent="flex-start">
                    <Filter
                        onFilter={onFilter}
                        setLoading={setLoading}
                        loading={loading}
                    />

                    <Grid item xs={12}>
                        <ChartTickets dashboardData={tickets} label='Aguardando' status='pending' />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartTickets dashboardData={tickets} label='Em Atendimento' status='open' />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartTickets dashboardData={tickets} label='Finalizados' status='closed' />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartSeconds dashboardData={accept} title='Tempo médio de aceite' />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartSeconds dashboardData={timing} title='Tempo médio de resposta' />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartRating dashboardData={ratings} />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartContacts dashboardData={contacts} />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartPeakTime dashboardData={peak} />
                    </Grid>

                    <Grid item xs={12}>
                        <ChartDaily dashboardData={daily} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Tickets
