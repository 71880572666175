import React from "react";

import Paper from "@material-ui/core/Paper";

import Rating from "@material-ui/lab/Rating";
import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import Title from "pages/Dashboard/components/Title";
import { Person } from "@material-ui/icons";
import useStyles from "../components/Style";

const customIcons = {
    0: {icon: "🚫", label: "Sem Avaliação"},
    1: {icon: "😡", rate:1, label: "Muito Insatisfeito" },
    2: {icon: "😤", rate:2, label: "Insatisfeito" },
    3: {icon: "😐", rate:3, label: "Indiferente" },
    4: {icon: "😀", rate:4, label: "Satisfeito" },
    5: {icon: "🤩", rate:5, label: "Muito Satisfeito" },
}

export function RatingBox({ rating, rated }) {
    const ratingTrunc = rating && rating > 0 ? rating.toFixed(1) : 0
    const ratingValue = parseFloat((ratingTrunc % 1).toFixed(1))
    const ratingIcon = customIcons[Math.ceil(ratingTrunc)]
    return (
        <Tooltip title={`${ratingTrunc} - ${ratingIcon?.label}`} arrow>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 4}}>
                <Rating 
                    max={1}
                    readOnly
                    precision={0.1} 
                    value={parseFloat(ratingValue === 0 ? 1 : ratingValue)}
                    icon={<span>{ratingIcon?.icon}</span>}
                />
                <span style={{ fontWeight: "bold" }}>{ratingTrunc}</span>
                <span style={{ fontWeight: "lighter" }}> ({rated ?? 0} Avaliações)</span>
            </div>
        </Tooltip>
    );
}

function User({ attendant }) {
    
    return (
        <Grid container alignItems="center">
            <Person />
            <Typography component="h3" variant="h6" color="primary">
                {attendant.name}
            </Typography>
        </Grid>
    )
}

function Tickets({ tickets }) {
    const title = [ `Total: ${tickets?.total || 0}` ]

    if (tickets && 'tickets' in tickets)
        title.push(`Contatos: ${tickets?.tickets || 0}`)

    if (tickets && 'groups' in tickets)
        title.push(`Grupos: ${tickets?.groups || 0}`)

    return (
        <Tooltip title={<span style={{ whiteSpace: 'pre-line' }}>{title.join("\n")}</span>} arrow>
            <Typography component="h3" variant="subtitle2" color="primary">
                {tickets?.total || 0}
            </Typography>
        </Tooltip>
    )
}

function Department({ department }) {

    return (
        <Grid
            container
            spacing={1}
            style={{ alignItems: 'flex-start', gap: 4 }}>
            {Boolean(department?.length) &&
                department.map((department, key) => (
                    <Chip
                        key={key}
                        variant="default"
                        style={{ backgroundColor: department.color }}
                        label={department.name}
                    />
                ))}
        </Grid>
    )
}

export default function TableUsers({ users }) {
    const classes = useStyles()

    return (
        <>
            <Title>{`Usuários: ${
                users.length
            }`}</Title>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={12} sm={8}  lg={2}><Typography component="h6" variant="subtitle2" color="primary">Nome do Usuário</Typography></Grid>
                        <Grid item xs={12} sm={4}  lg={2} style={{ textAlign: "center" }}><Typography component="h6" variant="subtitle2" color="primary">Avaliação</Typography></Grid>
                        <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Typography component="h6" variant="subtitle2" color="primary">Total de Atendimentos</Typography></Grid>
                        <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Typography component="h6" variant="subtitle2" color="primary">Aguardando</Typography></Grid>
                        <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Typography component="h6" variant="subtitle2" color="primary">Em Atendimento</Typography></Grid>
                        <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Typography component="h6" variant="subtitle2" color="primary">Finalizados</Typography></Grid>
                        <Grid item xs={12} sm={12} lg={4}><Typography component="h6" variant="subtitle2" color="primary">Setores</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.userSelectNone} style={{ maxHeight: 560, overflowY: "auto"}}>
                    {users.sort((a, b) => (b?.tickets?.total || 0) - (a?.tickets?.total || 0)).map((user, key) => (
                        <Grid key={key} item xs={12}>
                            <Paper style={{ padding: 16 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={12} sm={8}  lg={2}><User attendant={user} /></Grid>
                                    <Grid item xs={12} sm={4}  lg={2}><RatingBox rating={user.rating} rated={user?.tickets?.rated} /></Grid>
                                    <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Tickets tickets={user.tickets} /></Grid>
                                    <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Tickets tickets={user.tickets?.pending} /></Grid>
                                    <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Tickets tickets={user.tickets?.open} /></Grid>
                                    <Grid item xs={3}  sm={3}  lg={1} style={{ textAlign: "center" }}><Tickets tickets={user.tickets?.closed} /></Grid>
                                    <Grid item xs={12} sm={12} lg={4}><Department department={user.departments} /></Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}
