import React, { useState, useContext } from 'react'

import MenuItem from '@material-ui/core/MenuItem'

import api from '../../services/api'
import ConfirmationModal from '../ConfirmationModal'
import { Menu } from '@material-ui/core'
import { MessageContext } from '../../context/Message/MessageContext'
import toastError from '../../errors/toastError'
import useCan from 'hooks/useCan'

const MessageOptionsMenu = ({
    message,
    menuOpen,
    handleClose,
    handleReact,
    anchorEl,
}) => {
    const { setReplyingMessage, setEditingMessage } = useContext(MessageContext)
    const [confirmationOpen, setConfirmationOpen] = useState(false)
    const { can } = useCan()

    const handleDeleteMessage = async () => {
        try {
            await api.delete(`/messages/${message.id}`)
        } catch (err) {
            toastError(err)
        }
    }

    const handleReplyMessage = () => {
        setReplyingMessage(message)
        handleClose()
    }

    const handleEditMessage = () => {
        setReplyingMessage(null)
        setEditingMessage(message)
        handleClose()
    }

    const handleReactMessage = () => {
        setReplyingMessage(null)
        handleReact(message)
        handleClose()
    }

    const handleOpenConfirmationModal = () => {
        setConfirmationOpen(true)
        handleClose()
    }

    return (
        <>
            <ConfirmationModal
                title="Apagar mensagem?"
                open={confirmationOpen}
                onClose={setConfirmationOpen}
                onConfirm={handleDeleteMessage}>
                Esta ação não pode ser revertida.
            </ConfirmationModal>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={menuOpen}
                onClose={handleClose}>
                {(message.fromMe && can('ticket:delete:message')) && (
                    <MenuItem onClick={handleOpenConfirmationModal}>
                        Deletar
                    </MenuItem>
                )}
                <MenuItem onClick={handleReplyMessage}>Responder</MenuItem>
                <MenuItem onClick={handleReactMessage}>Reagir</MenuItem>
                {['extendedTextMessage', 'text'].includes(message.messageType) && (
                    <MenuItem onClick={handleEditMessage}>Editar</MenuItem>
                )}
            </Menu>
        </>
    )
}

export default MessageOptionsMenu
