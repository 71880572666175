import React from 'react'

import { Bar } from 'react-chartjs-2'
import useChartJs from 'hooks/useChartJs'
import { Paper } from '@material-ui/core'
import useStyles from '../components/Style'
import moment from 'moment'

const ChartDaily = ({ dashboardData }) => {
    const { backgroundColor, generateLabels, random, clickLegend, getHeight, getOptions } = useChartJs()
    const classes = useStyles()

    if (dashboardData === undefined)
        return null

    const labels = Object.keys(dashboardData).map(day => moment(day).format('DD/MM/YYYY'))
    const values = Object.values(dashboardData)

    return (
        <Paper className={classes.chartHeight} style={{ height: getHeight(labels.length) }} >
            <Bar 
                options={
                    getOptions({
                        plugins: {
                            autocolors: { enabled: true, mode: 'data', offset: random() },
                            legend: { 
                                position: 'right',
                                labels: { generateLabels },
                                onClick: clickLegend
                            },
                            title: {
                                align: 'start',
                                display: true,
                                text: 'Atendimentos por Dia',
                                font: { size: 18 },
                            },
                            datalabels: {
                                backgroundColor: context => context.dataset.backgroundColor,
                                borderColor: backgroundColor,
                                borderRadius: 50,
                                borderWidth: 2,
                                color: 'white',
                                display: true,
                                padding: { left: 8, right: 8 },
                                font: { weight: 'bold' },
                            },
                        },
                    })
                } 
                data={{
                    labels: labels,
                    datasets: [
                        { label: 'Atendimentos', data: values, borderRadius: 8 }
                    ],
                }} 
            />
        </Paper>
    )
}

export default ChartDaily
