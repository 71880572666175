import { useContext } from 'react'
import { WhatsAppsContext } from 'context/WhatsApp/WhatsAppsContext'

const useWhatsApps = (whatsappId = null) => {
    const { loading, whatsApps } = useContext(WhatsAppsContext)

    const whatsApp = whatsappId ? whatsApps?.find(wpp => wpp.id === whatsappId) : null
    
    return { whatsApps, loading, whatsApp }
}

export default useWhatsApps
