import React from 'react'

import { Line } from 'react-chartjs-2'
import useChartJs from 'hooks/useChartJs'

const chartStructure = {
    '00:00': 0, '01:00': 0, '02:00': 0, '03:00': 0, '04:00': 0, '05:00': 0, '06:00': 0, '07:00': 0, '08:00': 0, '09:00': 0, '10:00': 0, '11:00': 0,
    '12:00': 0, '13:00': 0, '14:00': 0, '15:00': 0, '16:00': 0, '17:00': 0, '18:00': 0, '19:00': 0, '20:00': 0, '21:00': 0, '22:00': 0, '23:00': 0,
}

const createDataset = (data, label, hidden, color, color3, color7) => ({
    label,
    hidden,
    borderColor: color,
    data: Object.values(data),
    gradient: { backgroundColor: { axis: 'y', colors: { 0: color3, 100: color7 } } },
    datalabels: { backgroundColor: color7 },
    cubicInterpolationMode: 'monotone',
    fill: 'origin',
    tension: 0.3,
})

const ChartToday = ({ dashboardData }) => {
    const { getOptions } = useChartJs()

    const totalTickets = Object.values(dashboardData).reduce((total, tickets) => total + tickets.length, 0)

    const chartDatasets = {
        bot: { ...chartStructure },
        pending: { ...chartStructure },
        open: { ...chartStructure },
        closed: { ...chartStructure },
        tickets: { ...chartStructure },
    }

    Object.entries(dashboardData).forEach(([hour, tickets]) => {
        tickets.forEach(ticket => {
            chartDatasets[ticket.status][hour] += 1
            chartDatasets['tickets'][hour] += 1
        })
    })
    
    return (
        <Line 
            options={
                getOptions({
                    plugins: {
                        legend: { position: 'bottom' },
                        title: {
                            align: 'start',
                            display: true,
                            text: `Atendimentos criados hoje: ${totalTickets}`,
                            font: { size: 18 },
                        },
                        tooltip: { enabled: false },
                        gradient: {},
                        datalabels: {
                            padding: 6,
                            borderRadius: 4,
                            color: 'white',
                            formatter: Math.round,
                            font: { weight: 'bold', },
                        },
                    },
                })
            } 
            data={{
                labels: Object.keys(chartStructure),
                datasets: [
                    createDataset(chartDatasets.bot,     'No Bot',         true,  'rgb(255, 170, 11)', 'rgba(255, 170, 11, 0.3)', 'rgba(255, 170, 11, 0.7)'),
                    createDataset(chartDatasets.pending, 'Aguardando',     true,  'rgb(244, 83, 72)', 'rgba(244, 83, 72, 0.3)', 'rgba(244, 83, 72, 0.7)'),
                    createDataset(chartDatasets.open,    'Em Atendimento', true,  'rgb(67, 223, 132)',    'rgba(67, 223, 132, 0.3)',    'rgba(67, 223, 132, 0.7)'   ),
                    createDataset(chartDatasets.closed,  'Finalizados',    true,  'rgb(69, 173, 242)',  'rgba(69, 173, 242, 0.3)',  'rgba(69, 173, 242, 0.7)' ),
                    createDataset(chartDatasets.tickets, 'Total',          false, 'rgb(74, 124, 165)', 'rgba(74, 124, 165, 0.3)', 'rgba(74, 124, 165, 0.7)'),
                ],
            }} 
        />
    )
}

export default ChartToday
