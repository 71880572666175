import React from 'react'
import { Avatar, Box, Button, CardHeader, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, InputBase, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import MessageIcon from '@material-ui/icons/MessageOutlined'
import InfoIcon from '@material-ui/icons/Info'
import toastError from 'errors/toastError'
import useMessages from 'hooks/useMessages'
import { trim } from 'lodash'
import moment from 'moment'

const useStyles = makeStyles(theme => ({

    messageCardHeader: {
        '& .MuiCardHeader-content': {
            overflow: 'hidden'
        }
    },

    messageResult: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },

    ticketOptionsBox: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 5,
        alignItems: 'center',
        padding: theme.spacing(1),
    },

    serachInputWrapper: {
        flex: 1,
        background: theme.palette.bordabox,
        display: 'flex',
        borderRadius: 40,
        padding: 4,
        marginRight: theme.spacing(1),
    },

    searchIcon: {
        color: 'grey',
        marginLeft: 6,
        marginRight: 6,
        alignSelf: 'center',
    },

    searchInput: {
        flex: 1,
        border: 'none',
        borderRadius: 30,
    },

}))

export default function SearchMessage({ open, ticket, onClose, onClick }) {
    const { search } = useMessages()

    const classes = useStyles()

    const [searchResult, setSearchResult] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState('')

    const ticketClosed = ticket.status === 'closed'

    const handleSearchMessage = (e) => {
        const value = e.target.value
        if (value && trim(value).length < 3) {
            setError('Digite pelo menos 3 caracteres para buscar')
            return
        }
        
        setError('')
        setSearchResult({})

        if (!value) return;

        setLoading(true)
        const delayDebounceFn = setTimeout(async () => {
            try {
                const result = await search(ticket.id, {
                    filters: {
                        smart: e.target.value,
                        ticketId: ticketClosed ? ticket.id : undefined,
                        ticketOrGroup: {
                            ticketId: ticket.id, 
                            contactId: ticket.contactId
                        }
                    }
                })

                setLoading(false)
                setSearchResult(result)
            } catch (err) {
                setSearchResult({})
                setLoading(false)
                toastError(err)
            }
        }, 500)
        return () => clearTimeout(delayDebounceFn)        
    }

    const { messages } = searchResult

    const renderResult = () => {
        if (loading) {
            return (
                <Box component='div' sx={{ display: loading ? 'flex' : 'none' }} >
                    <Chip icon={<CircularProgress size={20} />} label="Carregando..." />
                </Box>
            )
        }
        
        if (!loading && trim(error)) {
            return (
                <Box component='div' sx={{ display: !loading && trim(error) ? 'flex' : 'none' }} >
                    <Chip icon={<InfoIcon fontSize='small' />} label={error} />
                </Box>
            )
        }

        function formatDate(createdAt) {
            return moment(createdAt).format("HH:mm");
        }

        if (messages && messages.length) {
            return (
                <Box component='div' sx={{ display: 'flex', flexDirection: 'column', gap: 4, maxHeight: 240, width: '100%' }} >
                    {messages.map((message, index) => (
                        <Box key={index} component='div' sx={{ display: 'flex' }} >
                            <Chip 
                                clickable
                                icon={<MessageIcon fontSize='small' />} 
                                label={`${formatDate(message.createdAt)} - ${(message.body || message.mediaPath)}`} 
                                style={{ maxWidth: '100%' }} 
                                onClick={() => {onClose(); onClick(message);}}
                            />
                        </Box>
                    ))}
                </Box>
            )
        }
    }

    return (
        <Dialog
            open={Boolean(open)}
            onClose={onClose}
            maxWidth="sm"
            style={{
                backdropFilter: 'blur(4px)'
            }}
        >
            <DialogTitle>Buscar mensagem</DialogTitle>
            <Divider orientation="horizontal" variant="fullWidth" />
            <DialogContent>
                <Typography variant="subtitle1">Atendimento:</Typography>
                <CardHeader
                    className={classes.messageCardHeader}
                    titleTypographyProps={{ noWrap: true, style: { textOverflow: 'ellipsis' } }}
                    subheaderTypographyProps={{ noWrap: true }}
                    avatar={<Avatar src={ticket.contact.profilePicUrl} alt="contact_image" />}
                    title={ticket.contact.name}
                />
                <Box component='div' className={classes.ticketOptionsBox}>
                    <Box component='div' className={classes.serachInputWrapper}>
                        <SearchIcon className={classes.searchIcon} />
                        <InputBase
                            className={classes.searchInput}
                            placeholder="Pesquisar..."
                            onChange={handleSearchMessage}
                            type="search"
                        />
                    </Box>
                </Box>
            </DialogContent>
            <Divider orientation="horizontal" variant="fullWidth" />
            <DialogContent className={classes.messageResult}>
                {renderResult()}
            </DialogContent>
            <Divider orientation="horizontal" variant="fullWidth" />
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    )
}
