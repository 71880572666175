import React, { useState, useEffect } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import {
    TextField,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from '@material-ui/core'
import api from '../../services/api'
import toastError from '../../errors/toastError'
import DepartmentSelectCustom from '../DepartmentSelectCustom'
import { Can } from '../Can'
import FormikRoleSelect from 'components/RoleSelect/FormikRoleSelect'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))

const UserSchema = Validator.object().shape({
    name: Validator.string().min(2).max(50).required(),
    password: Validator.string().min(5).max(50),
    email: Validator.string().email().required(),
})

const ModalUsers = ({ open, onClose, userId, companyId }) => {
    const classes = useStyles()

    const initialState = {
        name: '',
        email: '',
        password: '',
    }

    const [user, setUser] = useState(initialState)
    const [selectedDepartmentIds, setSelectedDepartmentIds] = useState([])

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) return
            if (open) {
                try {
                    const { data } = await api.get(`/users/${userId}`, {
                        include: {
                            departments: 'id,name,color',
                            company: 'id,name',
                        },
                    })
                    setUser(prevState => {
                        return { ...prevState, ...data }
                    })
                    const userDepartmentIds = data.departments?.map(department => department.id)
                    setSelectedDepartmentIds(userDepartmentIds)
                } catch (err) {
                    toastError(err)
                }
            }
        }

        fetchUser()
    }, [userId, open])

    const handleClose = () => {
        onClose()
        setUser(initialState)
    }

    const handleSaveUser = async values => {
        const userData = { ...values, companyId, department: selectedDepartmentIds }
        try {
            if (userId) {
                await api.put(`/users/${userId}`, userData)
            } else {
                await api.post('/users', userData)
            }
            toast.success('Usuário salvo com sucesso.')
        } catch (err) {
            toastError(err)
        }
        handleClose()
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {userId ? 'Editar usuário' : 'Adicionar usuário'}
                </DialogTitle>
                <Formik
                    initialValues={user}
                    enableReinitialize={true}
                    validationSchema={UserSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveUser(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({ touched, errors, isSubmitting }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label="Nome"
                                        autoFocus
                                        name="name"
                                        error={
                                            touched.name && Boolean(errors.name)
                                        }
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    <Field
                                        as={TextField}
                                        label="Email"
                                        type="password"
                                        name="password"
                                        error={
                                            touched.password &&
                                            Boolean(errors.password)
                                        }
                                        helperText={
                                            touched.password && errors.password
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label="Senha"
                                        name="email"
                                        error={
                                            touched.email &&
                                            Boolean(errors.email)
                                        }
                                        helperText={
                                            touched.email && errors.email
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    <Field
                                        as={FormikRoleSelect}
                                        label="Cargo"
                                        name="roleId"
                                        variant="outlined"
                                        className={classes.formControl}
                                        margin="dense"
                                        required
                                    />
                                </div>
                                <Can perform="update:all:department">
                                    <DepartmentSelectCustom
                                        companyId={companyId}
                                        selectedDepartmentIds={selectedDepartmentIds}
                                        onChange={values =>
                                            setSelectedDepartmentIds(values)
                                        }
                                    />
                                </Can>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    {userId ? 'Salvar' : 'Adicionar'}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default ModalUsers
