import React from 'react'

import { Bar } from 'react-chartjs-2'
import useChartJs from 'hooks/useChartJs'
import { Paper } from '@material-ui/core'
import useStyles from '../components/Style'

function formatSeconds(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return `${String(hours).padStart(2, '0')}h ${String(minutes).padStart(2, '0')}m ${String(secs).padStart(2, '0')}s`;
}

const ChartSeconds = ({ dashboardData, title }) => {
    const { generateLabels, clickLegend, random, getHeight, getOptions } = useChartJs()
    const classes = useStyles()

    if (dashboardData === undefined)
        return null

    const users = dashboardData.filter(user => user.seconds)
    .sort((user1, user2) => {
        if (!user1.userId)
            return -1
        else if (!user2.userId)
            return 1
            
        return user1.userName.localeCompare(user2.userName)
    }).map(user => {
        if (!user.userId) {
            user.userId = 0
            user.userName = 'Sem Usuário'
        }

        return user
    })

    const labels = users.map(user => user.userName)
    const values = users.map(user => (user.seconds/3600).toFixed(2))

    return (
        <Paper className={classes.chartHeight} style={{ height: getHeight(labels.length) }} >
            <Bar 
                options={
                    getOptions({
                        plugins: {
                            autocolors: { enabled: true, mode: 'data', offset: random() },
                            legend: { 
                                position: 'right',
                                labels: { generateLabels },
                                onClick: clickLegend
                            },
                            title: {
                                align: 'start',
                                display: true,
                                text: title,
                                font: { size: 18 },
                            },
                            datalabels: {
                                display: false,
                            },
                        },
                    })
                } 
                data={{
                    labels,
                    datasets: [
                        { 
                            label: 'Tempo', 
                            data: values, 
                            borderRadius: 8,
                            tooltip: {
                                callbacks: {
                                    label: tooltip => 'Tempo: ' + formatSeconds(users[tooltip.dataIndex].seconds)
                                }
                            }
                        }
                    ],
                }} 
            />
        </Paper>
    )
}

export default ChartSeconds
