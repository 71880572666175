import React from "react";

import Paper from "@material-ui/core/Paper";

import Rating from "@material-ui/lab/Rating";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import Title from "pages/Dashboard/components/Title";
import { Person } from "@material-ui/icons";
import useStyles from "../components/Style";

const customIcons = {
    0: {icon: "🚫", label: "Sem Avaliação"},
    1: {icon: "😡", rate:1, label: "Muito Insatisfeito" },
    2: {icon: "😤", rate:2, label: "Insatisfeito" },
    3: {icon: "😐", rate:3, label: "Indiferente" },
    4: {icon: "😀", rate:4, label: "Satisfeito" },
    5: {icon: "🤩", rate:5, label: "Muito Satisfeito" },
}

export function RatingBox({ rating, rated }) {
    const ratingTrunc = rating && rating > 0 ? rating.toFixed(1) : 0
    const ratingValue = parseFloat((ratingTrunc % 1).toFixed(1))
    const ratingIcon = customIcons[Math.ceil(ratingTrunc)]
    return (
        <Tooltip title={`${ratingTrunc} - ${ratingIcon?.label}`} arrow>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 4}}>
                <Rating 
                    max={1}
                    readOnly
                    precision={0.1} 
                    value={parseFloat(ratingValue === 0 ? 1 : ratingValue)}
                    icon={<span>{ratingIcon?.icon}</span>}
                />
                <span style={{ fontWeight: "bold" }}>{ratingTrunc}</span>
                <span style={{ fontWeight: "lighter" }}> ({rated ?? 0} Avaliações)</span>
            </div>
        </Tooltip>
    );
}

export default function TableClientMessage({ contacts }) {
    const classes = useStyles()

    const [visible, setVisible] = React.useState([])
    const refContacts = React.useRef([])

    const handleLoadMore = event => {
        if (!refContacts.current.length || (event && (event.currentTarget.scrollHeight - event.currentTarget.scrollTop - 60) > event.currentTarget.clientHeight)) 
            return

        setVisible(prev => ([...prev, ...refContacts.current.splice(0, 20)]))
    }

    React.useEffect(() => {
        refContacts.current = contacts?.sort((a, b) => (b.total || 0) - (a.total || 0)) ?? []
        setVisible([])
        handleLoadMore()
    }, [contacts])

    return (
        <>
            <Title>Ranking de Clientes que mais trocaram Mensagens</Title>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={8} ><Typography component="h6" variant="subtitle2" color="primary">Nome do Contato</Typography></Grid>
                        <Grid item xs={4} style={{ textAlign: "center" }}><Typography component="h6" variant="subtitle2" color="primary">Número de Mensagens</Typography></Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1} className={classes.contactSelectNone} style={{ maxHeight: 560, overflowY: "auto"}} onScroll={handleLoadMore}>
                    {visible.map((contact, key) => (
                        <Grid key={key} item xs={12}>
                            <Paper style={{ padding: 16 }}>
                                <Grid container alignItems="center">
                                    <Grid item xs={8}>
                                        <Grid container alignItems="center">
                                            <Person />
                                            <Typography component="h3" variant="h6" color="primary">
                                                {contact.contactName}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4} style={{ textAlign: "center" }}>
                                        <Typography component="h3" variant="subtitle2" color="primary">
                                            {contact?.total}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </>
    );
}