import React from 'react'

import { Bar } from 'react-chartjs-2'
import useChartJs from 'hooks/useChartJs'
import { Paper } from '@material-ui/core'
import useStyles from '../components/Style'

const ChartTickets = ({ dashboardData, label, status }) => {
    const { backgroundColor, generateLabels, clickLegend, random, getHeight, getOptions } = useChartJs()
    const classes = useStyles()

    if (dashboardData === undefined)
        return null

    const users = dashboardData.filter(user => user.tickets?.[status]?.total)
    .sort((user1, user2) => {
        if (!user1.userId)
            return -1
        else if (!user2.userId)
            return 1
            
        return user1.userName.localeCompare(user2.userName)
    }).map(user => {
        if (!user.userId) {
            user.userId = 0
            user.userName = 'Sem Usuário'
        }

        return user
    })

    const labels = users.map(user => user.userName)
    
    return (
        <Paper className={classes.chartHeight} style={{ height: getHeight(labels.length) }} >
            <Bar 
                options={
                    getOptions({
                        plugins: {
                            autocolors: { enabled: true, mode: 'data', offset: random() },
                            legend: { 
                                position: 'right',
                                labels: { generateLabels },
                                onClick: clickLegend
                            },
                            title: {
                                align: 'start',
                                display: true,
                                text: label,
                                font: { size: 18 },
                            },
                            datalabels: {
                                backgroundColor: context => context.dataset.backgroundColor,
                                borderColor: backgroundColor,
                                borderRadius: 50,
                                borderWidth: 2,
                                color: 'white',
                                display: true,
                                padding: { left: 8, right: 8 },
                                font: { weight: 'bold' },
                            },
                        },
                    })
                } 
                data={{
                    labels,
                    datasets: [
                        { label, data: users.map(user => user.tickets?.[status]?.total), borderRadius: 8 }
                    ],
                }} 
            />
        </Paper>
    )
}

export default ChartTickets
