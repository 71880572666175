import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import React, { useCallback } from 'react'

export function UnansweredFilter({
    onChangeType,
    onChangeMinutes,
    valueType,
    valueMinutes,
}) {
    const options = [
        { id: 'client', desc: 'Pelo cliente' },
        { id: 'me', desc: 'Por mim' },
        { id: 'clientIn', desc: 'Pelo cliente em' },
        { id: 'meIn', desc: 'Por mim em' },
    ]

    const handleChangeType = useCallback(
        event => {
            return onChangeType(event.target.value)
        },
        [onChangeType],
    )

    const handleChangeMinutes = useCallback(
        e => {
            let minutes = e?.target?.value

            if (minutes < 0) minutes = 0
            if (minutes.length > 10) minutes = minutes.slice(0, 10)

            if (!minutes) return onChangeMinutes(null)

            return onChangeMinutes(minutes)
        },
        [onChangeMinutes],
    )

    return (
        <Grid style={{ padding: '0px 10px 10px' }} container spacing={1}>
            <Grid item xs={['clientIn', 'meIn'].includes(valueType) ? 7 : 12}>
                <FormControl fullWidth size="small" variant="outlined">
                    <InputLabel id="unanswered-label">
                        Não Respondidas
                    </InputLabel>
                    <Select
                        label="Não Respondidas"
                        labelId="unanswered-label"
                        value={valueType}
                        onChange={handleChangeType}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}>
                        <MenuItem value={null}>Selecione...</MenuItem>
                        {options.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.desc}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            {['clientIn', 'meIn'].includes(valueType) && (
                <Grid item xs={5}>
                    <TextField
                        label="Minutos"
                        size="small"
                        variant="outlined"
                        value={valueMinutes}
                        InputProps={{
                            inputProps: {
                                type: 'number',
                                allowNegative: false,
                                min: 0,
                                max: 9999999999,
                            },
                        }}
                        onChange={handleChangeMinutes}
                        fullWidth
                    />
                </Grid>
            )}
        </Grid>
    )
}
