import React, { useCallback, useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import MessageForm from '../../nodes/MessageNode/MessageForm'
import MenuForm from '../../nodes/MenuNode/MenuForm'
import SleepForm from '../../nodes/SleepNode/SleepForm'
import AskForm from '../../nodes/AskNode/AskForm'
import DepartmentForm from '../../nodes/DepartmentNode/DepartmentForm'
import UserForm from '../../nodes/UserNode/UserForm'
import NotifyForm from '../../nodes/NotifyNode/NotifyForm'
import ConditionForm from '../../nodes/ConditionNode/ConditionForm'
import TagForm from '../../nodes/TagNode/TagForm'
import FinishForm from '../../nodes/FinishNode/FinishForm'
import { Form, Formik } from 'formik'
import FindPersonForm from 'pages/ChatFlow/nodes/FindPersonNode/FindPersonForm'
import SendInvoiceForm from 'pages/ChatFlow/nodes/SendInvoiceNode/SendInvoiceForm'
import validations from './validations'
import HttpForm from 'pages/ChatFlow/nodes/HttpNode/HttpForm'
import ConnectionForm from 'pages/ChatFlow/nodes/ConnectionNode/ConnectionForm'
import CreateLeadForm from 'pages/ChatFlow/nodes/CreateLeadNode/CreateLeadForm'
import * as initialValues from './initialValues'
import { cloneDeep, merge } from 'lodash'

const nodeForms = {
    message: MessageForm,
    menu: MenuForm,
    sleep: SleepForm,
    ask: AskForm,
    department: DepartmentForm,
    user: UserForm,
    notify: NotifyForm,
    condition: ConditionForm,
    tag: TagForm,
    findPerson: FindPersonForm,
    sendInvoice: SendInvoiceForm,
    httpRequest: HttpForm,
    finish: FinishForm,
    connectionTransfer: ConnectionForm,
    createLead: CreateLeadForm,
}

const ComponentBase = ({ myNode, ...props }) => {
    const Component = nodeForms[myNode?.type]
    if (!Component) return null
    return <Component {...props} />
}

const NodeConfig = ({ isOpen, node, onChange, onDelete, onClose }) => {
    const [initialValue, setInitialValue] = useState(node.data)

    useEffect(() => {
        if (!isOpen)
            return setInitialValue(initialValues[node.type] || {})

        const newInitial = cloneDeep(initialValues[node.type]) || {}

        setInitialValue(merge(newInitial, node.data))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [node.type, isOpen])

    useEffect(() => {
        if (['start'].includes(node?.type)) return onClose()
    }, [node, onClose])

    const call = useCallback(
        props => {
            return (
                <Form>
                    <DialogContent>
                        <ComponentBase myNode={node} {...props} />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => onDelete(node)}
                            color="secondary"
                            style={{ marginRight: 'auto' }}>
                            Deletar
                        </Button>
                        <Button onClick={onClose} color="primary">
                            Cancelar
                        </Button>
                        <Button type="submit" color="primary">
                            Salvar
                        </Button>
                    </DialogActions>
                </Form>
            )
        },
        [node, onClose, onDelete],
    )

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={(data, helpers) => {
                onChange({ ...node, data: { ...node.data, ...data } })
                onClose()
            }}
            validationSchema={validations[node?.type]}>
            {props => (
                <Dialog
                    open={Boolean(isOpen)}
                    onClose={onClose}
                    maxWidth="md"
                    fullWidth={true}>
                    {call(props)}
                </Dialog>
            )}
        </Formik>
    )
}

export default NodeConfig
