import React, { useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from '../../services/api'

export default function CustomFieldAutocomplete({
    value,
    onChange,
    label = 'Campos Customizados',
    disableClearable,
    ...props
}) {
    const [open, setOpen] = useState(false)
    const [smart, setSmart] = useState('')
    const [options, setOptions] = useState([])
    const [labelOptions, setLabelOptions] = useState('')
    const [loading, setLoading] = useState(false)
    const smartTimeout = useRef()

    useEffect(() => {
        const getLabel = async fieldId => {
            if (!fieldId) return

            return (await api.get(`/custom-field/${fieldId}`)).data
        }

        return getLabel(value).then(label => {
            setLabelOptions(label)
        })
    }, [value])

    useEffect(() => {
        clearTimeout(smartTimeout.current)
        smartTimeout.current = setTimeout(() => {
            setLoading(true)
            api.get('/custom-field', {
                filters: {
                    smart,
                },
            }).then(({ data }) => {
                setOptions(data.data)
                setLoading(false)
            }).catch(err => {
                setLoading(true)
            })
        }, 500)
    }, [smart])

    const handleChange = (e, value) => {
        setLabelOptions(value)

        if (!value)
            return onChange(null)

        onChange(value.id)
    }

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.id === value}
            getOptionLabel={option => option.name || option}
            options={options}
            loading={loading}
            autoSelect={true}
            onChange={handleChange}
            value={labelOptions}
            disableClearable={disableClearable}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    onChange={e => setSmart(e.target.value)}
                    {...props}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}
